import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function Career() {

  return (
    <Index.Box className="main-career">
      <PagesIndex.BannerImage
        bannerImage={PagesIndex.Jpg.contactBanner}
        bannerImageWidth="900"
        bannerImageHeight="570"
        bannerTitle="Career With Us"
      />
      <Index.Box className="main-career-wrapper">
        <Index.Box className="cus-container">
          <Index.Grid container spacing={{ md: 5, xxs: 4 }} alignItems="center">
            <Index.Grid item lg={6} xxs={12} className="career-left">
              <Index.Box className="career-img">
                <img src={PagesIndex.Jpg.membership} alt="career" />
              </Index.Box>
            </Index.Grid>
            <Index.Grid item lg={6} xxs={12} className="career-right">
              <Index.Box className="career-form">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="career-form-title"
                >
                  Career With Us
                </Index.Typography>
                <Index.Grid container spacing={2} className="form-group">
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      First name
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="firstNameCareer"
                      className="form-control"
                      placeholder="First name"
                      name="firstName"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Last name
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="lastNameCareer"
                      className="form-control"
                      placeholder="First name"
                      name="firstName"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Email
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="emailCareer"
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      name="email"
                      inputProps={{ maxLength: 320 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Phone number
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="phoneCareer"
                      className="form-control"
                      placeholder="Phone number"
                      name="phoneNumber"
                      inputProps={{ maxLength: 10 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      City
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="cityCareer"
                      className="form-control"
                      placeholder="City"
                      name="city"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Position
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="positionCareer"
                      className="form-control"
                      placeholder="Position"
                      name="position"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={12} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Resume
                    </Index.FormHelperText>
                    <Index.Box className="file-upload-box">
                      <label>
                        Upload or drag file
                      </label>
                      <input
                        id="resumeCareer"
                        className="form-control"
                        type="file"
                        name="resume"
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item sm={12} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Message
                    </Index.FormHelperText>
                    <Index.TextareaAutosize
                      minRows={5}
                      placeholder="Message"
                      name="message"
                      maxLength={250}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={12} xxs={12} className="form-action">
                    <PagesIndex.Button type="submit" primary>
                      Submit
                    </PagesIndex.Button>
                  </Index.Grid>
                </Index.Grid>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );

}

export default Career;
