import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import PropTypes from "prop-types";

function AddSnacksTab(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

AddSnacksTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
let cancellation = false;
function AddSnacks() {
  const { userDetails } = PagesIndex.useSelector((state) => state.UserReducer);
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const movieId = new URLSearchParams(location.search).get("mId");
  const regionId = new URLSearchParams(location.search).get("rId");
  const [value, setValue] = useState(0);
  const [foodItemList, setFoodItemList] = useState([]);
  const [selectedFood, setSelectedFood] = useState([]);
  const [snackPrice, setSnackPrice] = useState(0);
  const [priceAfterFoodAdd, setPriceAfterFoodAdd] = useState(
    location?.state?.ticketPriceDetails?.total +
      location?.state?.convenienceFees
  );
  const [openWarning, setOpenWarning] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  useEffect(() => {
    if (!location?.state) {
      navigate({
        pathname: `/movie-details`,
        search: PagesIndex?.createSearchParams({
          mId: movieId,
          rId: regionId,
        }).toString(),
      });
    } else {
      fetchFoodItemList();
    }
  }, []);
  useEffect(() => {
    return () => {
      if (!cancellation) {
        componentUnmount();
      }
    };
  }, []);
  const componentUnmount = (e) => {
    if (window.confirm("Are you sure you want to cancel this transaction?")) {
      cancelSeats(location?.state?.transactionId);
    } else {
      navigate(
        {
          pathname: `/add-snacks`,
          search: PagesIndex?.createSearchParams({
            mId: movieId,
            rId: regionId,
          }).toString(),
        },
        { state: location.state }
      );
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCloseWarning = () => setOpenWarning(false);
  const cancelSeats = async (transactionId) => {
    dispatch(PagesIndex.showLoader());
    await PagesIndex.apiGetHandler(
      PagesIndex.Api.CANCEL_SEATS,
      `${location?.state?.cinemaData.cinemaId}/${transactionId}`
    ).then(async (res) => {
      if (res?.status === 200) {
        PagesIndex.toast.success(res?.message);
        dispatch(PagesIndex.hideLoader());
        if (cancellation) {
          navigate({
            pathname: `/movie-details`,
            search: PagesIndex?.createSearchParams({
              mId: movieId,
              rId: regionId,
            }).toString(),
          });
        }
        window.history.replaceState({}, document.title);
      } else {
        PagesIndex.toast.error("Something went wrong");
      }
      dispatch(PagesIndex.hideLoader());
    });
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpay(transactionId) {
    dispatch(PagesIndex.showLoader());
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const result = await PagesIndex.apiPostHandler(
      PagesIndex.Api.CREATE_ORDER,
      {
        transId: transactionId,
        cinemaId: location?.state?.cinemaData.cinemaId,
        sessionId: location?.state?.selectedSessionId,
        userId: userDetails?._id,
        areaCatCode: location?.state?.selectedAreaData?.areaCatCode,
        quantity: location?.state?.selectedSeats.length,
        pGroupCode: location?.state?.pGroupCode,
        fAndB: selectedFood.length ? true : false,
        booking_type: "Web",
      }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    const { amount, id: order_id, currency } = result.data;
    const options = {
      key: "rzp_test_JOC0wRKpLH1cVW", // Enter the Key ID generated from the Dashboard
      currency: currency,
      name: "Connplex",
      description: "Connplex web application",
      image: PagesIndex.Svg.ConnplexLogo,
      order_id: order_id,
      handler: async function (response) {
        const data = {
          razorpay_order_id: order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
        };
        try {
          const result = await PagesIndex.apiPostHandler(
            PagesIndex.Api.RAZORPAY_CALLBACK,
            data
          );
          await dispatch(PagesIndex.hideLoader());
          window.location.href = result;
          window.history.replaceState({}, document.title);
        } catch {
          await dispatch(PagesIndex.hideLoader());
          PagesIndex.toast.error("Something went wrong");
        }
      },
      modal: {
        confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
        // This function is executed when checkout modal is closed
        // There can be 3 reasons when this modal is closed.
        ondismiss: async (reason) => {
          // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
          cancellation = true;
          if (reason === undefined) {
            cancelSeats(transactionId);
            // setSelectedPrice(0);
            // setSelectedSeats([]);
            // setSeatDetails([]);
            // getSeatLayout();
          }
          // Reason 2 - When modal is auto closed because of time out
          else if (reason === "timeout") {
            cancelSeats(transactionId);
            // setSelectedPrice(0);
            // setSelectedSeats([]);
            // setSeatDetails([]);
            // getSeatLayout();
          }
          // Reason 3 - When payment gets failed.
          else {
            cancelSeats(transactionId);
            // setSelectedPrice(0);
            // setSelectedSeats([]);
            // setSeatDetails([]);
            // getSeatLayout();
          }
        },
      },
      timeout: 420,
      // prefill: {
      //   name: "Soumya Dey",
      //   email: "SoumyaDey@example.com",
      //   contact: "9999999999",
      // },
      notes: {
        cinemaId: location?.state?.cinemaData.cinemaId,
        transId: transactionId,
        sessionId: location?.state?.selectedSessionId,
        userId: userDetails?._id,
      },
      theme: {
        color: "#191510",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  const fetchFoodItemList = async () => {
    dispatch(PagesIndex.showLoader());
    const foodList = [];
    await PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_FOODS_AND_BAVERAGES,
      location?.state?.cinemaData?._id
    ).then((response) => {
      if (response.status === 200) {
        response.data.map((data) => {
          const obj = {
            ...data,
            itemPrice:
              data?.itemPrice / 100 + ((data?.itemPrice / 100) * 5) / 100,
            quantity: 0,
          };
          foodList.push(obj);
        });
        setFoodItemList(foodList);
      } else {
        Index.toast.error(response?.message);
      }
      dispatch(PagesIndex.hideLoader());
    });
  };
  const handleAddRemoveItem = (index, value, minMax) => {
    let selectedFoodArr = foodItemList;
    let count = selectedFoodArr?.reduce(
      (currentValue, data) => data.quantity + currentValue,
      0
    );
    setFoodItemList((prev) => {
      let resurtArray = [...prev];
      if (
        minMax !== 0
          ? prev[index].quantity < minMax && count !== 10
          : prev[index].quantity !== minMax
      ) {
        const array = [...prev];
        resurtArray = array.map((dataI, indexI) => {
          if (indexI === index) {
            array[indexI].quantity = array[indexI].quantity + value;
            array[indexI].index = indexI;
            selectedFoodArr.push(array[indexI]);
            setPriceAfterFoodAdd(
              (prev) => prev + value * array[indexI]?.itemPrice
            );
            setSnackPrice((prev) => prev + value * array[indexI]?.itemPrice);
          }
          return array[indexI];
        });
      }
      if (count === 10) {
        setOpenWarning(true);
      }
      const test = selectedFoodArr
        ?.filter((data) => data?.quantity)
        ?.map((obj) => obj);
      setSelectedFood([...new Set(test)]);
      return resurtArray;
    });
  };
  const handleRemoveItem = (index) => {
    let selectedFoodArr = selectedFood;
    setFoodItemList((prev) => {
      let resultArray = [...prev];
      const array = [...prev];
      resultArray = array.map((dataI, indexI) => {
        if (indexI === index) {
          const quantity = array[indexI].quantity;
          setSnackPrice((prev) => prev - quantity * array[indexI]?.itemPrice);
          setPriceAfterFoodAdd(
            (prev) => prev - quantity * array[indexI]?.itemPrice
          );
          array[indexI].quantity = 0;
          selectedFoodArr.push(array[indexI]);
        }
        return array[indexI];
      });
      const test = selectedFoodArr
        ?.filter((data) => data?.index !== index)
        ?.filter((data) => data?.quantity);
      setSelectedFood(test);
      return resultArray;
    });
  };
  const addConssesionForItems = (transactionId) => {
    dispatch(PagesIndex.showLoader());
    const payLoadString = `|${selectedFood?.length}${selectedFood
      ?.map((detail) => `|${detail.itemId}|${detail.quantity}|-1`)
      .join("")}|`;
    const selectedFoodList = selectedFood.map((data) => {
      return {
        itemId: data?.itemId,
        name: data?.itemDescription,
        quantity: data?.quantity,
        price: data?.itemPrice * data?.quantity,
      };
    });
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("cinemaId", location?.state?.cinemaData.cinemaId);
    urlEncoded.append("strTransId", transactionId);
    urlEncoded.append("strItemsOrder", payLoadString);
    urlEncoded.append("itemData", JSON.stringify(selectedFoodList));
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_ITEMS, urlEncoded)
      .then(async (res) => {
        dispatch(PagesIndex.hideLoader());
        if (res?.status === 200) {
          displayRazorpay(location?.state?.transactionId);
        } else {
          PagesIndex.toast.error("Something went wrong");
        }
      })
      .catch(() => {
        dispatch(PagesIndex.hideLoader());
      });
  };

  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Index.Box className="main-add-snacks">
      <Index.Box className="add-snacks-header">
        <Index.Box className="cus-container">
          <Index.Typography
            variant="h1"
            component="h1"
            className="header-title"
          >
            {location?.state?.movieData?.name}
          </Index.Typography>
          <Index.Typography
            variant="p"
            component="p"
            className="header-content"
          >
            {PagesIndex.moment(location?.state?.showDate, "YYYY-MM-DD").format(
              "dddd, DD MMM"
            )}
            ({location?.state?.movieData?.languages} 2D)
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="add-snacks-body">
        <Index.Box className="add-snacks-tab-wrapper">
          <Index.Box className="add-snacks-tab-box">
            <Index.Box className="cus-container">
              <Index.Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="basic tabs example"
              >
                <Index.Tab
                  className="add-snacks-tab"
                  label="All"
                  {...a11yProps(0)}
                />
                <Index.Tab
                  className="add-snacks-tab"
                  label="Combo"
                  {...a11yProps(1)}
                />
                <Index.Tab
                  className="add-snacks-tab"
                  label="Beverage"
                  {...a11yProps(2)}
                />
                <Index.Tab
                  className="add-snacks-tab"
                  label="Popcorn"
                  {...a11yProps(3)}
                />
                <Index.Tab
                  className="add-snacks-tab"
                  label="Burger"
                  {...a11yProps(4)}
                />
              </Index.Tabs>
            </Index.Box>
          </Index.Box>
          <Index.Box className="add-snacks-content-box">
            <Index.Box className="cus-container">
              <Index.Box className="snacks-heading-title">
                Select Snacks
              </Index.Box>
              <Index.Box className="snacks-bottom">
                <Index.Box className="snacks-bottom-left">
                  <AddSnacksTab
                    value={value}
                    index={0}
                    className="add-snacks-tab-content"
                  >
                    <Index.Box className="snacks-card-wrapper">
                      {foodItemList?.map((item, key) => (
                        <Index.Box key={key} className="snacks-card">
                          <img
                            src={
                              item?.poster
                                ? `${PagesIndex.IMAGES_API_ENDPOINT}/${item?.poster}`
                                : PagesIndex.Jpg.snackImg1
                            }
                            width="233"
                            height="173"
                            alt="snacks"
                          />
                          <Index.Box className="snacks-card-content">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="snacks-card-desc"
                            >
                              {item.itemDescription}
                            </Index.Typography>
                            <Index.Box className="snacks-card-bottom">
                              <Index.Typography
                                variant="p"
                                component="p"
                                className="snacks-card-price"
                              >
                                ₹{parseFloat(item.itemPrice).toFixed(2)}
                              </Index.Typography>
                              <Index.Box className="snacks-qty">
                                <PagesIndex.Button
                                  onClick={() => {
                                    handleAddRemoveItem(key, -1, 0);
                                  }}
                                >
                                  -
                                </PagesIndex.Button>
                                <Index.Typography
                                  variant="span"
                                  component="span"
                                  className="snacks-input"
                                >
                                  {item?.quantity}
                                </Index.Typography>
                                <PagesIndex.Button
                                  onClick={() => {
                                    handleAddRemoveItem(key, 1, 10);
                                  }}
                                >
                                  +
                                </PagesIndex.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      ))}
                    </Index.Box>
                  </AddSnacksTab>
                  <AddSnacksTab
                    value={value}
                    index={1}
                    className="add-snacks-tab-content"
                  >
                    <Index.Box className="no-found-img-box">
                      <img src={PagesIndex.Png.Popcorn} alt="No Found" />
                      No Any Combo Found.
                    </Index.Box>
                  </AddSnacksTab>
                  <AddSnacksTab
                    value={value}
                    index={2}
                    className="add-snacks-tab-content"
                  >
                    <Index.Box className="no-found-img-box">
                      <img src={PagesIndex.Png.Popcorn} alt="No Found" />
                      No Any Beverage Found.
                    </Index.Box>
                  </AddSnacksTab>
                  <AddSnacksTab
                    value={value}
                    index={3}
                    className="add-snacks-tab-content"
                  >
                    <Index.Box className="no-found-img-box">
                      <img src={PagesIndex.Png.Popcorn} alt="No Found" />
                      No Any Popcorn Found.
                    </Index.Box>
                  </AddSnacksTab>
                  <AddSnacksTab
                    value={value}
                    index={4}
                    className="add-snacks-tab-content"
                  >
                    <Index.Box className="no-found-img-box">
                      <img src={PagesIndex.Png.Popcorn} alt="No Found" />
                      No Any Burger Found.
                    </Index.Box>
                  </AddSnacksTab>
                </Index.Box>
                <Index.Box className="summary-box-wrapper">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="summary-main-title"
                  >
                    Your Booking
                  </Index.Typography>
                  <Index.Box className="summary-box-inner cus-scrollbar">
                    <Index.Box className="booking-summary">
                      <Index.Box className="selected-movie summary-box-row">
                        <img
                          src={
                            location?.state?.movieData?.poster
                              ? PagesIndex.IMAGES_API_ENDPOINT + location?.state?.movieData?.poster
                              : PagesIndex.Png.NoImage
                          }
                          width="1280"
                          height="80"
                          alt="movie detail"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = PagesIndex.Png.NoImage;
                          }}
                        />
                        <Index.Typography>
                          <Index.Typography className="summary-title">
                            {location?.state?.movieData?.name}
                          </Index.Typography>
                          <Index.Typography className="summary-subtitle">
                            ( {location?.state?.movieData?.category
                              ? location?.state?.movieData?.category?.replace(", ", " | ")
                              : "-"}{" "}
                            | {location?.state?.movieData?.censorRating ? location?.state?.movieData?.censorRating : "-"} )
                          </Index.Typography>
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="selected-seat summary-box-row">
                        <PagesIndex.SeatIcon selected />
                        <Index.Typography
                          variant="span"
                          component="span"
                          className="summary-title"
                        >
                          {location?.state?.selectedSeats.length} Seats{" "}
                          {`(${location?.state?.selectedSeats
                            ?.toString()
                            ?.replaceAll(",", ", ")})`}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="selected-cinema summary-box-row">
                        <Index.FmdGoodIcon />
                        <Index.Box>
                          <Index.Typography
                            variant="span"
                            component="span"
                            className="summary-title"
                          >
                            {location?.state?.cinemaData.displayName}
                          </Index.Typography>
                          <Index.Typography
                            variant="span"
                            component="span"
                            className="summary-subtitle"
                          >
                            {location?.state?.cinemaData.address}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="selected-time summary-box-row">
                        <Index.WatchLaterIcon />
                        <Index.Typography
                          variant="span"
                          component="span"
                          className="summary-title"
                        >
                          {location?.state?.selectedShowTiming}
                        </Index.Typography>
                      </Index.Box>
                      {selectedFood?.length > 0 && (
                        <Index.Box className="selected-snacks-box">
                          <Index.Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleExpanded("panel1")}
                          >
                            <Index.AccordionSummary
                              expandIcon={<Index.ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Index.Typography
                                variant="span"
                                component="span"
                                className="summary-title"
                              >
                                Food And Beverage
                              </Index.Typography>
                            </Index.AccordionSummary>
                            <Index.AccordionDetails>
                              {selectedFood?.map((item) => {
                                return (
                                  <Index.Box className="selected-snacks summary-box-row">
                                    <img
                                      src={
                                        item?.poster
                                          ? `${PagesIndex.IMAGES_API_ENDPOINT}/${item?.poster}`
                                          : PagesIndex.Jpg.snackImg1
                                      }
                                      width="233"
                                      height="173"
                                      alt="snacks"
                                    />
                                    <Index.Box className="snacks-summary">
                                      <Index.Typography
                                        variant="p"
                                        component="p"
                                        className="snacks-desc"
                                      >
                                        {item?.itemDescription}
                                      </Index.Typography>
                                      <Index.Typography
                                        variant="p"
                                        component="p"
                                        className="snacks-price"
                                      >
                                        ₹{parseFloat(item.itemPrice).toFixed(2)}
                                      </Index.Typography>
                                      <Index.Box className="selected-snacks-bottom">
                                        <Index.Box className="snacks-qty">
                                          <PagesIndex.Button
                                            onClick={() => {
                                              handleAddRemoveItem(
                                                item?.index,
                                                -1,
                                                0
                                              );
                                            }}
                                          >
                                            -
                                          </PagesIndex.Button>
                                          <Index.Typography
                                            variant="span"
                                            component="span"
                                            className="snacks-input"
                                          >
                                            {item?.quantity}
                                          </Index.Typography>
                                          <PagesIndex.Button
                                            onClick={() => {
                                              handleAddRemoveItem(
                                                item?.index,
                                                1,
                                                10
                                              );
                                            }}
                                          >
                                            +
                                          </PagesIndex.Button>
                                        </Index.Box>
                                        <Index.Button
                                          className="snacks-remove"
                                          onClick={() =>
                                            handleRemoveItem(item?.index)
                                          }
                                        >
                                          Remove
                                        </Index.Button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                );
                              })}
                            </Index.AccordionDetails>
                          </Index.Accordion>
                        </Index.Box>
                      )}
                      <Index.Box className="apply-promocode-box">
                        <Index.TextField
                          fullWidth
                          id="promoCode"
                          className="form-control"
                          placeholder="Enter your Promocode"
                        />
                        <PagesIndex.Button primary>Apply</PagesIndex.Button>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="payment-summary">
                      <Index.Box className="payment-summary-row">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="payment-summary-label"
                        >
                          <Index.Typography variant="p" component="p">
                            Ticket Price :
                          </Index.Typography>
                          <Index.Typography variant="p" component="p">
                            ({location?.state?.selectedSeats.length} x ₹
                            {location?.state?.selectedAreaData.currentPrice})
                          </Index.Typography>
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="payment-summary-price"
                        >
                          ₹
                          {parseFloat(
                            location.state?.ticketPriceDetails?.total
                          ).toFixed(2)}
                        </Index.Typography>
                      </Index.Box>
                      {selectedFood.length ? (
                        <Index.Box className="payment-summary-row">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="payment-summary-label"
                          >
                            F&B Price :
                          </Index.Typography>
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="payment-summary-price"
                          >
                            ₹{parseFloat(snackPrice).toFixed(2)}
                          </Index.Typography>
                        </Index.Box>
                      ) : (
                        ""
                      )}
                      <Index.Box className="payment-summary-row">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="payment-summary-label"
                        >
                          CGST :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="payment-summary-price"
                        >
                          ₹
                          {parseFloat(
                            location?.state?.ticketPriceDetails?.tax1
                          ).toFixed(2)}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="payment-summary-row">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="payment-summary-label"
                        >
                          SGST :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="payment-summary-price"
                        >
                          ₹
                          {parseFloat(
                            location?.state?.ticketPriceDetails?.tax2
                          ).toFixed(2)}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="payment-summary-row">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="payment-summary-label"
                        >
                          <Index.Typography variant="p" component="p">
                            Convenience fee:
                          </Index.Typography>
                          <Index.Typography variant="p" component="p">
                            ({location?.state?.selectedSeats.length} x ₹
                            {location?.state?.cinemaData?.convenienceFees})
                          </Index.Typography>
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="payment-summary-price"
                        >
                          ₹
                          {parseFloat(location?.state?.convenienceFees).toFixed(
                            2
                          )}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="total-payment-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="total-payment"
                      >
                        Total :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="total-payment"
                      >
                        ₹{parseFloat(priceAfterFoodAdd).toFixed(2)}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="summary-box-button">
                      <PagesIndex.Button
                        primary
                        className="book-now-btn"
                        onClick={() => {
                          // displayRazorpay(location?.state?.transactionId);
                          if (selectedFood.length) {
                            addConssesionForItems(
                              location?.state?.transactionId
                            );
                          } else {
                            displayRazorpay(location?.state?.transactionId);
                          }
                        }}
                      >
                        Proceed to Pay
                      </PagesIndex.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openWarning}
        onClose={handleCloseWarning}
        className="max-seat-warning-modal"
      >
        <Index.Box className="max-seat-warning-modal-inner">
          <Index.Box className="modal-inner cus-scrollbar">
            <Index.Box className="max-seat-warning">
              <Index.ErrorIcon />
              Warning
            </Index.Box>
            <Index.Typography className="max-seat-warning-text">
              You cannot select more than 10 snacks.
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}

export default AddSnacks;
