export default {
  getUserDetails: "getUserDetails",
  getUserToken: "getUserToken",
  userLogOut: "userLogOut",
  changeRegion: "changeRegion",
  upcomingMoviesList: "upcomingMoviesList",
  hideLoader: "hideLoader",
  showLoader: "showLoader",
  getOtpTimer: "getOtpTimer",
};
