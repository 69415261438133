import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function Calender({ props }) {
  const dispatch = PagesIndex.useDispatch();
  const [genre, setGenre] = useState("");
  const [language, setLang] = useState("");
  const [moviesList, setMoviesList] = useState([]);
  const [filterMonths, setFilterMonths] = useState([]);
  useEffect(() => {
    getMoviesList();
  }, []);
  const handleChangeGenre = (event) => {
    setGenre(event.target.value);
  };
  const handleChangeLang = (event) => {
    setLang(event.target.value);
  };

  const getMoviesList = () => {
    dispatch(PagesIndex.showLoader());
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CALENDER_LIST).then((res) => {
      if (res?.status === 200) {
        const movieData = res.data.map((data) => {
          return {
            month: data?.month,
            startDate: PagesIndex.moment(data?.month, "MMM YYYY")
              .startOf("month")
              .format("DD MMM"),
            endDate: PagesIndex.moment(data?.month, "MMM YYYY")
              .endOf("month")
              .format("DD MMM"),
            year: PagesIndex.moment(data?.month, "MMM YYYY").format("YYYY"),
            id: PagesIndex.moment(data?.month, "MMM YYYY").format("MMM_YYYY"),
            movies: data?.movies.filter((item) => {
              var startTime = PagesIndex.moment(item?.filmOpeningDate).format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              );
              var currentTime = PagesIndex.moment().format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              );
              let timeisGt = startTime < currentTime;
              return !timeisGt;
            }),
          };
        });
        setMoviesList(movieData);
        getFutureMonths(res?.data?.length - 1);
      }
      dispatch(PagesIndex.hideLoader());
    });
  };
  const getFutureMonths = (length) => {
    let months = [];
    let monthsRequired = length;
    for (let i = 0; i <= monthsRequired; i++) {
      const a = PagesIndex.moment();
      const b = PagesIndex.moment().add(i, "months");
      let objData = {
        month: b.format("MMM"),
        year: b.format("YYYY"),
        id: b.format("MMM_YYYY"),
        selected: b.diff(a, "months") === 0 ? true : false,
      };
      months.push(objData);
    }
    setFilterMonths(months);
  };
  return (
    <Index.Box className="main-calender">
      <PagesIndex.BannerImage
        bannerImage={PagesIndex.Jpg.upcomingBanner}
        bannerImageWidth="900"
        bannerImageHeight="570"
        bannerTitle="Upcoming"
      />
      <Index.Box className="calender-filter">
        <Index.Box className="cus-container">
          <Index.Box className="calender-filter-left">
            <PagesIndex.Swiper
              modules={[PagesIndex.Navigation]}
              slidesPerView={"auto"}
              spaceBetween={5}
              breakpoints={{
                550: {
                  slidesPerView: "auto",
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 15,
                },
              }}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              {...props}
            >
              {filterMonths.map((item, key) => (
                <PagesIndex.SwiperSlide key={key}>
                  <a href={`#${item.id}`} className="calender-filter-item">
                    <Index.Typography
                      variant="span"
                      component="span"
                      className="filter-year"
                    >
                      {item.year}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="filter-month"
                    >
                      {item.month}
                    </Index.Typography>
                  </a>
                </PagesIndex.SwiperSlide>
              ))}
            </PagesIndex.Swiper>
            <Index.Box className="swiper-button-prev" />
            <Index.Box className="swiper-button-next" />
          </Index.Box>
          <Index.Box className="calender-filter-right">
            <div className="filter-dropdown-main genre-category-dropdown">
              <div className="filter-drop-btn">
                <p className="filter-btn-title">Genre</p>
                <img
                  src={PagesIndex.Svg.ArrowDownIcon}
                  className="filter-down-arrow"
                />
              </div>
              <ul className="filter-ul">
                <li className="filter-li active">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">Drama</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
                <li className="filter-li">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">Action</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
                <li className="filter-li">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">Crime</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
                <li className="filter-li">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">Thriller</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
                <li className="filter-li">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">Animation</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
                <li className="filter-li">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">Family</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className="filter-dropdown-main lang-category-dropdown">
              <div className="filter-drop-btn">
                <p className="filter-btn-title">Language</p>
                <img
                  src={PagesIndex.Svg.ArrowDownIcon}
                  className="filter-down-arrow"
                />
              </div>
              <ul className="filter-ul">
                <li className="filter-li active">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">English</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
                <li className="filter-li">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">Hindi</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
                <li className="filter-li">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">Gujarati</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
                <li className="filter-li">
                  <div className="filter-check-flex">
                    <Index.FormControlLabel
                      label={<p className="filter-data-text">Tamil</p>}
                      control={<Index.Checkbox className="filter-checkbox" />}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="calender-body">
        <Index.Box className="cus-container">
          {moviesList?.map((item, key) =>
            item.movies.length ? (
              <Index.Box
                key={key}
                className="upcoming-wrapper-box"
                id={item.id}
              >
                <Index.Box className="upcoming-heading">
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="upcoming-heading-year"
                  >
                    {item.year}
                  </Index.Typography>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="upcoming-heading-month"
                  >
                    {item.startDate} - {item.endDate}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="upcoming-card-wrapper">
                  {item.movies.map((res, key) => (
                    <Index.Box key={key} className="upcoming-card">
                      <Index.Box className="upcoming-card-img">
                        <img
                          src={`${PagesIndex.IMAGES_API_ENDPOINT}/${res.poster}`}
                          alt="movie"
                          width="585"
                          height="800"
                        />
                        <Index.Typography
                          variant="span"
                          component="span"
                          className="upcoming-card-icon"
                        >
                          <Index.OpenInNewIcon />
                        </Index.Typography>
                        <Index.Box className="upcoming-card-details">
                          <Index.Box className="">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="card-details-genre"
                            >
                              {res.category}
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="card-details-lang"
                            >
                              {res.languages}
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="card-details-cast-crew"
                            >
                              <Index.Typography
                                variant="span"
                                component="span"
                                className="card-details-label"
                              >
                                Cast & Crew :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                variant="span"
                                component="span"
                                className="card-details-value"
                              >
                                {res.starCast
                                  .map((data) => data?.starCastId?.name)
                                  .join(", ")}
                              </Index.Typography>
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="upcoming-card-title">
                        {res.name}
                      </Index.Box>
                      <Index.Box className="upcoming-card-release">
                        Releasing on{" "}
                        {PagesIndex.moment(res.filmOpeningDate).format(
                          "ddd MMM DD"
                        )}
                      </Index.Box>
                    </Index.Box>
                  ))}
                </Index.Box>
              </Index.Box>
            ) : (
              ""
            )
          )}
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default Calender;
