import React from 'react'
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';

function TransactionFailed() {

    return (

        <Index.Box className="main-transaction-failed">
            <Index.Box className='main-content'>
                <Index.Box className="transaction-failed-wrapper">
                    <Index.Box className="transaction-failed-img">
                        <img src={PagesIndex.Png.TransactionFailedImg} alt="Transaction Failed" />
                    </Index.Box>
                    <Index.Typography
                        variant="h1"
                        component="h1"
                        className="transaction-failed-title"
                    >
                        Transaction Failed
                    </Index.Typography>
                    <Index.Typography className="transaction-failed-subtitle">
                        Oh snap! Your transaction has been expired. Please try again.
                    </Index.Typography>
                    <Index.Box className="transaction-failed-btn-box">
                        <Index.Link to="#" className='btn btn-secondary'>
                            View Order Details
                        </Index.Link>
                        <Index.Link to="/" className='btn btn-primary'>
                            Go home
                        </Index.Link>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </Index.Box>

    )

}

export default TransactionFailed