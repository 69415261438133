import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../components/Index";
import PagesIndex from "../../../components/PagesIndex";
import {
  LoginSocialApple,
  LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login";

function Login(props) {
  const signInRef = useRef();
  const otpRef = useRef();
  const googleRef = useRef();
  const facebookRef = useRef();
  const dispatch = PagesIndex.useDispatch();
  const { otpTimer } = PagesIndex.useSelector((state) => state.UserReducer);
  const { signInClose } = props;
  const [open, setOpen] = useState(true);
  const [id, setId] = useState();
  const initialValues = {
    emailOrPhone: "",
    otp: "",
  };
  function toggleBool(values) {
    setOpen(!open);
  }

  useEffect(() => {
    let myInterval;
    if (!open) {
      myInterval = setTimeout(() => {
        if (otpTimer.seconds > 0) {
          dispatch(
            PagesIndex.getOtpTimer({
              minute: otpTimer.minute,
              seconds: otpTimer.seconds - 1,
            })
          );
        }
        if (otpTimer.seconds == 0) {
          if (otpTimer.minute == 0) {
            clearTimeout(myInterval);
            dispatch(
              PagesIndex.getOtpTimer({
                seconds: 0,
                minute: 0,
              })
            );
          } else {
            dispatch(
              PagesIndex.getOtpTimer({
                minute: otpTimer.minute - 1,
                seconds: 59,
              })
            );
          }
        }
      }, 1000);
    }
    return () => {
      clearTimeout(myInterval);
    };
  });

  const onLogoutFailure = useCallback(() => {
    alert("logout fail");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    alert("logout success");
  }, []);
  const signinContent = () => {
    function handleSubmitSignin(values) {
      dispatch(PagesIndex.showLoader());
      let payload = { login_type: "Web" };
      if (values?.emailOrPhone?.includes("@")) {
        payload.email = values?.emailOrPhone;
      } else {
        payload.mobileNumber = values?.emailOrPhone;
      }
      PagesIndex.apiPostHandler(PagesIndex.Api.LOGIN, payload).then((res) => {
        if (res?.status === 201) {
          setId(res?.data?._id);
          signInRef.current.resetForm();
          dispatch(
            PagesIndex.getOtpTimer({
              minute: 0,
              seconds: 30,
            })
          );
          toggleBool();
        }
        dispatch(PagesIndex.hideLoader());
      });
    }
    function handleSocialLogin(apiParams) {
      dispatch(PagesIndex.showLoader());
      let payload = apiParams;
      PagesIndex.apiPostHandler(PagesIndex.Api.SOCIAL_LOGIN, payload).then(
        (res) => {
          if (res?.status === 200 || res?.status === 201) {
            signInClose();
            dispatch(PagesIndex.getUserData(res?.data));
            dispatch(PagesIndex.getUserToken(res?.data?.token));
          }
          dispatch(PagesIndex.hideLoader());
        }
      );
    }
    return (
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleSubmitSignin}
        initialValues={initialValues}
        validationSchema={PagesIndex.loginValidationSchema}
        innerRef={signInRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
        }) => (
          <Index.Box className="signin-modal-right cus-scrollbar">
            <Index.Stack
              className="form-control"
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Index.Typography
                variant="p"
                component="p"
                className="signin-title"
              >
                Sign In
              </Index.Typography>
              <Index.Typography
                variant="p"
                component="p"
                className="signin-content"
              >
                Enter your mobile number OR email to sign in
              </Index.Typography>
              <Index.Box className="signin-form">
                <Index.TextField
                  fullWidth
                  id="signInPhone"
                  name="emailOrPhone"
                  className="form-control"
                  placeholder="Mobile number or email"
                  onChange={(e) => {
                    setFieldTouched("emailOrPhone");
                    handleChange(e);
                  }}
                  inputProps={{ maxLength: 320 }}
                  error={
                    errors.emailOrPhone && touched.emailOrPhone ? true : false
                  }
                  helperText={
                    errors.emailOrPhone && touched.emailOrPhone
                      ? errors.emailOrPhone
                      : null
                  }
                />
                <PagesIndex.Button type="submit" primary className="signin-btn">
                  Get OTP
                </PagesIndex.Button>
              </Index.Box>
              <Index.Box className="signup-option">
                <Index.Box className="signup-title-box">
                  <Index.Box className="signup-title-line"></Index.Box>
                  <Index.Box className="signup-title">
                    Or Sign Up With
                  </Index.Box>
                  <Index.Box className="signup-title-line"></Index.Box>
                </Index.Box>
                <Index.Box className="signup-option-box">
                  {/* <LoginSocialApple
                    client_id={""}
                    scope={"name email"}
                    redirect_uri={""}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }) => {}}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  > */}
                  <Index.Box className="signup-option-item">
                    <img
                      src={PagesIndex.Svg.AppleIcon}
                      width="34"
                      height="45"
                      alt="Apple Icon"
                    />
                  </Index.Box>
                  {/* </LoginSocialApple> */}
                  <LoginSocialGoogle
                    ref={googleRef}
                    client_id="361165071167-dvqku6rbrdcgl4go4470ugkmffdaqd83.apps.googleusercontent.com"
                    onLogoutFailure={onLogoutFailure}
                    onLogoutSuccess={onLogoutSuccess}
                    onResolve={({ provider, data }) => {
                      handleSocialLogin({
                        firstName: data?.given_name,
                        lastName: data?.family_name,
                        email: data?.email,
                        providerId: data?.sub,
                        accessToken: data?.access_token,
                        source: "google",
                        login_type: "Web",
                      });
                    }}
                    scope="openid profile email"
                    onReject={(err) => {}}
                  >
                    <Index.Box
                      className="signup-option-item"
                      // onClick={() => {
                      //   handleSocialLogin(PagesIndex.Api.GOOGLE_LOGIN);
                      // }}
                    >
                      <img
                        src={PagesIndex.Svg.GoogleIcon}
                        width="48"
                        height="48"
                        alt="Apple Icon"
                      />
                    </Index.Box>
                  </LoginSocialGoogle>
                  <LoginSocialFacebook
                    ref={facebookRef}
                    appId={"866492805044644"}
                    fieldsProfile={
                      "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                    }
                    onLogoutSuccess={onLogoutSuccess}
                    onResolve={({ provider, data }) => {
                      handleSocialLogin({
                        firstName: data?.first_name,
                        lastName: data?.last_name,
                        email: data?.email,
                        providerId: data?.id,
                        accessToken: data?.accessToken,
                        source: "facebook",
                        login_type: "Web",
                      });
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <Index.Box className="signup-option-item">
                      <img
                        src={PagesIndex.Svg.FacebookIcon}
                        width="24"
                        height="24"
                        alt="Apple Icon"
                      />
                    </Index.Box>
                  </LoginSocialFacebook>
                </Index.Box>
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        )}
      </PagesIndex.Formik>
    );
  };

  const otpContent = () => {
    const handleSubmit = (values) => {
      let payload = {
        id: id,
        otp: values?.otp,
      };
      PagesIndex.apiPostHandler(PagesIndex.Api.VERIFY_OTP, payload).then(
        (res) => {
          if (res?.status === 200) {
            setId("");
            signInClose();
            dispatch(PagesIndex.getUserData(res?.data));
            dispatch(PagesIndex.getUserToken(res?.data?.token));
            setOpen(!open);
            otpRef.current.resetForm();
            PagesIndex.toast.success("Logged In");
          } else {
            otpRef.current.resetForm();
            PagesIndex.toast.error(res?.message);
          }
        }
      );
    };
    const resendOtp = (userInput) => {
      let payload;
      if (userInput?.emailOrPhone?.includes("@")) {
        payload = {
          id: id,
          email: userInput?.emailOrPhone,
        };
      } else {
        payload = {
          id: id,
          mobileNumber: userInput?.emailOrPhone,
        };
      }
      PagesIndex.apiPostHandler(PagesIndex.Api.RESEND_OTP, payload).then(
        (res) => {
          if (res?.status === 201) {
            dispatch(
              PagesIndex.getOtpTimer({
                seconds: 30,
                minute: 0,
              })
            );
            PagesIndex.toast.success(res?.message);
          } else {
            PagesIndex.toast.error(res?.message);
          }
        }
      );
    };
    return (
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={PagesIndex.otpSchema}
        innerRef={otpRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Index.Box className="otp-modal-right cus-scrollbar">
            <Index.Stack
              className="form-control"
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Index.Typography variant="p" component="p" className="otp-title">
                Verify OTP
              </Index.Typography>
              <Index.Typography
                variant="p"
                component="p"
                className="otp-content"
              >
                Enter OTP which is sent to your mobile number OR email address
              </Index.Typography>
              <Index.Box className="otp-form-wrapper">
                <Index.Box className="otp-form-inner-wrapper">
                  <PagesIndex.OTPInput
                    shouldAutoFocus
                    name="otp"
                    numInputs={4}
                    inputStyle="form-control"
                    containerStyle="otp-form"
                    inputType="number"
                    value={values.otp}
                    onChange={(value) => {
                      setFieldValue("otp", value);
                      setFieldTouched("otp");
                    }}
                    error={errors.otp && touched.otp ? true : false}
                    renderInput={(props) => <input {...props} />}
                  />
                  <Index.FormHelperText error>
                    {errors.otp && touched.otp ? errors.otp : null}
                  </Index.FormHelperText>
                  <Index.Box className="resend-otp-box">
                    <Index.Typography variant="span" component="span">
                      Didn't recieved OTP?
                    </Index.Typography>
                    {otpTimer.minute === 0 && otpTimer.seconds === 0 ? (
                      <button
                        className="resend-otp-btn"
                        onClick={() => resendOtp(values)}
                        type="button"
                      >
                        Resend
                      </button>
                    ) : (
                      <Index.Typography variant="span" component="span">
                        {otpTimer.minute < 10
                          ? `0${otpTimer.minute}`
                          : otpTimer.minute}
                        :
                        {otpTimer.seconds < 10
                          ? `0${otpTimer.seconds}`
                          : otpTimer.seconds}
                      </Index.Typography>
                    )}
                  </Index.Box>
                </Index.Box>
                <PagesIndex.Button primary className="otp-btn" type="submit">
                  Verify OTP
                </PagesIndex.Button>
                <Index.Box className="back-btn-box">
                  <Index.Box onClick={toggleBool} className="back-btn">
                    <Index.ArrowRightAltIcon />
                    Back
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        )}
      </PagesIndex.Formik>
    );
  };
  return (
    <Index.Box className="signin-modal-inner">
      <Index.Box className="modal-inner">
        <Index.Box className="signin-modal-left">
          <img
            src={PagesIndex.Gif.SigninImg2}
            width="279"
            height="450"
            alt="sign in"
            className="sign-in-bg"
          />
          <img
            src={PagesIndex.Gif.SigninImg1}
            width="279"
            height="450"
            alt="sign in"
            className="sign-in-logo"
          />
        </Index.Box>
        {open ? signinContent() : otpContent()}
      </Index.Box>
    </Index.Box>
  );
}

export default Login;
