import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function BookingInfo() {
  const dispatch = PagesIndex.useDispatch();
  const location = PagesIndex.useLocation();
  const transId = new URLSearchParams(location.search).get("transId");
  const [bookingDetails, setBookingDetails] = useState({});
  useEffect(() => {
    getBookingDetails();
  }, []);
  const getBookingDetails = () => {
    dispatch(PagesIndex.showLoader());
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("initTransId", transId);
    PagesIndex.apiPostHandler(PagesIndex.Api.BOOKING_DETAILS, urlEncoded)
      .then((res) => {
        setBookingDetails(res.data);
        dispatch(PagesIndex.hideLoader());
      })
      .catch(() => {
        dispatch(PagesIndex.hideLoader());
      });
  };
  return (
    <Index.Box className="main-booking-info">
      <Index.Box className="cus-container">
        <Index.Box className="booking-info-header">
          <Index.Box className="confirm-icon">
            <Index.DoneAllIcon />
          </Index.Box>
          <Index.Typography className="confirm-text">
            Your Booking Details
          </Index.Typography>
        </Index.Box>
        <Index.Box className="booking-info-body">
          <Index.Box className="booking-qr-info">
            <PagesIndex.QRCode
              value={bookingDetails?.addSeatData?.strBookId}
              renderAs="canvas"
              bgColor={"#23211e"}
              fgColor={"#bcbcbc"}
              size={125}
            />
            <Index.Box className="booking-code-text-wrapper">
              <Index.Typography className="booking-code-text">
                Booking ID
              </Index.Typography>
              <Index.Typography className="booking-code-text">
                {bookingDetails?.addSeatData?.strBookId}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="booking-detail-box">
            <img
              className="movie-img"
              src={`${PagesIndex.IMAGES_API_ENDPOINT}/${bookingDetails?.movieId?.poster}`}
              width="585"
              height="800"
              alt="movie"
            />
            <Index.Box className="booking-detail-summary">
              <Index.Box className="booking-detail-title-box">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="booking-detail-title"
                >
                  {bookingDetails?.movieId?.name}
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="booking-detail-content"
                >
                  {bookingDetails?.movieId?.languages} (
                  {bookingDetails?.movieId?.censorRating}) -{" "}
                  {bookingDetails?.movieId?.category}
                </Index.Typography>
              </Index.Box>
              <Index.Typography
                variant="p"
                component="p"
                className="booking-detail-value"
              >
                {PagesIndex.moment(bookingDetails?.showId?.sessionRealShow)
                  .format("MMM DD, YYYY")}{" "}
                |{" "}
                {PagesIndex.moment(bookingDetails?.showId?.sessionRealShow)
                  .format("hh:mm A")}
              </Index.Typography>
              <Index.Typography
                variant="p"
                component="p"
                className="booking-detail-value"
              >
                <Index.Typography variant="p" component="p">
                  {bookingDetails?.showId?.screenName}
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="booking-detail-content"
                  >
                    &nbsp;({" "}
                    {
                      bookingDetails?.setSeatData?.strSeatInfo
                        .split(" - ")[1]
                        .split(",").length
                    }{" "}
                    Tickets )
                  </Index.Typography>
                </Index.Typography>
                {bookingDetails?.setSeatData?.strSeatInfo}
              </Index.Typography>
              <Index.Typography
                variant="p"
                component="p"
                className="booking-detail-value"
              >
                <Index.Typography
                  variant="span"
                  component="span"
                  className="booking-detail-content"
                >
                  {bookingDetails?.cinemaId?.displayName} : &nbsp;
                </Index.Typography>
                <a href={bookingDetails?.cinemaId?.googleUrl} target="_blank">
                  {bookingDetails?.cinemaId?.address}
                </a>
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="booking-summary-bottom">
            <Index.Box className="payment-summary">
              <Index.Box className="payment-summary-row">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="payment-summary-label"
                >
                  <Index.Typography variant="p" component="p">
                    Ticket Price :
                  </Index.Typography>
                  <Index.Typography variant="p" component="p">
                    (
                    {
                      bookingDetails?.setSeatData?.strSeatInfo
                        .split(" - ")[1]
                        .split(",").length
                    }{" "}
                    x ₹
                    {parseFloat(
                      bookingDetails?.commitBookingData?.curTicketsTotal /
                        bookingDetails?.setSeatData?.strSeatInfo
                          .split(" - ")[1]
                          .split(",").length
                    ).toFixed(2)}
                    )
                  </Index.Typography>
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="payment-summary-price"
                >
                  ₹
                  {parseFloat(
                    bookingDetails?.commitBookingData?.curTicketsTotal
                  ).toFixed(2)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="payment-summary-row">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="payment-summary-label"
                >
                  CGST :
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="payment-summary-price"
                >
                  ₹0.00
                </Index.Typography>
              </Index.Box>
              <Index.Box className="payment-summary-row">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="payment-summary-label"
                >
                  SGST :
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="payment-summary-price"
                >
                  ₹0.00
                </Index.Typography>
              </Index.Box>
              <Index.Box className="payment-summary-row">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="payment-summary-label"
                >
                  <Index.Typography variant="p" component="p">
                    Convenience fee :
                  </Index.Typography>
                  <Index.Typography variant="p" component="p">
                    (
                    {
                      bookingDetails?.setSeatData?.strSeatInfo
                        .split(" - ")[1]
                        .split(",").length
                    }{" "}
                    x ₹{bookingDetails?.cinemaId?.convenienceFees} - Including
                    GST)
                  </Index.Typography>
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="payment-summary-price"
                >
                  ₹
                  {parseFloat(
                    bookingDetails?.cinemaId?.convenienceFees *
                      bookingDetails?.setSeatData?.strSeatInfo
                        .split(" - ")[1]
                        .split(",").length
                  ).toFixed(2)}
                </Index.Typography>
              </Index.Box>
              {bookingDetails?.fAndBDetails?.length ? (
                <Index.Box className="payment-summary-row food-list-row">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-label"
                  >
                    <Index.Typography variant="p" component="p">
                      Food and beverages price :
                    </Index.Typography>
                    <Index.Typography variant="p" component="p">
                      {bookingDetails?.fAndBDetails?.map((data) => {
                        return (
                          <Index.Box component="p" className="food-list-item">
                            {data?.name.toLowerCase()} (x{data?.quantity})
                            <br />
                          </Index.Box>
                        );
                      })}
                    </Index.Typography>
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-price"
                  >
                    + ₹
                    {parseFloat(
                      bookingDetails?.foodAndBvgResponse?.curFoodTotal +
                        (bookingDetails?.foodAndBvgResponse?.curFoodTotal * 5) /
                          100
                    ).toFixed(2)}
                  </Index.Typography>
                </Index.Box>
              ) : (
                ""
              )}
            </Index.Box>
            <Index.Box className="total-payment-row">
              <Index.Typography
                variant="p"
                component="p"
                className="total-payment"
              >
                Total :
              </Index.Typography>
              <Index.Typography
                variant="p"
                component="p"
                className="total-payment"
              >
                ₹
                {parseFloat(
                  bookingDetails?.paymentResponse?.amount / 100
                ).toFixed(2)}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default BookingInfo;
