import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function Cinema() {
  const { region } = PagesIndex.useSelector((state) => state.UserReducer);
  const [cinemaList, setCinemaList] = useState([]);

  useEffect(() => {
    if (region) {
      getCinemasByRegionList();
    }
  }, [region]);

  const getCinemasByRegionList = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CINEMA_BY_ID, region._id).then(
      (res) => {
        if (res?.status === 200) {
          setCinemaList(res.data);
        }
      }
    );
  };
  return (
    <Index.Box className="main-cinema">
      <Index.Box className="cus-container">
        <Index.Box className="cinema-header">
          <Index.Typography
            variant="h1"
            component="h1"
            className="cinema-header-title"
          >
            Cinemas
          </Index.Typography>
          <Index.Typography
            variant="p"
            component="p"
            className="cinema-header-subtitle"
          >
            WE ARE SPREAD ACROSS INDIA
          </Index.Typography>
        </Index.Box>
        {cinemaList.length ? (
          <Index.Box className="cinema-body">
            {cinemaList.map((item, key) => (
              <Index.Box key={key} className="cinema-box">
                <img
                  src={
                    item.poster
                      ? `${PagesIndex.IMAGES_API_ENDPOINT}${item.poster}`
                      : PagesIndex.Png.NoImageCinema
                  }
                  alt="cinema"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = PagesIndex.Png.NoImageCinema;
                  }}
                />
                <Index.Box className="cinema-box-content">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="cinema-title"
                  >
                    {item.displayName}
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="cinema-city"
                  >
                    {item.emailId}
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="cinema-address"
                  >
                    {item.address}
                  </Index.Typography>
                </Index.Box>
                <Index.Link
                  to={`/cinema-detail?cId=${item?._id}`}
                  className="cinema-box-link"
                />
              </Index.Box>
            ))}
          </Index.Box>
        ) : (
          <Index.Box className="no-cinema-body">
            <Index.Box className="no-found-img-box">
              <img src={PagesIndex.Png.Theatre} alt="No Found" />
              No theatre found
            </Index.Box>
          </Index.Box>
        )}
      </Index.Box>
    </Index.Box>
  );
}

export default Cinema;
