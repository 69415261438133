import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "swiper/css/effect-fade";

function Home() {
  const dispatch = PagesIndex.useDispatch();
  const { region } = PagesIndex.useSelector((state) => state.UserReducer);
  const [upcomingMoviesList, setUpcomingMoviesList] = useState([]);
  const [nowPlayingList, setNowPlayingList] = useState([]);
  const [sliderList, setSliderList] = useState([]);
  const [aboveUpcomingBannerList, setAboveUpcomingBannerList] = useState([]);
  const [belowUpcomingBannerList, setBelowUpcomingBannerList] = useState([]);
  useEffect(() => {
    getSliderImages();
    getBannerImages();
    getUpcomingMoviesList();
  }, []);
  useEffect(() => {
    if (region) {
      getMoviesByRegionList();
    }
  }, [region]);

  const getMoviesByRegionList = () => {
    dispatch(PagesIndex.showLoader());
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_MOVIES_BY_ID, region._id).then(
      (res) => {
        if (res?.status === 200) {
          setNowPlayingList(res.data);
        }
        dispatch(PagesIndex.hideLoader());
      }
    );
  };
  const getUpcomingMoviesList = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CALENDER_LIST).then((res) => {
      if (res?.status === 200) {
        let movieData = [];
        movieData = res.data
          ?.find(
            (data) =>
              PagesIndex.moment(data.month, "MMM YYYY").format("MMM YYYY") ===
              PagesIndex.moment().format("MMM YYYY")
          )
          ?.movies?.filter((data) => {
            var startTime = PagesIndex.moment(data?.filmOpeningDate)
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            var currentTime = PagesIndex.moment().format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            );
            let timeisGt = startTime <= currentTime;
            return !timeisGt;
          });
        if (!movieData?.length) {
          movieData = res.data
            ?.find(
              (data) =>
                PagesIndex.moment(data.month, "MMM YYYY").format("MMM YYYY") ===
                PagesIndex.moment().add(1, "months").format("MMM YYYY")
            )
            ?.movies?.filter((data) => {
              var startTime = PagesIndex.moment(data?.filmOpeningDate)
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
              var currentTime = PagesIndex.moment().format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              );
              let timeisGt = startTime <= currentTime;
              return !timeisGt;
            });
        }
        dispatch(PagesIndex.upcomingMoviesList(movieData));
        setUpcomingMoviesList(movieData);
      }
    });
  };
  const getSliderImages = () => {
    dispatch(PagesIndex.showLoader());
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SLIDER_IMAGES).then((res) => {
      if (res?.status === 200) {
        setSliderList(res.data);
      }
      dispatch(PagesIndex.hideLoader());
    });
  };
  const getBannerImages = () => {
    dispatch(PagesIndex.showLoader());
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_BANNER_IMAGES).then((res) => {
      if (res?.status === 200) {
        const bannerListAboveUpcoming = res.data.filter((data) =>
          data?.bannerShowSection.includes("Above Upcoming Section")
        );
        const bannerListBelowUpcoming = res.data.filter((data) =>
          data?.bannerShowSection.includes("Below Upcoming Section")
        );
        setAboveUpcomingBannerList(bannerListAboveUpcoming);
        setBelowUpcomingBannerList(bannerListBelowUpcoming);
      }
      dispatch(PagesIndex.hideLoader());
    });
  };
  return (
    <Index.Box className="main-home">
      <Index.Box className="main-hero-slider">
        <PagesIndex.Swiper
          modules={[PagesIndex.Autoplay, PagesIndex.EffectFade]}
          loop={true}
          speed={1500}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          effect={"fade"}
        >
          {sliderList.map((data, key) => {
            return (
              <PagesIndex.SwiperSlide key={key}>
                <Index.Box className="hero-slider-img">
                  <img
                    src={`${PagesIndex.IMAGES_API_ENDPOINT}${data?.image}`}
                    alt="banner slider"
                    width="1920"
                    height="600"
                  />
                </Index.Box>
              </PagesIndex.SwiperSlide>
            );
          })}
        </PagesIndex.Swiper>
      </Index.Box>
      <Index.Box className="main-showing-part movie-slider">
        <Index.Box className="cus-container">
          <Index.Box className="showing-txt movie-slider-heading">
            <Index.Typography variant="h5">Now Showing</Index.Typography>
          </Index.Box>
          <Index.Box className="main-showing-slider movie-slider-inner">
            {nowPlayingList?.length ? (
              <PagesIndex.Swiper
                modules={[PagesIndex.Navigation]}
                navigation={true}
                spaceBetween={10}
                slidesPerView={2.5}
                breakpoints={{
                  550: {
                    slidesPerView: 2.8,
                    spaceBetween: 10,
                  },
                  780: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1349: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
              >
                {nowPlayingList?.map((item, key) => (
                  <PagesIndex.SwiperSlide key={key}>
                    <PagesIndex.MovieCard item={item} isNowPlaying={true} />
                  </PagesIndex.SwiperSlide>
                ))}
              </PagesIndex.Swiper>
            ) : (
              <Index.Box className="not-found">No Movies Found</Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="main-membership-plan">
        <Index.Box className="cus-container">
          <Index.Box className="membership-grid-main">
            <Index.Grid container>
              <Index.Grid item md={6} xxs={12}>
                <img
                  src={PagesIndex.Jpg.membership}
                  className="member-img"
                  alt="Membership"
                />
              </Index.Grid>
              <Index.Grid item md={6} xxs={12} className="membership-right-box">
                <Index.Box className="member-right-details">
                  <Index.Typography variant="h4">
                    Introducing a loyalty program with special rewards and
                    exclusive benefits!
                  </Index.Typography>
                  <Index.Typography variant="p" component="p">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Architecto, mollitia pariatur? Sed veritatis quod iure
                    reiciendis tempora cupiditate ab ipsa deleniti consectetur,
                    ex molestias doloremque ea quidem voluptatem eius rerum?
                    Numquam esse temporibus beatae minus commodi veritatis
                    aliquam asperiores repudiandae.
                  </Index.Typography>
                  <Index.Typography variant="p" component="p">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Architecto, mollitia pariatur? Sed veritatis quod iure.
                  </Index.Typography>
                  <Index.Box className="membership-btns main">
                    <Index.Link to="/membership" className="btn btn-primary">
                      Purchase Plan
                    </Index.Link>
                    <Index.Link>Already Member ?</Index.Link>
                  </Index.Box>
                  <Index.Box className="membership-btns store-btns">
                    <Index.Link to="#" className="play-store-btns">
                      <img
                        src={PagesIndex.Svg.PlayStore}
                        width="150"
                        height="48"
                        alt="Play Store"
                      />
                    </Index.Link>
                    <Index.Link to="#" className="apple-store-btns">
                      <img
                        src={PagesIndex.Svg.AppleStore}
                        width="150"
                        height="48"
                        alt="Apple Store"
                      />
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {aboveUpcomingBannerList.length ? (
        <Index.Box className="cus-container">
          <Index.Box className="account-ad-slider">
            <PagesIndex.Swiper
              modules={[PagesIndex.Autoplay]}
              loop={true}
              speed={1500}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
            >
              {aboveUpcomingBannerList?.map((data, index) => {
                return (
                  <PagesIndex.SwiperSlide key={index}>
                    <Index.Box
                      className={`ad-slider-img ${
                        data?.bannerLink ? "link" : ""
                      }`}
                      onClick={() => {
                        if (data?.bannerLink) {
                          window.open(
                            data?.bannerLink,
                            "_blank" // <- This is what makes it open in a new window.
                          );
                        }
                      }}
                    >
                      <img
                        src={`${PagesIndex.IMAGES_API_ENDPOINT}${data?.banner}`}
                        alt="ad-slider slider"
                      />
                    </Index.Box>
                  </PagesIndex.SwiperSlide>
                );
              })}
            </PagesIndex.Swiper>
          </Index.Box>
        </Index.Box>
      ) : (
        ""
      )}
      <Index.Box className="main-upcoming-part movie-slider">
        <Index.Box className="cus-container">
          <Index.Box className="showing-txt movie-slider-heading">
            <Index.Typography variant="h5">Upcoming</Index.Typography>
          </Index.Box>
          <Index.Box className="main-showing-slider movie-slider-inner">
            {upcomingMoviesList?.length ? (
              <PagesIndex.Swiper
                modules={[PagesIndex.Navigation]}
                navigation={true}
                spaceBetween={10}
                slidesPerView={2.5}
                breakpoints={{
                  550: {
                    slidesPerView: 2.8,
                    spaceBetween: 10,
                  },
                  780: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1349: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
              >
                {upcomingMoviesList?.map((item, key) => {
                  return (
                    <PagesIndex.SwiperSlide key={key}>
                      <PagesIndex.MovieCard item={item} isUpcoming={true} />
                    </PagesIndex.SwiperSlide>
                  );
                })}
              </PagesIndex.Swiper>
            ) : (
              <Index.Box className="not-found">No Movies Found</Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="main-showing-part movie-slider">
        <Index.Box className="cus-container">
          <Index.Box className="showing-txt movie-slider-heading">
            <Index.Typography variant="h5">Bollywood Movies</Index.Typography>
          </Index.Box>
          <Index.Box className="main-showing-slider movie-slider-inner">
            {nowPlayingList?.filter(
              (data) => data?.movieCategory === "Bollywood"
            )?.length ? (
              <PagesIndex.Swiper
                modules={[PagesIndex.Navigation]}
                navigation={true}
                spaceBetween={10}
                slidesPerView={2.5}
                breakpoints={{
                  550: {
                    slidesPerView: 2.8,
                    spaceBetween: 10,
                  },
                  780: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1349: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
              >
                {nowPlayingList
                  ?.filter((data) => data?.movieCategory === "Bollywood")
                  ?.map((item, key) => (
                    <PagesIndex.SwiperSlide key={key}>
                      <PagesIndex.MovieCard item={item} isNowPlaying={true} />
                    </PagesIndex.SwiperSlide>
                  ))}
              </PagesIndex.Swiper>
            ) : (
              <Index.Box className="not-found">No Movies Found</Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="main-showing-part movie-slider">
        <Index.Box className="cus-container">
          <Index.Box className="showing-txt movie-slider-heading">
            <Index.Typography variant="h5">Hollywood Movies</Index.Typography>
          </Index.Box>
          <Index.Box className="main-showing-slider movie-slider-inner">
            {nowPlayingList?.filter(
              (data) => data?.movieCategory === "Hollywood"
            )?.length ? (
              <PagesIndex.Swiper
                modules={[PagesIndex.Navigation]}
                navigation={true}
                spaceBetween={10}
                slidesPerView={2.5}
                breakpoints={{
                  550: {
                    slidesPerView: 2.8,
                    spaceBetween: 10,
                  },
                  780: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1349: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
              >
                {nowPlayingList
                  ?.filter((data) => data?.movieCategory === "Hollywood")
                  ?.map((item, key) => (
                    <PagesIndex.SwiperSlide key={key}>
                      <PagesIndex.MovieCard item={item} isNowPlaying={true} />
                    </PagesIndex.SwiperSlide>
                  ))}
              </PagesIndex.Swiper>
            ) : (
              <Index.Box className="not-found">No Movies Found</Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="main-showing-part movie-slider">
        <Index.Box className="cus-container">
          <Index.Box className="showing-txt movie-slider-heading">
            <Index.Typography variant="h5">Regional Movies</Index.Typography>
          </Index.Box>
          <Index.Box className="main-showing-slider movie-slider-inner">
            {nowPlayingList?.filter(
              (data) => data?.movieCategory === "Regional"
            )?.length ? (
              <PagesIndex.Swiper
                modules={[PagesIndex.Navigation]}
                navigation={true}
                spaceBetween={10}
                slidesPerView={2.5}
                breakpoints={{
                  550: {
                    slidesPerView: 2.8,
                    spaceBetween: 10,
                  },
                  780: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1349: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
              >
                {nowPlayingList
                  ?.filter((data) => data?.movieCategory === "Regional")
                  ?.map((item, key) => (
                    <PagesIndex.SwiperSlide key={key}>
                      <PagesIndex.MovieCard item={item} isNowPlaying={true} />
                    </PagesIndex.SwiperSlide>
                  ))}
              </PagesIndex.Swiper>
            ) : (
              <Index.Box className="not-found">No Movies Found</Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {belowUpcomingBannerList.length ? (
        <Index.Box className="cus-container">
          <Index.Box className="account-ad-slider">
            <PagesIndex.Swiper
              modules={[PagesIndex.Autoplay]}
              loop={true}
              speed={1500}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
            >
              {belowUpcomingBannerList?.map((data, index) => {
                return (
                  <PagesIndex.SwiperSlide key={index}>
                    <Index.Box
                      className={`ad-slider-img ${
                        data?.bannerLink ? "link" : ""
                      }`}
                      onClick={() => {
                        if (data?.bannerLink) {
                          window.open(
                            data?.bannerLink,
                            "_blank" // <- This is what makes it open in a new window.
                          );
                        }
                      }}
                    >
                      <img
                        src={`${PagesIndex.IMAGES_API_ENDPOINT}${data?.banner}`}
                        alt="ad-slider slider"
                      />
                    </Index.Box>
                  </PagesIndex.SwiperSlide>
                );
              })}
            </PagesIndex.Swiper>
          </Index.Box>
        </Index.Box>
      ) : (
        ""
      )}
      <Index.Box className="main-recent-releases-part movie-slider">
        <Index.Box className="cus-container">
          <Index.Box className="showing-txt movie-slider-heading">
            <Index.Typography variant="h5">Recent Releases</Index.Typography>
          </Index.Box>
          <Index.Box className="main-recent-releases-slider movie-slider-inner">
            {nowPlayingList?.length ? (
              <PagesIndex.Swiper
                modules={[PagesIndex.Navigation]}
                navigation={true}
                spaceBetween={10}
                slidesPerView={2.5}
                breakpoints={{
                  550: {
                    slidesPerView: 2.8,
                    spaceBetween: 10,
                  },
                  780: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1349: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
              >
                {nowPlayingList
                  ?.filter(
                    (data) =>
                      PagesIndex.moment()
                        .utc()
                        .diff(
                          PagesIndex.moment(data?.filmOpeningDate),
                          "weeks"
                        ) < 3
                  )
                  ?.map((item, key) => (
                    <PagesIndex.SwiperSlide key={key}>
                      <PagesIndex.MovieCard item={item} isNowPlaying={true} />
                    </PagesIndex.SwiperSlide>
                  ))}
              </PagesIndex.Swiper>
            ) : (
              <Index.Box className="not-found">No Movies Found</Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.Membership />
    </Index.Box>
  );
}

export default Home;
