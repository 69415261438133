import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function PageNotFound() {
  return (
    <Index.Box className="main-page-not-found">
      <Index.Box className="main-content">
        <Index.Box className="page-not-found-wrapper">
          <Index.Box className="page-not-found-img">
            <img src={PagesIndex.Svg.PageNotFound} alt="Transaction Failed" />
          </Index.Box>
          <Index.Typography
            variant="h1"
            component="h1"
            className="page-not-found-title"
          >
            Page Not Found
          </Index.Typography>
          <Index.Typography className="page-not-found-subtitle">
            You didn't break the internet, but we can't find what you are
            looking for.
          </Index.Typography>
          <Index.Box className="page-not-found-btn-box">
            {/* <Index.Link to="#" className='btn btn-secondary'>
                            View Order Details
                        </Index.Link> */}
            <Index.Link to="/" className="btn btn-primary">
              Back to home
            </Index.Link>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default PageNotFound;
