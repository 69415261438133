export const Api = {
  LOGIN: "user/login",
  VERIFY_OTP: "user/verify-otp",
  GET_REGION: "user/get-region",
  GET_MOVIES_BY_ID: "user/get-movies-by-region",
  GET_CINEMA_BY_ID: "user/get-cinema-by-region",
  APPLY_FRANCHISE: "user/apply-franchise",
  CONTACT_US: "user/contact-us",
  GET_USER: "user/user-info",
  UPDATE_USER: "user/profile-update",
  GET_MOVIEDETAILS: "user/movies-details-by-id",
  GET_MOVIE_SHOW_DETAILS: "movie-detils-with-shows",
  SOCIAL_LOGIN: "user/social-login",
  GET_GENERAL_SETTINGS: "user/site-setting",
  GET_GALLERY_IMAGES_LIST: "user/gallery",
  GET_SEAT_LAYOUT: "seat-layout",
  GET_CMS: "get-cms",
  GET_SHOW_TIMINGS_BY_CINEMA_MOVIE: "show-time-by-cinema-movie",
  GET_PRICE_DETAILS_LIST: "price-details",
  INIT_SEAT_BOOKING: "init-booking",
  ADD_SEATS: "add-seats",
  SET_SEATS: "set-seats",
  CREATE_ORDER: "create-order",
  CANCEL_SEATS: "temp-cancel",
  RAZORPAY_CALLBACK: "redirect-as-per-response",
  BOOKING_DETAILS: "booking-details-by-transid",
  MY_BOOKINGS: "/user/my-booking",
  GET_FOODS_AND_BAVERAGES: "item-details-by-cinema",
  ADD_ITEMS: "add-conssesion",
  GET_SLIDER_IMAGES: "user/sliders",
  GET_BANNER_IMAGES: "user/banners",
  VERIFY_MOBILENUMBER: "user/verify-mobilenumber",
  VERIFY_EMAIL: "user/verify-email",
  RESEND_OTP: "user/resend-otp",
  SUBSCRIBE: "subscribe",
  GET_FAQS: "get-faqs",
  GET_ALL_SEARCH_DATA: "all-search",
  TWENTY_MIN_FRANCHISE_APPLICATION: "user/apply-twenty-min-franchise",
  GET_CALENDER_LIST: "upcoming-movies",
  GET_GEO_LOCATION: "near-by-region",
  GET_MOVIE_SHOW_BY_CINEMA: "user/movie-shows-by-cinema",
  ADD_RATE_REVIEW: "user/write-rate-review",
  LIKE_DISLIKE_MOVIE: "user/like-dis-like-movie",
};
