import React from "react";
import Index from "../Index";
import PagesIndex from "../PagesIndex";
import { useEffect } from "react";
import { useState } from "react";

export default function SearchModal({ handleClose }) {
  const searchType = [
    {
      title: "AnyWhere",
      id: 1,
    },
    {
      title: "AnyWeek",
      id: 2,
    },
    {
      title: "AnyMovie",
      id: 3,
    },
    {
      title: "AnyOffer",
      id: 4,
    },
  ];
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const { region } = PagesIndex.useSelector((state) => state.UserReducer);
  const [searchedData, setSearchedData] = useState([]);
  const [type, setType] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    getAllSearchItems();
  }, [searchText, region?._id, type]);
  const getAllSearchItems = async () => {
    setShowLoader(true);
    await PagesIndex.apiGetHandlerWithQuery(
      PagesIndex.Api.GET_ALL_SEARCH_DATA,
      `?text=${searchText}&type=${type}`
    ).then((response) => {
      if (response?.status === 200) {
        setSearchedData(response?.data);
      } else {
        PagesIndex.toast.error(response?.message);
      }
      setShowLoader(false);
    });
  };
  const handleChangeType = (typeNo) => {
    setType(typeNo);
  };
  return (
    <Index.Box className="search-modal-inner">
      <Index.Box className="modal-inner">
        <Index.Box className="search-header-box">
          <Index.Box className="search-btn-box-wrapper cus-scrollbar">
            <Index.Box className="search-btn-box">
              {searchType?.map((data) => {
                return (
                  <PagesIndex.Button
                    secondary
                    className={type === data?.id ? "active" : ""}
                    onClick={() => handleChangeType(data?.id)}
                  >
                    {data?.title}
                  </PagesIndex.Button>
                );
              })}
            </Index.Box>
          </Index.Box>
          <Index.Box className="search-input-box">
            <Index.Input
              autoFocus
              className="search-input"
              placeholder="Search by Cinema , Movie..."
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <Index.Box className="svg-box">
              {showLoader && <PagesIndex.Loader secondary />}
              <Index.SearchIcon />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="search-result-box cus-scrollbar">
          {(type === 1 || type === 2 || type === 3) && (
            <Index.Box className="search-result-box-inner movie">
              <Index.Typography className="search-result-title">
                Movies
                {/* <Index.Typography variant="span" component="span" className="view-all">
                View All
              </Index.Typography> */}
              </Index.Typography>
              <Index.Box className="search-result-body movie">
                {searchedData?.getMovies?.length ? (
                  searchedData?.getMovies?.map((data) => {
                    return (
                      <Index.Box
                        className="search-result-card movie"
                        onClick={() => {
                          handleClose();
                          navigate({
                            pathname: `/movie-details`,
                            search: PagesIndex?.createSearchParams({
                              mId: data?._id,
                              rId: region?._id,
                            }).toString(),
                          });
                        }}
                      >
                        <Index.Box className="search-result-movie-img">
                          <img
                            src={
                              data?.poster
                                ? `${PagesIndex.IMAGES_API_ENDPOINT}/${data?.poster}`
                                : PagesIndex.Png.NoImage
                            }
                            alt="movie"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = PagesIndex.Png.NoImage;
                            }}
                          />
                        </Index.Box>
                        <Index.Typography className="search-result-movie-name">
                          {data?.name}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })
                ) : (
                  <Index.Box className="search-result-body offer">
                    <Index.Box className="no-search-result">
                      No Results Found.
                    </Index.Box>
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>
          )}
          {(type === 1 || type === 2) && (
            <Index.Box className="search-result-box-inner cinema">
              <Index.Typography className="search-result-title">
                Cinemas
                {/* <Index.Typography variant="span" component="span" className="view-all">
                View All
              </Index.Typography> */}
              </Index.Typography>
              <Index.Box className="search-result-body cinema">
                {searchedData?.getCinemas?.length ? (
                  searchedData?.getCinemas?.map((data) => {
                    return (
                      <Index.Box
                        className="search-result-card cinema"
                        onClick={() => {
                          handleClose();
                          navigate({
                            pathname: `/cinema-detail`,
                            search: PagesIndex?.createSearchParams({
                              cId: data?.cinemaObjectId?._id,
                            }).toString(),
                          });
                        }}
                      >
                        <img
                          src={
                            data?.cinemaObjectId?.poster
                              ? `${PagesIndex.IMAGES_API_ENDPOINT}/${data?.cinemaObjectId?.poster}`
                              : PagesIndex.Png.NoImage
                          }
                          alt="cinema"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = PagesIndex.Png.NoImage;
                          }}
                        />
                        <Index.Typography className="search-result-cinema-name">
                          {data?.cinemaObjectId?.displayName}
                          <Index.Typography className="search-result-cinema-address">
                            {data?.cinemaObjectId?.address}
                          </Index.Typography>
                        </Index.Typography>
                      </Index.Box>
                    );
                  })
                ) : (
                  <Index.Box className="search-result-body offer">
                    <Index.Box className="no-search-result">
                      No Results Found.
                    </Index.Box>
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>
          )}
          {type === 4 && (
            <Index.Box className="search-result-box-inner offer">
              <Index.Typography className="search-result-title">
                Offers
                {/* <Index.Typography variant="span" component="span" className="view-all">
                View All
              </Index.Typography> */}
              </Index.Typography>
              <Index.Box className="search-result-body offer">
                <Index.Box className="no-search-result">
                  No Results Found.
                </Index.Box>
              </Index.Box>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
