import React from 'react'
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';

function OrderTab() {

    return (
        <Index.Box className="account-tab-order">
            <Index.Box className="account-tab-heading-box">
                <Index.Typography component="span" className="account-tab-heading">
                    My Orders
                </Index.Typography>
            </Index.Box>
            <Index.Box className="no-found-svg-box">
                <Index.RedeemIcon />
                You don't seem to have any recent Orders.
            </Index.Box>
        </Index.Box>
    )
}

export default OrderTab