import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import DetailTabContent from "./DetailTabContent";
import AboutTabContent from "./AboutTabContent";
import ReviewTabContent from "./ReviewTabContent";

function MovieDetailTab(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Box>{children}</Index.Box>
        </Index.Box>
      )}
    </div>
  );
}

MovieDetailTab.propTypes = {
  children: PagesIndex.PropTypes.node,
  index: PagesIndex.PropTypes.number.isRequired,
  value: PagesIndex.PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MovieDetail() {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const { isLoggedIn, region, userDetails } = PagesIndex.useSelector(
    (state) => state.UserReducer
  );
  const movieId = new URLSearchParams(location.search).get("mId");
  const regionId = new URLSearchParams(location.search).get("rId");
  const [value, setValue] = useState(0);
  const [movieShows, setMovieShows] = useState([]);
  const [filteredMovieShows, setFilteredMovieShows] = useState([]);
  const [modalTrailer, setModalTrailer] = useState(false);
  const [modalReview, setModalReview] = useState(false);
  const [movieDetail, setMovieDetail] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);

  useEffect(() => {
    if (regionId !== region?._id)
      navigate({
        pathname: `/movie-details`,
        search: PagesIndex?.createSearchParams({
          mId: movieId,
          rId: region._id,
        }).toString(),
      });
  }, [region]);
  useEffect(() => {
    getMovieDetail();
  }, [movieId, isLoggedIn]);
  const signInOpen = () => {
    setToggle(true);
  };
  const signInClose = () => setToggle(false);
  function getMovieDetail() {
    dispatch(PagesIndex.showLoader());
    PagesIndex.apiGetHandler(
      `${PagesIndex.Api.GET_MOVIEDETAILS}?movieId=${movieId}&userId=${userDetails?._id}`
    ).then((res) => {
      if (res?.status === 200) {
        setMovieDetail(res?.data);
        setIsLiked(res?.data?.isLiked);
        setLikesCount(
          res?.data?.likes >= res?.data?.totalLikes - res?.data?.likes
            ? res?.data?.likes
            : res?.data?.totalLikes
        );
      }
      dispatch(PagesIndex.hideLoader());
    });
  }
  function getMovieShowDetails(selectedDate) {
    dispatch(PagesIndex.showLoader());
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", `${regionId}|${selectedDate}|${movieId}`);
    PagesIndex.apiPostHandler(PagesIndex.Api.GET_MOVIE_SHOW_DETAILS, urlEncoded)
      .then((res) => {
        setMovieShows(res.data);
        var filteredRes = res.data.map((data) => {
          return {
            ...data,
            timings: data.timings?.filter((item) => {
              var startTime = PagesIndex.moment(item?.startTime).format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              );
              var currentTime = PagesIndex.moment().format(
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              );
              let timeisGt = startTime < currentTime;
              return !timeisGt;
            }),
          };
        });
        setFilteredMovieShows(filteredRes);
        dispatch(PagesIndex.hideLoader());
      })
      .catch(() => {
        dispatch(PagesIndex.hideLoader());
      });
  }
  function handleLikeDislike() {
    setIsLiked((prev) => !prev);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("movieId", movieId);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.LIKE_DISLIKE_MOVIE,
      urlEncoded,
      userDetails?.token
    )
      .then((res) => {
        setIsLiked(res?.data?.isLiked);
        setLikesCount(res?.data?.totalLikes);
      })
      .catch(() => { });
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const timeConverter = (time) => {
    var hours = Math.floor(time / 60);
    var minutes = time % 60;
    return hours + "h " + minutes + "m";
  };
  const releaseDateCheck = () => {
    var startTime = PagesIndex.moment(movieDetail?.filmOpeningDate).format(
      "YYYY-MM-DD"
    );
    var currentTime = PagesIndex.moment().utc().format("YYYY-MM-DD");
    let timeisGt = startTime > currentTime;
    return timeisGt;
  };
  const modalTrailerOpen = () => setModalTrailer(true);
  const modalTrailerClose = () => setModalTrailer(false);

  const modalReviewOpen = () => setModalReview(true);
  const modalReviewClose = () => setModalReview(false);

  return (
    <Index.Box className="main-movie-detail">
      <Index.Box
        className="movie-detail-banner"
        style={{
          backgroundImage: `url(${PagesIndex.IMAGES_API_ENDPOINT}${movieDetail?.poster})`,
        }}
      >
        <Index.Box className="cus-container">
          <Index.Box className="detail-banner-box">
            <Index.Box className="detail-banner-img">
              <img
                src={
                  movieDetail?.poster
                    ? PagesIndex.IMAGES_API_ENDPOINT + movieDetail?.poster
                    : PagesIndex.Png.NoImage
                }
                width="260"
                height="390"
                alt="movie detail"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = PagesIndex.Png.NoImage;
                }}
              />
              {movieDetail?.filmOpeningDate && (
                <Index.Box className="detail-banner-img-tag">
                  {releaseDateCheck() ? "Releasing on" : "Released on"}{" "}
                  {PagesIndex.moment(movieDetail?.filmOpeningDate).format(
                    "DD MMM, YYYY"
                  )}
                </Index.Box>
              )}
            </Index.Box>
            <Index.Box className="detail-banner-content">
              <Index.Typography
                variant="h1"
                component="h1"
                className="detail-banner-title"
              >
                {movieDetail?.name}
              </Index.Typography>
              {!releaseDateCheck() ? (
                <Index.Box className="detail-banner-like">
                  {isLoggedIn && isLiked ? (
                    <Index.ThumbUpAltIcon
                      onClick={handleLikeDislike}
                      className="like-icon"
                    />
                  ) : (
                    <Index.ThumbUpAltOutlinedIcon
                      className="like-icon"
                      onClick={() => {
                        if (isLoggedIn) {
                          handleLikeDislike();
                        } else {
                          signInOpen();
                        }
                      }}
                    />
                  )}
                  <span>
                    {Intl.NumberFormat("en-US", {
                      notation: "compact",
                      maximumFractionDigits: 1,
                    }).format(likesCount)}
                  </span>
                  <Index.StarIcon className="star-icon" />
                  <span>
                    {Intl.NumberFormat("en-US", {
                      notation: "compact",
                      maximumFractionDigits: 1,
                    }).format(
                      movieDetail?.averageRating >= 5
                        ? movieDetail?.averageRating
                        : movieDetail?.rating
                    )}
                    /5
                  </span>
                </Index.Box>
              ) : (
                ""
              )}
              {releaseDateCheck() ? (
                <Index.Box className="detail-banner-interest">
                  <Index.Box className="banner-interest-content">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="banner-interest-title"
                    >
                      Releasing on{" "}
                      {PagesIndex.moment(movieDetail?.filmOpeningDate).format(
                        "DD MMM, YYYY"
                      )}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="banner-interest-subtitle"
                    >
                      Are you interested in watching this movie?
                    </Index.Typography>
                  </Index.Box>
                  <PagesIndex.Button primary className="banner-interest-button">
                    I'm interested
                  </PagesIndex.Button>
                </Index.Box>
              ) : (
                ""
              )}
              {movieDetail &&
                !movieDetail.isAlreadyRated &&
                !releaseDateCheck() ? (
                <Index.Box className="detail-banner-interest">
                  <Index.Box className="banner-interest-content">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="banner-interest-title"
                    >
                      Add Your Rating & Review
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="banner-interest-subtitle"
                    >
                      Your opinion matters
                    </Index.Typography>
                  </Index.Box>
                  <PagesIndex.Button
                    primary
                    onClick={() => {
                      if (isLoggedIn) {
                        modalReviewOpen();
                      } else {
                        signInOpen();
                      }
                    }}
                    className="banner-interest-button"
                  >
                    Rate now
                  </PagesIndex.Button>
                </Index.Box>
              ) : (
                ""
              )}
              <PagesIndex.ReviewModal
                open={modalReview}
                onClose={modalReviewClose}
                movieDetail={movieDetail}
                getMovieDetail={getMovieDetail}
              />
              <Index.Typography
                variant="p"
                component="p"
                className="banner-interest-hours"
              >
                {movieDetail?.duration
                  ? timeConverter(movieDetail?.duration)
                  : "-"}{" "}
                |{" "}
                {movieDetail?.category
                  ? movieDetail?.category?.replace(", ", " | ")
                  : "-"}{" "}
                | {movieDetail?.censorRating ? movieDetail?.censorRating : "-"}
              </Index.Typography>
              <Index.Box className="banner-interest-tags">
                <Index.Typography
                  variant="span"
                  component="span"
                  className="banner-interest-tag"
                >
                  2D
                </Index.Typography>
                {movieDetail?.languages && (
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="banner-interest-tag"
                  >
                    {movieDetail?.languages}
                  </Index.Typography>
                )}
              </Index.Box>
              <Index.Box className="book-now-box">
                <PagesIndex.Button primary onClick={(() => {
                  const bookingElement = document.getElementById("book-now-content");
                  bookingElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
                })}>
                  Book Now
                </PagesIndex.Button>
                {movieDetail?.youtubeVideoUrl && (
                  <PagesIndex.Button
                    primary
                    onClick={modalTrailerOpen}
                    className="play-trailer-btn"
                  >
                    <Index.PlayArrowIcon />
                  </PagesIndex.Button>
                )}
                <PagesIndex.TrailerModal
                  open={modalTrailer}
                  onClose={modalTrailerClose}
                  link={movieDetail?.youtubeVideoUrl}
                />
              </Index.Box>
            </Index.Box>
            <Index.Box className="detail-banner-share">
              <PagesIndex.Button primary>
                <Index.ShareIcon />
              </PagesIndex.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="movie-detail-tab-wrapper" id="book-now-content">
        <Index.Box className="movie-detail-tab-box">
          <Index.Box className="cus-container">
            <Index.Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              aria-label="basic tabs example"
            >
              <Index.Tab
                className="movie-detail-tab"
                label="Movie Details"
                {...a11yProps(0)}
              />
              <Index.Tab
                className="movie-detail-tab"
                label="About Movie"
                {...a11yProps(1)}
              />
              <Index.Tab
                className="movie-detail-tab"
                label="Reviews"
                {...a11yProps(2)}
              />
            </Index.Tabs>
          </Index.Box>
        </Index.Box>
        <MovieDetailTab
          value={value}
          index={0}
          className="movie-detail-tab-content"
        >
          <DetailTabContent
            movieId={movieId}
            regionId={regionId}
            movieShows={movieShows}
            filteredMovieShows={filteredMovieShows}
            setFilteredMovieShows={setFilteredMovieShows}
            languages={movieDetail?.languages}
            getMovieShowDetails={(selectedDate) =>
              getMovieShowDetails(selectedDate)
            }
          />
        </MovieDetailTab>
        <MovieDetailTab
          value={value}
          index={1}
          className="movie-detail-tab-content"
        >
          <AboutTabContent details={movieDetail} movieId={movieId} />
        </MovieDetailTab>
        <MovieDetailTab
          value={value}
          index={2}
          className="movie-detail-tab-content"
        >
          <ReviewTabContent details={movieDetail} movieId={movieId} />
        </MovieDetailTab>
      </Index.Box>
      <Index.Modal
        open={toggle}
        onClose={signInClose}
        aria-labelledby="signin-modal-title"
        aria-describedby="signin-modal-description"
        className="signin-modal"
      >
        <>
          <PagesIndex.Login signInClose={signInClose} />
        </>
      </Index.Modal>
    </Index.Box>
  );
}

export default MovieDetail;
