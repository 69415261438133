import moment from "moment";
import * as Yup from "yup";

const nameRegex = /^[a-zA-Z]+$/;
const fullNameRegex = /^[a-zA-Z]+(([\'\,\.\- ][a-zA-Z ])?[a-zA-Z]*)*$$/;
const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$/;

const phoneRegex = /^([6789]\d{2}\d{4}\d{3})$/; // Change this regex based on requirement

export const loginValidationSchema = Yup.object({
  emailOrPhone: Yup.string("Enter your Email/Phone Number")
    .required("Mobile number or email is required")
    .test("test-name", "Enter Valid mobile number or email", function (value) {
      let isValidEmail = emailRegex.test(value);
      let isValidPhone = phoneRegex.test(value);
      if (!isValidEmail && !isValidPhone) {
        return false;
      }
      return true;
    }),
});

export const otpSchema = Yup.object().shape({
  otp: Yup.string("Please enter your OTP").required("OTP is required"),
});

export const applyFranchiseScheme = Yup.object().shape({
  firstName: Yup.string("Please enter first name")
    .required("First name is required")
    .matches(nameRegex, "Enter valid first name"),
  lastName: Yup.string("Please enter last name")
    .required("Last name is required")
    .matches(nameRegex, "Enter valid last name"),
  email: Yup.string("Please enter email")
    .required("Email is required")
    .matches(emailRegex, "Enter valid email"),
  phoneNumber: Yup.string("Please enter phone number")
    .required("Phone number is required")
    .matches(phoneRegex, "Enter valid phone number"),
  city: Yup.string("Please enter city")
    .required("City is required")
    .matches(fullNameRegex, "Enter valid city"),
  state: Yup.string("Please enter state")
    .required("State is required")
    .matches(fullNameRegex, "Enter valid state"),
  jobTitle: Yup.string("Please enter job title")
    .required("Job title is required")
    .matches(fullNameRegex, "Enter valid job title"),
  company: Yup.string("Please enter company").required("Company is required"),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    "You need to accept the terms and conditions"
  ),
});
export const applyTwentyMinFranchiseScheme = Yup.object().shape({
  name: Yup.string("Please enter name")
    .required("Name is required")
    .matches(fullNameRegex, "Enter valid name"),
  email: Yup.string("Please enter email")
    .required("Email is required")
    .matches(emailRegex, "Enter valid email"),
  phoneNumber: Yup.string("Please enter phone number")
    .required("Phone number is required")
    .matches(phoneRegex, "Enter valid phone number"),
  city: Yup.string("Please enter city")
    .required("City is required")
    .matches(fullNameRegex, "Enter valid city"),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    "You need to accept the terms and conditions"
  ),
});

export const contactUsSchema = Yup.object().shape({
  firstName: Yup.string("Please enter first name")
    .required("First name is required")
    .matches(nameRegex, "Enter valid first name"),
  lastName: Yup.string("Please enter last name")
    .required("Last name is required")
    .matches(nameRegex, "Enter valid last name"),
  email: Yup.string("Please enter email")
    .required("Email is required")
    .matches(emailRegex, "Enter valid email"),
  phoneNumber: Yup.string("Please enter phone number")
    .required("Phone number is required")
    .matches(phoneRegex, "Enter valid phone number"),
  message: Yup.string("Please enter message").required("Message is required"),
});

export const userDetailsSchema = Yup.object().shape({
  profile: Yup.mixed()
    .test("FILE_TYPE", "Unsupported file format", (value) => {
      if (value) {
        return (
          value.type === "image/jpeg" ||
          value.type === "image/jpg" ||
          value.type === "image/png"
        );
      } else {
        return true;
      }
    })
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 2000000)
    ),
  firstName: Yup.string("Please enter first name")
    .required("First name is required")
    .matches(nameRegex, "Please enter valid first name"),
  lastName: Yup.string("Please enter last name")
    .required("Last name is required")
    .matches(nameRegex, "Please enter valid last name"),
  birthDate: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = moment(originalValue);
      return result;
    })
    .typeError("please enter valid date")
    .max(moment().format(), "Date can not be future date"),
  gender: Yup.string("Please select gender"),
  maritalStatus: Yup.string("Please select marital status"),
  address: Yup.string("Please enter address"),
  city: Yup.string("Please enter city").matches(
    fullNameRegex,
    "Enter valid city"
  ),
});
export const accountDetailsEmailSchema = Yup.object().shape({
  email: Yup.string("Please enter email")
    .required("Email is required")
    .matches(emailRegex, "Enter valid email"),
});
export const accountDetailsPhoneSchema = Yup.object().shape({
  phoneNumber: Yup.string("Please enter phone number")
    .required("Phone number is required")
    .matches(phoneRegex, "Enter valid phone number"),
});
export const rateAndReview = Yup.object().shape({
  movieRating: Yup.string("Please give movie rating").required(
    "Movie rating is required"
  ),
  connplexRating: Yup.string("Please give connplex rating").required(
    "Connplex rating is required"
  ),
  movieReview: Yup.string("Please give movie review").required(
    "Movie review is required"
  ),
});
