import { Link } from "react-router-dom";
import CountUp from "react-countup";
import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PersonIcon from "@mui/icons-material/Person";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import LogoutIcon from "@mui/icons-material/Logout";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CreateIcon from "@mui/icons-material/Create";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import RedeemIcon from "@mui/icons-material/Redeem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import Favorite from "@mui/icons-material/Favorite";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import StarIcon from "@mui/icons-material/Star";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShareIcon from '@mui/icons-material/Share';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Button,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  FormHelperText,
  TextField,
  TextareaAutosize,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Modal,
  Stack,
  ImageList,
  ImageListItem,
  ListItemText,
  OutlinedInput,
  Pagination,
  InputAdornment,
  Divider,
} from "@mui/material";

const Index = {
  Link,
  CountUp,
  HomeIcon,
  CallIcon,
  MailIcon,
  ThumbUpAltIcon,
  PlayArrowIcon,
  KeyboardArrowDownIcon,
  SearchIcon,
  FmdGoodIcon,
  WatchLaterIcon,
  PersonIcon,
  ConfirmationNumberIcon,
  LoyaltyIcon,
  LogoutIcon,
  CloudDownloadIcon,
  CreateIcon,
  ExpandMoreIcon,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  Typography,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  TextareaAutosize,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Modal,
  OpenInNewIcon,
  CloseIcon,
  Stack,
  CheckIcon,
  ErrorIcon,
  RedeemIcon,
  NotificationsIcon,
  PrivacyTipIcon,
  ImageList,
  ImageListItem,
  Favorite,
  ListItemText,
  OutlinedInput,
  DateRangeIcon,
  DoneAllIcon,
  Pagination,
  InputAdornment,
  Divider,
  ArrowRightAltIcon,
  FilterAltIcon,
  ThumbDownIcon,
  StarIcon,
  ThumbUpAltOutlinedIcon,
  EventSeatIcon,
  InfoOutlinedIcon,
  ShareIcon,
};

export default Index;
