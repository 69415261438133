import React from "react";
import Index from "../Index";
import PagesIndex from "../PagesIndex";

export default function FeedbackModal({ open, onClose }) {

  return (
    <Index.Modal open={open} onClose={onClose} className="common-modal">
      <Index.Box className="common-modal-inner">
        <Index.Box className="modal-inner cus-scrollbar">
          <Index.Typography
            variant="p"
            component="p"
            className="common-modal-title"
          >
            Feedback
          </Index.Typography>
          <Index.Grid
            container
            spacing={{ sm: 2, xxs: 1 }}
            className="form-group"
          >
            <Index.Grid item sm={6} xxs={12}>
              <Index.FormHelperText className="form-label">
                First name
              </Index.FormHelperText>
              <Index.TextField
                fullWidth
                id="firstNameFeedback"
                className="form-control"
                placeholder="First name"
                name="firstName"
                inputProps={{ maxLength: 50 }}
              />
            </Index.Grid>
            <Index.Grid item sm={6} xxs={12}>
              <Index.FormHelperText className="form-label">
                Last name
              </Index.FormHelperText>
              <Index.TextField
                fullWidth
                id="lastNameFeedback"
                className="form-control"
                placeholder="First name"
                name="firstName"
                inputProps={{ maxLength: 50 }}
              />
            </Index.Grid>
            <Index.Grid item sm={6} xxs={12}>
              <Index.FormHelperText className="form-label">
                Email
              </Index.FormHelperText>
              <Index.TextField
                fullWidth
                id="emailFeedback"
                className="form-control"
                placeholder="Email"
                type="email"
                name="email"
                inputProps={{ maxLength: 320 }}
              />
            </Index.Grid>
            <Index.Grid item sm={6} xxs={12}>
              <Index.FormHelperText className="form-label">
                Phone number
              </Index.FormHelperText>
              <Index.TextField
                fullWidth
                id="phoneFeedback"
                className="form-control"
                placeholder="Phone number"
                name="phoneNumber"
                inputProps={{ maxLength: 10 }}
              />
            </Index.Grid>
            <Index.Grid item sm={12} xxs={12}>
              <Index.FormHelperText className="form-label">
                Message
              </Index.FormHelperText>
              <Index.TextareaAutosize
                minRows={5}
                placeholder="Message"
                name="message"
                maxLength={250}
              />
            </Index.Grid>
            <Index.Grid item sm={12} xxs={12}>
              <Index.Box className="common-modal-action">
                <PagesIndex.Button primary type="submit" className="common-modal-btn">
                  Submit
                </PagesIndex.Button>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  );
}
