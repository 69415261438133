import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import PropTypes from "prop-types";
import AccountTab from "./AccountTab";
import BookingTab from "./BookingTab";
import MembershipTab from "./MembershipTab";
import OrderTab from "./OrderTab";
import NotificationTab from "./NotificationTab";
import SupportTab from "./SupportTab";
import "swiper/css/effect-fade";
import WishlistTab from "./WishlistTab";

function AccountTabItem(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

AccountTabItem.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Account() {
  const { userDetails, userToken } = PagesIndex.useSelector(
    (state) => state.UserReducer
  );
  const dispatch = PagesIndex.useDispatch();
  const [bookingList, setBookingList] = useState([]);
  const [value, setValue] = useState(0);
  const [bannerList, setBannerList] = useState([]);
  useEffect(() => {
    getBookingList();
    getBannerImages();
  }, []);
  const getBookingList = () => {
    dispatch(PagesIndex.showLoader());
    PagesIndex.apiGetHandler(PagesIndex.Api.MY_BOOKINGS, "", userToken).then(
      (res) => {
        if (res?.status === 200) {
          setBookingList(res.data);
        }
        dispatch(PagesIndex.hideLoader());
      }
    );
  };
  const getBannerImages = () => {
    dispatch(PagesIndex.showLoader());
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_BANNER_IMAGES).then((res) => {
      if (res?.status === 200) {
        const bannerList = res.data.filter((data) =>
          data?.bannerShowSection.includes("My Account Section")
        );
        setBannerList(bannerList);
      }
      dispatch(PagesIndex.hideLoader());
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Index.Box className="main-account">
      <Index.Box className="account-header">
        <Index.Box className="cus-container">
          <Index.Box className="account-ad-slider">
            <PagesIndex.Swiper
              modules={[PagesIndex.Autoplay]}
              loop={true}
              speed={1500}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
            >
              {bannerList?.map((data, index) => {
                return (
                  <PagesIndex.SwiperSlide key={index}>
                    <Index.Box
                      className={`ad-slider-img ${data?.bannerLink ? "link" : ""}`}
                      onClick={() => {
                        if (data?.bannerLink) {
                          window.open(
                            data?.bannerLink,
                            "_blank" // <- This is what makes it open in a new window.
                          );
                        }
                      }}
                    >
                      <img
                        src={`${PagesIndex.IMAGES_API_ENDPOINT}${data?.banner}`}
                        alt="ad-slider slider"
                      />
                    </Index.Box>
                  </PagesIndex.SwiperSlide>
                );
              })}
            </PagesIndex.Swiper>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="account-body-box">
        <Index.Box className="cus-container">
          <Index.Box className="account-body">
            <Index.Box className="account-tab-box">
              <Index.Box className="profile-img-box">
                <Index.Box className="profile-img">
                  <img
                    src={
                      userDetails?.profile
                        ? `${PagesIndex.IMAGES_API_ENDPOINT}${userDetails?.profile}`
                        : PagesIndex.Png.Avatar
                    }
                    width="80"
                    height="80"
                    alt="profile"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = PagesIndex.Png.Avatar;
                    }}
                  />
                </Index.Box>
                <Index.Typography className="profile-img-title">
                  Hi,{" "}
                  {userDetails?.firstName ? userDetails?.firstName : "Guest"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="account-tab-box-inner">
                <Index.Typography className="profile-img-title">
                  Hi,{" "}
                  {userDetails?.firstName ? userDetails?.firstName : "Guest"}
                </Index.Typography>
                <Index.Tabs
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  scrollButtons
                  variant="scrollable"
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  <Index.Tab
                    className="account-tab"
                    label={
                      <>
                        <Index.PersonIcon />
                        My Account
                      </>
                    }
                    {...a11yProps(0)}
                  />
                  <Index.Tab
                    className="account-tab"
                    label={
                      <>
                        <Index.ConfirmationNumberIcon />
                        My Booking
                      </>
                    }
                    {...a11yProps(1)}
                  />
                  <Index.Tab
                    className="account-tab"
                    label={
                      <>
                        <Index.RedeemIcon />
                        My Orders
                      </>
                    }
                    {...a11yProps(2)}
                  />
                  <Index.Tab
                    className="account-tab"
                    label={
                      <>
                        <Index.Favorite />
                        My Wishlist
                      </>
                    }
                    {...a11yProps(3)}
                  />
                  <Index.Tab
                    className="account-tab"
                    label={
                      <>
                        <Index.LoyaltyIcon />
                        Membership
                      </>
                    }
                    {...a11yProps(4)}
                  />
                  <Index.Tab
                    className="account-tab"
                    label={
                      <>
                        <Index.NotificationsIcon />
                        Notification
                      </>
                    }
                    {...a11yProps(5)}
                  />
                  {/* <Index.Tab
                    className="account-tab"
                    label={
                      <>
                        <Index.PrivacyTipIcon />
                        Help & Support
                      </>
                    }
                    {...a11yProps(6)}
                  /> */}
                </Index.Tabs>
              </Index.Box>
            </Index.Box>
            <Index.Box className="account-tab-wrapper">
              <AccountTabItem
                value={value}
                index={0}
                className="account-tab-content"
              >
                <AccountTab />
              </AccountTabItem>
              <AccountTabItem
                value={value}
                index={1}
                className="account-tab-content"
              >
                <BookingTab bookingList={bookingList} />
              </AccountTabItem>
              <AccountTabItem
                value={value}
                index={2}
                className="account-tab-content"
              >
                <OrderTab />
              </AccountTabItem>
              <AccountTabItem
                value={value}
                index={3}
                className="account-tab-content"
              >
                <WishlistTab />
              </AccountTabItem>
              <AccountTabItem
                value={value}
                index={4}
                className="account-tab-content"
              >
                <MembershipTab />
              </AccountTabItem>
              <AccountTabItem
                value={value}
                index={5}
                className="account-tab-content"
              >
                <NotificationTab />
              </AccountTabItem>
              <AccountTabItem
                value={value}
                index={6}
                className="account-tab-content"
              >
                <SupportTab />
              </AccountTabItem>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default Account;
