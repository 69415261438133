import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function ConfirmationScreen() {
  const dispatch = PagesIndex.useDispatch();
  const location = PagesIndex.useLocation();
  const transId = new URLSearchParams(location.search).get("transId");
  const [bookingDetails, setBookingDetails] = useState({});
  useEffect(() => {
    getBookingDetails();
  }, []);
  const getBookingDetails = () => {
    dispatch(PagesIndex.showLoader());
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("initTransId", transId);
    PagesIndex.apiPostHandler(PagesIndex.Api.BOOKING_DETAILS, urlEncoded)
      .then((res) => {
        setBookingDetails(res.data);
        dispatch(PagesIndex.hideLoader());
      })
      .catch(() => {
        dispatch(PagesIndex.hideLoader());
      });
  };
  return (
    <Index.Box
      className={`main-confirmation-screen ${
        location.pathname === "/my-booked-ticket"
          ? "my-booking-ticket-screen"
          : ""
      }`}
    >
      <Index.Box className="cus-container">
        {location.pathname === "/confirmation-screen" && (
          <Index.Box className="confirmation-screen-header">
            <Index.Box className="confirm-icon">
              <Index.DoneAllIcon />
            </Index.Box>
            <Index.Typography className="confirm-text">
              Payment of{" "}
              <Index.Typography
                component="span"
                className="confirm-text-amount"
              >
                ₹
                {parseFloat(
                  bookingDetails?.paymentResponse?.amount / 100
                ).toFixed(2)}
              </Index.Typography>{" "}
              Successful
            </Index.Typography>
          </Index.Box>
        )}
        <Index.Box className="confirmation-screen-body">
          <Index.Box className="booking-id-box">
            <Index.Typography className="booking-confirm-text">
              {location.pathname === "/my-booked-ticket"
                ? "YOUR BOOKING DETAILS!"
                : "YOUR BOOKING IS CONFIRMED!"}
            </Index.Typography>
            <Index.Typography className="booking-confirm-id">
              Booking ID {bookingDetails?.addSeatData?.strBookId}
            </Index.Typography>
          </Index.Box>
          <Index.Box className="booking-detail-box">
            <Index.Box className="booking-detail-left">
              <img
                className="movie-img"
                src={`${PagesIndex.IMAGES_API_ENDPOINT}/${bookingDetails?.movieId?.poster}`}
                width="585"
                height="800"
                alt="movie"
              />
              <Index.Box className="booking-detail-summary">
                <Index.Box className="booking-summary-top">
                  <Index.Box className="booking-summary-left">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="booking-detail-title"
                    >
                      {bookingDetails?.movieId?.name}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="booking-detail-content"
                    >
                      {bookingDetails?.movieId?.languages} (
                      {bookingDetails?.movieId?.censorRating}) -{" "}
                      {bookingDetails?.movieId?.category}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="booking-detail-content"
                    >
                      {PagesIndex.moment(
                        bookingDetails?.showId?.sessionRealShow
                      ).format("MMM DD, YYYY")}{" "}
                      |{" "}
                      {PagesIndex.moment(
                        bookingDetails?.showId?.sessionRealShow
                      ).format("hh:mm A")}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="booking-detail-content"
                    >
                      <a href={bookingDetails?.cinemaId?.googleUrl}>
                        {bookingDetails?.cinemaId?.address}
                      </a>
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="booking-summary-right">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="booking-detail-value"
                    >
                      {bookingDetails?.showId?.screenName}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="booking-detail-value"
                    >
                      {bookingDetails?.setSeatData?.strSeatInfo}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="booking-detail-value"
                    >
                      ({" "}
                      {
                        bookingDetails?.setSeatData?.strSeatInfo
                          .split(" - ")[1]
                          .split(",").length
                      }{" "}
                      Tickets )
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="booking-summary-bottom">
                  <Index.Accordion>
                    <Index.AccordionSummary
                      expandIcon={<Index.ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-detail-label"
                      >
                        Total Amount
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-detail-value"
                      >
                        ₹
                        {parseFloat(
                          bookingDetails?.paymentResponse?.amount / 100
                        ).toFixed(2)}
                      </Index.Typography>
                    </Index.AccordionSummary>
                    <Index.AccordionDetails>
                      <Index.Box>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="booking-detail-label"
                        >
                          Ticket Amount :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="booking-detail-value"
                        >
                          ₹
                          {parseFloat(
                            bookingDetails?.commitBookingData?.curTicketsTotal
                          ).toFixed(2)}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="booking-detail-label"
                        >
                          Convenience fee <small>(Including GST)</small> :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="booking-detail-value"
                        >
                          ₹
                          {parseFloat(
                            bookingDetails?.cinemaId?.convenienceFees *
                              bookingDetails?.setSeatData?.strSeatInfo
                                .split(" - ")[1]
                                .split(",").length
                          ).toFixed(2)}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="booking-detail-label"
                        >
                          GST :
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="booking-detail-value"
                        >
                          ₹0.00
                        </Index.Typography>
                      </Index.Box>
                      {bookingDetails?.fAndBDetails?.length ? (
                        <Index.Box>
                          Food And Beverages Amount :
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="booking-detail-value"
                          >
                            ₹
                            {parseFloat(
                              bookingDetails?.foodAndBvgResponse?.curFoodTotal +
                                (bookingDetails?.foodAndBvgResponse
                                  ?.curFoodTotal *
                                  5) /
                                  100
                            ).toFixed(2)}
                          </Index.Typography>
                        </Index.Box>
                      ) : (
                        ""
                      )}
                      {bookingDetails?.fAndBDetails?.length
                        ? bookingDetails?.fAndBDetails?.map((data, key) => {
                            return (
                              <Index.Box className="food-list-item" key={key}>
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="booking-detail-label"
                                >
                                  {key + 1}. {data?.name.toLowerCase()}{" "}
                                  <small>
                                    (x
                                    {data?.quantity})
                                  </small>
                                </Index.Typography>
                                <Index.Typography
                                  variant="p"
                                  component="p"
                                  className="booking-detail-label"
                                >
                                  ₹{parseFloat(data?.price).toFixed(2)}
                                </Index.Typography>
                              </Index.Box>
                            );
                          })
                        : ""}
                    </Index.AccordionDetails>
                  </Index.Accordion>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="booking-detail-right">
              <PagesIndex.QRCode
                value={bookingDetails?.addSeatData?.strBookId}
                renderAs="canvas"
                bgColor={"#23211e"}
                fgColor={"#bcbcbc"}
                size={100}
              />
              <Index.Typography className="booking-code-text">
                Booking ID
              </Index.Typography>
              <Index.Typography className="booking-code-text">
                {bookingDetails?.addSeatData?.strBookId}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="confirmation-screen-footer">
          <Index.Typography className="confirmation-screen-footer-text">
            You can access your ticket from your Profile. We will send you an
            e-Mail/SMS Confirmation within 15 Minutes.
          </Index.Typography>
          <Index.Box className="confirmation-screen-footer-btn">
            <Index.Link to="/" className="btn btn-primary">
              Back To Home
            </Index.Link>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default ConfirmationScreen;
