import membership from "../assets/images/jpg/membership.jpg";
import aboutBanner from "../assets/images/jpg/about-banner.jpg";
import partnerImg1 from "../assets/images/jpg/parner-img-1.jpg";
import partnerImg2 from "../assets/images/jpg/parner-img-2.jpg";
import partnerImg3 from "../assets/images/jpg/parner-img-3.jpg";
import partnerImg4 from "../assets/images/jpg/parner-img-4.jpg";
import partnerImg5 from "../assets/images/jpg/parner-img-5.jpg";
import partnerImg6 from "../assets/images/jpg/parner-img-6.jpg";
import partnerImg7 from "../assets/images/jpg/parner-img-7.jpg";
import partnerImg8 from "../assets/images/jpg/parner-img-8.jpg";
import partnerImg9 from "../assets/images/jpg/parner-img-9.jpg";
import partnerImg10 from "../assets/images/jpg/parner-img-10.jpg";
import partnerImg11 from "../assets/images/jpg/parner-img-11.jpg";
import contactBanner from "../assets/images/jpg/contact-banner.jpg";
import snackImg1 from "../assets/images/jpg/snack-img-1.jpg";
import lobbyArea from "../assets/images/jpg/lobby-area.jpg";
import lobbyArea2 from "../assets/images/jpg/lobby-area-2.jpg";
import upcomingBanner from "../assets/images/jpg/upcoming-banner.jpg";

const Jpg = {
    membership,
    aboutBanner,
    partnerImg1,
    partnerImg2,
    partnerImg3,
    partnerImg4,
    partnerImg5,
    partnerImg6,
    partnerImg7,
    partnerImg8,
    partnerImg9,
    partnerImg10,
    partnerImg11,
    contactBanner,
    snackImg1,
    lobbyArea,
    lobbyArea2,
    upcomingBanner,
};

export default Jpg;
