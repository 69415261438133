import axios from "axios";

// const API_ENDPOINT = "https://192.168.1.7:3035/api";
// const API_ENDPOINT = "https://vistaconnplex.appworkdemo.com/api";
// export const IMAGES_API_ENDPOINT =
//   "https://vistaconnplex.appworkdemo.com/uploads/";
const API_ENDPOINT = "https://backend.theconnplex.com/api";
export const IMAGES_API_ENDPOINT = "https://backend.theconnplex.com/uploads/";

const DataService = axios.create({
  baseURL: API_ENDPOINT,
});

const apiGetHandler = (url, payload, auth) => {
  if (auth) {
    DataService.defaults.headers.common.auth = auth;
  } else {
    delete DataService.defaults.headers.common.auth;
  }
  if (payload) {
    return DataService.get(url + "/" + payload)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  } else {
    return DataService.get(url)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  }
};
const apiGetHandlerWithQuery = (url, payload, auth) => {
  if (auth) {
    DataService.defaults.headers.common.auth = auth;
  } else {
    delete DataService.defaults.headers.common.auth;
  }
  return DataService.get(url + payload)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
const apiPostHandler = (url, payload, auth) => {
  if (auth) {
    DataService.defaults.headers.common.auth = auth;
  } else {
    delete DataService.defaults.headers.common.auth;
  }
  return DataService.post(url, payload)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
const apiPostHandlerXml = (auth, url, payload) => {
  DataService.defaults.headers.common["Content-Type"] = "application/xml";
  DataService.defaults.headers.common.Accept = "application/xml";
  if (auth) {
    DataService.defaults.headers.common.auth = auth;
  } else {
    delete DataService.defaults.headers.common.auth;
  }
  return DataService.post(url, payload)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};

export {
  apiGetHandler,
  apiPostHandler,
  apiPostHandlerXml,
  apiGetHandlerWithQuery,
};
