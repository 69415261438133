import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";

function Header() {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const regionId = new URLSearchParams(location.search).get("rId");
  const { isLoggedIn, region, userToken } = PagesIndex.useSelector(
    (state) => state.UserReducer
  );
  const [selectedRegion, setSelectedRegion] = useState({});
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [selectCity, setSelectCity] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [regionList, setRegionList] = useState([]);
  const openMenu = Boolean(anchorEl);
  const handleChangedropdown = (data) => {
    setSelectedRegion(data);
    dispatch(PagesIndex.changeRegion(data));
    changeCityClose();
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const signInOpen = () => {
    setToggle(true);
    handleMenuClose();
  };
  const signInClose = () => {
    setToggle(false);
  };
  const handleMenuClick = (event) => {
    if (isLoggedIn) {
      setAnchorEl(event.currentTarget);
    } else {
      signInOpen();
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getGeoLocation, errorCallback);
    } else {
      PagesIndex.toast.error("Geo tagging not supported");
    }
  };
  function errorCallback(error) {
    PagesIndex.toast.error(error?.message);
  }
  const getRegionList = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_REGION).then((res) => {
      if (res?.status === 200) {
        setRegionList(res?.data);
        if (regionId && !region) {
          const regionData = res.data?.find((data) => data?._id === regionId);
          setSelectedRegion(regionData);
          dispatch(PagesIndex.changeRegion(regionData));
        }
        if (!regionId && !region) {
          setSelectedRegion(res?.data[0]);
          dispatch(PagesIndex.changeRegion(res?.data[0]));
          getLocation();
        } else {
          setSelectedRegion(region);
        }
      }
      dispatch(PagesIndex.hideLoader());
    });
  };
  const getGeoLocation = async (position) => {
    await PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_GEO_LOCATION,
      `${position.coords.latitude}/${position.coords.longitude}`
    ).then((res) => {
      if (res?.status === 200) {
        if (!regionId) {
          setSelectedRegion(res?.data);
          dispatch(PagesIndex.changeRegion(res?.data));
        }
      }
    });
  };
  const changeCityOpen = () => setSelectCity(true);
  const changeCityClose = () => setSelectCity(false);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY >= 70);
    });
    getRegionList();
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      getUser();
    }
  }, []);

  const [openFranchise, setOpenFranchise] = useState(false);
  const handleOpenFranchise = () => {
    setOpenFranchise(true);
  };
  const handleOpenFranchiseMobile = () => {
    setOpenFranchise(true);
    mobileNav();
  };
  const handleCloseFranchise = () => setOpenFranchise(false);

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const mobileNav = () => {
    if (!mobileNavOpen) {
      setMobileNavOpen(true);
      document.body.classList.add("mobile-nav-open");
    } else {
      setMobileNavOpen(false);
      document.body.classList.remove("mobile-nav-open");
    }
  };
  function getUser() {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_USER, "", userToken).then(
      (res) => {
        if (
          res?.message === "jwt expired" ||
          res?.message === "Token not authorized"
        ) {
          dispatch(PagesIndex.userLogOut());
          navigate("/");
          PagesIndex.toast.error("Session has expired");
        }
      }
    );
  }
  return (
    <Index.Box
      className={
        scroll
          ? mobileNavOpen
            ? "main-header mui-fixed is-sticky mobile-nav-open"
            : "main-header mui-fixed is-sticky"
          : "main-header mui-fixed"
      }
    >
      <Index.Box className="cus-container">
        <Index.Box className="top-head">
          <Index.Box className="top-logo">
            <Index.Link to="/">
              <img
                src={PagesIndex.Svg.ConnplexLogo}
                width="300"
                height="69"
                className="head-logo"
                alt="Company Logo"
              />
            </Index.Link>
          </Index.Box>
          <Index.Box className="top-link">
            <Index.Box className="any-options">
              <Index.Box onClick={handleOpen} className="header-search-icon">
                <Index.SearchIcon />
              </Index.Box>
              <Index.Box className="any-options-text" onClick={handleOpen}>
                AnyWhere
              </Index.Box>
              <Index.Box className="any-options-text" onClick={handleOpen}>
                AnyWeek
              </Index.Box>
              <Index.Box className="any-options-text" onClick={handleOpen}>
                AnyMovie
              </Index.Box>
              <Index.Box className="any-options-text" onClick={handleOpen}>
                AnyOffer
              </Index.Box>
              <Index.Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="search-modal-title"
                aria-describedby="search-modal-description"
                className="search-modal"
              >
                <>
                  <PagesIndex.SearchModal handleClose={handleClose} />
                </>
              </Index.Modal>
            </Index.Box>
          </Index.Box>
          <Index.Box className="header-action">
            <Index.Box onClick={handleOpen} className="header-search-icon">
              <Index.SearchIcon />
            </Index.Box>
            {!(
              location.pathname === "/seat-management" ||
              location.pathname === "/add-snacks"
            ) && (
                <Index.Box className="main-city-select" onClick={changeCityOpen}>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="select-icon map"
                  >
                    <Index.LocationOnIcon />
                  </Index.Typography>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="city-name"
                  >
                    {selectedRegion?.region}
                  </Index.Typography>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="select-icon arrow"
                  >
                    <Index.ArrowDropDownIcon />
                  </Index.Typography>
                </Index.Box>
              )}
            <Index.Modal
              open={selectCity}
              onClose={changeCityClose}
              aria-labelledby="select-city-modal-title"
              aria-describedby="select-city-modal-description"
              className="select-city-modal"
            >
              <Index.Box className="select-city-modal-inner">
                <Index.Box className="modal-inner cus-scrollbar">
                  <Index.Box className="popular-city-box">
                    <Index.Box className="popular-city-header">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="popular-city-title"
                      >
                        Select City
                      </Index.Typography>
                      <Index.Box className="city-input-box">
                        <Index.Input
                          autoFocus
                          className="city-input"
                          placeholder="Search City"
                        />
                        <Index.Box className="svg-box">
                          <PagesIndex.Loader secondary />
                          <Index.SearchIcon />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="popular-city-wrapper-main cus-scrollbar">
                      <Index.Box className="popular-city-wrapper">
                        {regionList.map((item, key) => (
                          <Index.Box
                            key={key}
                            className={`popular-city-item ${selectedRegion?._id === item._id ? "active" : ""
                              }`}
                            onClick={() => handleChangedropdown(item)}
                          >
                            <img
                              src={`${PagesIndex.IMAGES_API_ENDPOINT}${item.image}`}
                              alt=""
                            />
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="popular-city-name"
                            >
                              {item.region}
                            </Index.Typography>
                          </Index.Box>
                        ))}
                      </Index.Box>
                      {/* <Index.Box className="no-search-result">
                        No Results Found.
                      </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>
            <Index.Box className="user-profile-head" onClick={handleMenuClick}>
              <img
                src={PagesIndex.Png.profile_img}
                width="34"
                height="34"
                alt="profile"
              />
            </Index.Box>
            <Index.Menu
              className="user-profile-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
            >
              <Index.MenuItem
                onClick={() => {
                  navigate("/account");
                  handleMenuClose();
                }}
                className="user-profile-menuitem"
              >
                <Index.PersonIcon /> My Account
              </Index.MenuItem>
              <Index.MenuItem
                onClick={handleMenuClose}
                className="user-profile-menuitem"
              >
                <Index.Favorite /> My Wishlist
              </Index.MenuItem>
              <Index.MenuItem
                onClick={handleMenuClose}
                className="user-profile-menuitem"
              >
                <Index.NotificationsIcon /> Notifications
              </Index.MenuItem>
              {/* <Index.MenuItem
                onClick={handleMenuClose}
                className="user-profile-menuitem"
              >
                <Index.PrivacyTipIcon /> Help & Support
              </Index.MenuItem> */}
              <Index.MenuItem
                onClick={() => {
                  handleMenuClose();
                  dispatch(PagesIndex.userLogOut());
                  PagesIndex.toast.success("Logged out");
                  navigate("/");
                }}
                className="user-profile-menuitem"
              >
                <Index.ExitToAppIcon /> Sign Out
              </Index.MenuItem>
            </Index.Menu>
            <Index.Modal
              open={toggle}
              onClose={signInClose}
              aria-labelledby="signin-modal-title"
              aria-describedby="signin-modal-description"
              className="signin-modal"
            >
              <>
                <PagesIndex.Login signInClose={signInClose} toggle={toggle} />
              </>
            </Index.Modal>
            <Index.Box
              className={
                scroll === true ? "mobile-navigation show" : "mobile-navigation"
              }
            >
              <Index.Box
                className={
                  mobileNavOpen ? "mobile-nav-btn open" : "mobile-nav-btn"
                }
                onClick={mobileNav}
              >
                <Index.MenuIcon />
              </Index.Box>
              <Index.Box
                className={
                  mobileNavOpen ? "mobile-nav-box open" : "mobile-nav-box"
                }
              >
                <Index.Box className="mobile-nav-header">
                  <Index.Link to="/" className="mobile-nav-logo">
                    <img
                      src={PagesIndex.Svg.ConnplexLogo}
                      width="300"
                      height="69"
                      className="head-logo"
                      alt="Company Logo"
                    />
                  </Index.Link>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="mobile-nav-close"
                    onClick={mobileNav}
                  >
                    X
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="mobile-nav-body">
                  <ul className="cus-scrollbar">
                    <li>
                      <Index.Link
                        onClick={mobileNav}
                        to="/"
                        className="mobile-nav-link"
                      >
                        Home
                      </Index.Link>
                    </li>
                    <li>
                      <Index.Typography
                        variant="span"
                        component="span"
                        onClick={handleOpenFranchiseMobile}
                        className="mobile-nav-link"
                      >
                        Apply For Franchise
                      </Index.Typography>
                    </li>
                    <li>
                      <Index.Link
                        onClick={mobileNav}
                        to="/cinema"
                        className="mobile-nav-link"
                      >
                        Cinema
                      </Index.Link>
                    </li>
                    <li>
                      <Index.Link
                        onClick={mobileNav}
                        to="/gallery"
                        className="mobile-nav-link"
                      >
                        Gallery
                      </Index.Link>
                    </li>
                    <li>
                      <Index.Link
                        onClick={mobileNav}
                        to="/membership"
                        className="mobile-nav-link"
                      >
                        Membership
                      </Index.Link>
                    </li>
                    <li>
                      <Index.Link
                        onClick={mobileNav}
                        to="/calender"
                        className="mobile-nav-link"
                      >
                        Calender
                      </Index.Link>
                    </li>
                    <li>
                      <Index.Link
                        onClick={mobileNav}
                        to="/ecommerce"
                        className="mobile-nav-link"
                      >
                        Ecommerce
                      </Index.Link>
                    </li>
                    <li>
                      <Index.Link
                        onClick={mobileNav}
                        to="/about"
                        className="mobile-nav-link"
                      >
                        About Connplex
                      </Index.Link>
                    </li>
                  </ul>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {scroll === false && (
        <Index.Box className="btm-head">
          <Index.Box className="head-btm-links">
            <ul>
              <li>
                <Index.Typography
                  variant="span"
                  component="span"
                  onClick={handleOpenFranchise}
                >
                  Apply For Franchise
                </Index.Typography>
              </li>
              <li>
                <Index.Link to="/cinema">Cinema</Index.Link>
              </li>
              <li>
                <Index.Link to="/gallery">Gallery</Index.Link>
              </li>
              <li>
                <Index.Link to="/membership">Membership</Index.Link>
              </li>
              <li>
                <Index.Link to="/calender">Calender</Index.Link>
              </li>
              <li>
                <Index.Link to="/ecommerce">Ecommerce</Index.Link>
              </li>
              <li>
                <Index.Link to="/about">About Connplex</Index.Link>
              </li>
            </ul>
          </Index.Box>
        </Index.Box>
      )}
      <PagesIndex.FranchiseModal
        open={openFranchise}
        onClose={handleCloseFranchise}
      />
    </Index.Box>
  );
}

export default Header;
