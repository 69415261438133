import type from "./type";

const getUserData = (payload) => ({
  type: type.getUserDetails,
  payload,
});
const getUserToken = (payload) => ({
  type: type.getUserToken,
  payload,
});
const userLogOut = () => ({
  type: type.userLogOut,
});
const changeRegion = (payload) => ({
  type: type.changeRegion,
  payload,
});
const showLoader = (payload) => ({
  type: type.showLoader,
  payload,
});
const getOtpTimer = (payload) => ({
  type: type.getOtpTimer,
  payload,
});
const hideLoader = (payload) => ({
  type: type.hideLoader,
  payload,
});
const upcomingMoviesList = (payload) => ({
  type: type.upcomingMoviesList,
  payload,
});

export {
  getUserData,
  changeRegion,
  userLogOut,
  upcomingMoviesList,
  showLoader,
  hideLoader,
  getUserToken,
  getOtpTimer,
};
