import React, { useState } from "react";
import Index from "../Index";
import PagesIndex from "../PagesIndex";

function MovieCard({ isNowPlaying, item }) {
  const { region } = PagesIndex.useSelector((state) => state.UserReducer);
  const navigate = PagesIndex.useNavigate();
  const [modalTrailer, setModalTrailer] = useState(false);
  const modalTrailerOpen = () => setModalTrailer(true);
  const modalTrailerClose = () => setModalTrailer(false);

  return (
    <Index.Box className="main-card">
      <Index.Box
        className="card-img"
        onClick={() => {
          navigate({
            pathname: `/movie-details`,
            search: PagesIndex?.createSearchParams({
              mId: item?._id,
              rId: region?._id,
            }).toString(),
          });
        }}
      >
        <img
          src={
            item?.poster
              ? `${PagesIndex.IMAGES_API_ENDPOINT}${item?.poster}`
              : PagesIndex.Png.NoImage
          }
          alt="movie"
          width="585"
          height="800"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = PagesIndex.Png.NoImage;
          }}
        />
        {isNowPlaying && (
          <Index.Box className="card-rating-like">
            <Index.Box className="card-rating-box">
              <Index.Typography component="span">
                <Index.StarIcon />
              </Index.Typography>
              <Index.Typography component="span">
                {Intl.NumberFormat("en-US", {
                  notation: "compact",
                  maximumFractionDigits: 1,
                }).format(
                  item?.averageRating >= 5 ? item?.averageRating : item?.rating
                )}
                /5
              </Index.Typography>
            </Index.Box>
            <Index.Box className="card-like-box">
              <Index.Typography component="span">
                <Index.ThumbUpAltIcon color="success" />
              </Index.Typography>
              <Index.Typography component="span">
                {Intl.NumberFormat("en-US", {
                  notation: "compact",
                  maximumFractionDigits: 1,
                }).format(
                  item?.totalLikes < item?.likes
                    ? item?.likes + item?.totalLikes
                    : item?.totalLikes
                )}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        )}
      </Index.Box>
      <Index.Box className="card-content">
        <Index.Box className="card-content-title">
          <Index.Typography variant="h6">{item?.name}</Index.Typography>
        </Index.Box>
        <Index.Box className="card-content-action">{item?.category}</Index.Box>
        <Index.Box className="card-content-btn">
          <PagesIndex.Button secondary onClick={modalTrailerOpen}>
            Watch Trailer
          </PagesIndex.Button>
          <PagesIndex.TrailerModal
            open={modalTrailer}
            onClose={modalTrailerClose}
            link={item?.youtubeVideoUrl}
          />
          <PagesIndex.Button
            primary
            onClick={() => {
              navigate({
                pathname: `/movie-details`,
                search: PagesIndex?.createSearchParams({
                  mId: item?._id,
                  rId: region?._id,
                }).toString(),
              });
            }}
          >
            Book Ticket
          </PagesIndex.Button>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default MovieCard;
