import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";

function Footer() {

  const dispatch = PagesIndex.useDispatch();
  const [settingsState, setSettingsState] = useState({});
  useEffect(() => {
    getCMSData();
    getGeneralSettings();
  }, []);
  const [cmsText, setCmsText] = useState("");

  const getCMSData = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CMS).then((res) => {
      if (res?.status === 200) {
        setCmsText(res?.data?.aboutUs);
      } else {
        PagesIndex.toast.error(res?.message);
      }
    });
  };
  const getGeneralSettings = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_GENERAL_SETTINGS).then(
      (res) => {
        if (res?.status === 200) {
          setSettingsState(res?.data);
        } else {
          PagesIndex.toast.error(res?.message);
        }
        dispatch(PagesIndex.hideLoader());
      }
    );
  };

  const [openFranchise, setOpenFranchise] = useState(false);
  const handleOpenFranchise = () => setOpenFranchise(true);
  const handleCloseFranchise = () => setOpenFranchise(false);

  const [openFeedback, setOpenFeedback] = useState(false);
  const handleOpenFeedback = () => setOpenFeedback(true);
  const handleCloseFeedback = () => setOpenFeedback(false);

  const footerMenu = [
    {
      title: "GENERAL",
      children: [
        {
          link: "Cinema",
          url: "/cinema",
        },
        {
          link: "Gallery",
          url: "/gallery",
        },
        {
          link: "Membership",
          url: "/membership",
        },
        {
          link: "Calender",
          url: "/calender",
        },
        {
          link: "Ecommerce",
          url: "/ecommerce",
        },
        {
          link: "Legal Notice",
          url: "/legal-notice",
        },
        {
          link: "Privacy Policy",
          url: "/privacy-policy",
        },
        {
          link: "Terms & Conditions",
          url: "/terms-conditions",
        },
      ],
    },
    {
      title: "REACH",
      children: [
        {
          link: "Apply For Franchise",
          modal: handleOpenFranchise,
        },
        {
          link: "20 Minutes Franchise",
          url: "/franchise",
        },
        {
          link: "About Connplex",
          url: "/about",
        },
        {
          link: "Contact Us",
          url: "/contact",
        },
        {
          link: "FAQ",
          url: "/faq",
        },
        {
          link: "Feedback",
          modal: handleOpenFeedback,
        },
        {
          link: "Advertise With Us",
          url: "/advertise",
        },
        {
          link: "Career",
          url: "/career",
        },
      ],
    },
    {
      title: "PARTNERS",
      children: [
        {
          link: "Connplex Canada",
        },
        {
          link: "Connplex Home",
        },
        {
          link: "Connplex Skyinn theatre",
        },
        {
          link: "Connverse",
        },
        {
          link: "Connflix",
        },
        {
          link: "House of Cinema",
        },
      ],
    },
  ];

  return (
    <Index.Box className="main-footer">
      <Index.Box className="top-footer">
        <Index.Box className="cus-container">
          <Index.Box className="footer-menu-wrap">
            <Index.Box className="footer-logo-col">
              <Index.Link to="/" className="footer-logo">
                <img
                  src={PagesIndex.Svg.ConnplexLogo}
                  width="300"
                  height="69"
                  alt="Company Logo"
                />
              </Index.Link>
              <Index.Box className="footer-contact">
                <Index.Typography
                  variant="span"
                  component="span"
                  className="footer-contact-icon"
                >
                  <Index.HomeIcon />
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="footer-contact-content"
                >
                  {settingsState?.address1}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="footer-contact">
                <Index.Typography
                  variant="span"
                  component="span"
                  className="footer-contact-icon"
                >
                  <Index.CallIcon />
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="footer-contact-content"
                >
                  <Index.Link
                    to={`tel:+91${settingsState?.contactNumber1}`}
                    className="footer-contact-link"
                  >
                    +91 {settingsState?.contactNumber1}
                  </Index.Link>{" "}
                  /{" "}
                  <Index.Link
                    to={`tel:+91${settingsState?.contactNumber2}`}
                    className="footer-contact-link"
                  >
                    +91 {settingsState?.contactNumber2}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
              <Index.Box className="footer-contact">
                <Index.Typography
                  variant="span"
                  component="span"
                  className="footer-contact-icon"
                >
                  <Index.MailIcon />
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="footer-contact-content"
                >
                  <Index.Link
                    to={`mailto:${settingsState?.email}`}
                    className="footer-contact-link"
                  >
                    {settingsState?.email}
                  </Index.Link>
                </Index.Typography>
              </Index.Box>
              <Index.Box className="footer-social">
                <Index.Link
                  to={`${settingsState?.facebookUrl}`}
                  target="_blank"
                  className="footer-social-link btn btn-primary"
                >
                  <Index.FacebookIcon />
                  <Index.Typography variant="span" component="span" className="text-hide">
                    Facebook
                  </Index.Typography>
                </Index.Link>
                <Index.Link
                  to={`${settingsState?.twitterUrl}`}
                  target="_blank"
                  className="footer-social-link btn btn-primary"
                >
                  <Index.TwitterIcon />
                  <Index.Typography variant="span" component="span" className="text-hide">
                    Twitter
                  </Index.Typography>
                </Index.Link>
                <Index.Link
                  to={`${settingsState?.instagramUrl}`}
                  target="_blank"
                  className="footer-social-link btn btn-primary"
                >
                  <Index.InstagramIcon />
                  <Index.Typography variant="span" component="span" className="text-hide">
                    Instagram
                  </Index.Typography>
                </Index.Link>
                <Index.Link
                  to={`${settingsState?.youtubeUrl}`}
                  target="_blank"
                  className="footer-social-link btn btn-primary"
                >
                  <Index.YouTubeIcon />
                  <Index.Typography variant="span" component="span" className="text-hide">
                    Youtube
                  </Index.Typography>
                </Index.Link>
              </Index.Box>
            </Index.Box>
            {footerMenu.map((item, key) => (
              <Index.Box key={key} className="footer-menu-col">
                <Index.Box className="footer-menu-title">
                  {item.title}
                </Index.Box>
                <ul
                  // className={`${item.children.length > 7 ? "footer-grid" : "footer-menu"
                  //   }`}
                  className="footer-menu"
                >
                  {item.children.map((res, key) => (
                    <li key={key}>
                      {res.url ?
                        <Index.Link className="footer-nav-link" to={res.url}>{res.link}</Index.Link>
                        :
                        res.modal ?
                          <Index.Typography
                            variant="span"
                            component="span"
                            onClick={res.modal}
                            className="footer-nav-link"
                          >
                            {res.link}
                          </Index.Typography>
                          :
                          <Index.Typography
                            variant="span"
                            component="span"
                            className="footer-nav"
                          >
                            {res.link}
                          </Index.Typography>
                      }
                    </li>
                  ))}
                </ul>
              </Index.Box>
            ))}
          </Index.Box>
          <Index.Box className="footer-desc-wrap" dangerouslySetInnerHTML={{ __html: cmsText }} />
        </Index.Box>
      </Index.Box>
      <Index.Box className="bottom-footer">
        <Index.Box className="cus-container">
          <Index.Box className="bottom-footer-box">
            <Index.Typography
              variant="p"
              component="p"
              className="copyright-text"
            >
              Copyright © {new Date().getFullYear()}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.FranchiseModal open={openFranchise} onClose={handleCloseFranchise} />
      <PagesIndex.FeedbackModal open={openFeedback} onClose={handleCloseFeedback} />
    </Index.Box>
  );
}

export default Footer;
