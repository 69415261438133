import React, { useEffect } from "react";
import Index from "../Index";
import PagesIndex from "../PagesIndex";
import { useRef } from "react";

export default function Membership() {
  const initialValues = {
    email: "",
  };
  const formikRefAccount = useRef();

  const handleSubmit = (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("email", values?.email);
    PagesIndex.apiPostHandler(PagesIndex.Api.SUBSCRIBE, urlEncoded).then(
      (res) => {
        if (res?.status === 201) {
          PagesIndex.toast.success(res?.message);
        } else {
          PagesIndex.toast.error(res?.message);
        }
      }
    );
  };
  return (
    <Index.Box className="main-trial-membership">
      <Index.Box className="cus-container">
        <Index.Grid
          container
          columnSpacing={2}
          rowSpacing={3}
          className="main-trial-container"
        >
          <Index.Grid item md={6} xxs={12}>
            <Index.Box
              className="newsletter-content"
            >
              <Index.Typography variant="h4">
                TRIAL START FIRST 30 DAYS.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                Enter your email to create or restart your membership.
              </Index.Typography>
            </Index.Box>
          </Index.Grid>
          <Index.Grid item md={6} xxs={12}>
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.accountDetailsEmailSchema}
              innerRef={formikRefAccount}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                setFieldValue,
              }) => (
                <Index.Stack
                  className="form-control"
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box
                    className="trial-mail"
                  >
                    <Index.Input
                      name="email"
                      placeholder="Enter Your Email"
                      value={values?.email}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldTouched("email");
                      }}
                      error={errors.email && touched.email ? true : false}
                    />
                    <PagesIndex.Button type="submit">
                      Get Started
                    </PagesIndex.Button>
                  </Index.Box>
                  <Index.FormHelperText error>
                    {errors.email && touched.email ? errors.email : null}
                  </Index.FormHelperText>
                </Index.Stack>
              )}
            </PagesIndex.Formik>
          </Index.Grid>
        </Index.Grid>
      </Index.Box>
    </Index.Box>
  );
}
