import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function BookingTab({ bookingList }) {
  const navigate = PagesIndex.useNavigate();
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <Index.Box className="account-tab-booking-main">
      <Index.Box className="account-tab-heading-box">
        <Index.Typography component="span" className="account-tab-heading">
          My Booking
        </Index.Typography>
      </Index.Box>
      {bookingList.length ? (
        <>
          <Index.Box className="account-tab-booking">
            {bookingList
              ?.slice((page - 1) * 4, (page - 1) * 4 + 4)
              ?.map((item, key) => (
                <Index.Box
                  key={key}
                  className="your-booking-card"
                  onClick={() => {
                    navigate({
                      pathname: `/my-booked-ticket`,
                      search: PagesIndex?.createSearchParams({
                        transId: item?.initTransId,
                      }).toString(),
                    });
                  }}
                >
                  <img
                    src={`${PagesIndex.IMAGES_API_ENDPOINT}/${item?.movieId?.poster}`}
                    width="585"
                    height="800"
                    alt="movie"
                  />
                  <Index.Box className="booking-card-summary">
                    <Index.Box className="booking-card-header">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-title"
                      >
                        {item?.movieId?.name}
                      </Index.Typography>
                      <Index.Typography
                        variant="span"
                        component="span"
                        className="booking-card-download"
                      >
                        <Index.CloudDownloadIcon />
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="booking-card-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-label"
                      >
                        Genre :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-value"
                      >
                        {item?.movieId?.category}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="booking-card-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-label"
                      >
                        Date / Time :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-value"
                      >
                        {PagesIndex.moment(item?.showId?.sessionRealShow)
                          .format("MMM DD, YYYY")}{" "}
                        |{" "}
                        {PagesIndex.moment(item?.showId?.sessionRealShow)
                          .format("hh:mm A")}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="booking-card-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-label"
                      >
                        Screen :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-value"
                      >
                        {item?.showId?.screenName}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="booking-card-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-label"
                      >
                        Seat :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-value"
                      >
                        {item?.setSeatData?.strSeatInfo}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="booking-card-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-label"
                      >
                        Amount :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-value"
                      >
                        ₹
                        {parseFloat(
                          item?.paymentResponse?.amount / 100
                        ).toFixed(2)}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="booking-card-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-label"
                      >
                        Payment Mode :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="booking-card-value"
                      >
                        {item?.paymentResponse?.method === "upi"
                          ? item?.paymentResponse?.method.toUpperCase()
                          : item?.paymentResponse?.method
                              .charAt(0)
                              .toUpperCase() +
                            item?.paymentResponse?.method
                              .slice(1)
                              .toLowerCase()}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              ))}
          </Index.Box>
          <Index.Pagination
            count={Math.floor(bookingList?.length / 4) + 1}
            page={page}
            onChange={handleChange}
          />
        </>
      ) : (
        <Index.Box className="no-found-svg-box">
          <Index.ConfirmationNumberIcon />
          You don't seem to have any recent Booking.
        </Index.Box>
      )}
    </Index.Box>
  );
}

export default BookingTab;
