import {
  Box,
  Typography,
  Grid,
  OutlinedInput,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Modal,
  Input,
  Button,
  FormHelperText,
  TextField,
  Stack,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
  Rating,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import StarIcon from "@mui/icons-material/Star";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
const Index = {
  Box,
  Typography,
  Grid,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  Modal,
  Input,
  Button,
  FormHelperText,
  TextField,
  Link,
  SearchIcon,
  LanguageIcon,
  LocationOnIcon,
  GoogleIcon,
  AppleIcon,
  FacebookIcon,
  TwitterIcon,
  StarIcon,
  ThumbUpAltIcon,
  ArrowRightAltIcon,
  Menu,
  ShoppingCartCheckoutIcon,
  FavoriteBorder,
  Favorite,
  Checkbox,
  Stack,
  ArrowDropDownIcon,
  TextareaAutosize,
  FormControlLabel,
  CheckIcon,
  PersonIcon,
  NotificationsIcon,
  PrivacyTipIcon,
  ExitToAppIcon,
  CallIcon,
  MailIcon,
  HomeIcon,
  InstagramIcon,
  YouTubeIcon,
  MenuIcon,
  Rating,
  SentimentVeryDissatisfiedIcon,
  SentimentDissatisfiedIcon,
  SentimentSatisfiedIcon,
  SentimentSatisfiedAltIcon,
  SentimentVerySatisfiedIcon,
  ThumbDownIcon,
  ThumbUpOffAltOutlinedIcon,
};

export default Index;
