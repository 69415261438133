import ConnplexLogoIcon from "../assets/images/png/connplex-logo-icon.png";
import profile_img from "../assets/images/png/profile_img.png";
import FeatureImg1 from "../assets/images/png/feature-img-1.png";
import FeatureImg2 from "../assets/images/png/feature-img-2.png";
import FeatureImg3 from "../assets/images/png/feature-img-3.png";
import ProductImg1 from "../assets/images/png/product-img-1.png";
import TransactionFailedImg from "../assets/images/png/transaction-failed-img.png";
import NoImage from "../assets/images/png/no-image-placeholder.png";
import NoImageCinema from "../assets/images/png/no-image-placeholder-cinema.png";
import Avatar from "../assets/images/png/avatar.png";
import Popcorn from "../assets/images/png/popcorn.png";
import Theatre from "../assets/images/png/theatre.png";
import Gallery from "../assets/images/png/gallery.png";
import PositiveReview from "../assets/images/png/positive-review.png";

const Png = {
  ConnplexLogoIcon,
  profile_img,
  FeatureImg1,
  FeatureImg2,
  FeatureImg3,
  ProductImg1,
  TransactionFailedImg,
  NoImage,
  Avatar,
  Popcorn,
  Theatre,
  Gallery,
  NoImageCinema,
  PositiveReview,
};

export default Png;
