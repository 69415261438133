import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function DetailTabContent({
  props,
  movieId,
  regionId,
  languages,
  movieShows,
  filteredMovieShows,
  setFilteredMovieShows,
  getMovieShowDetails,
}) {
  const showPriceItem = [
    {
      startPrice: "0",
      endPrice: "100",
      checked: false,
    },
    {
      startPrice: "100",
      endPrice: "250",
      checked: false,
    },
    {
      startPrice: "250",
      endPrice: "500",
      checked: false,
    },
    {
      startPrice: "500",
      endPrice: "Max",
      checked: false,
    },
  ];
  const showTimeItem = [
    {
      dayTime: "Morning",
      startTime: "12:00",
      endTime: "11:59",
      period: "AM",
      icon: PagesIndex.Svg.MorningIcon,
      checked: false,
    },
    {
      dayTime: "Afternoon",
      startTime: "12:00",
      endTime: "03:59",
      period: "PM",
      icon: PagesIndex.Svg.AfternoonIcon,
      checked: false,
    },
    {
      dayTime: "Evening",
      startTime: "04:00",
      endTime: "06:59",
      period: "PM",
      icon: PagesIndex.Svg.EveningIcon,
      checked: false,
    },
    {
      dayTime: "Night",
      startTime: "07:00",
      endTime: "11:59",
      period: "PM",
      icon: PagesIndex.Svg.NightIcon,
      checked: false,
    },
  ];
  const navigate = PagesIndex.useNavigate();
  const [showDays, setShowDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    PagesIndex.moment().format("YYYY-MM-DD")
  );
  const [timings, setTimings] = useState([]);
  const [filterShows, setFilterShows] = useState([]);
  const [movieShowTimings, setMovieShowTimings] = useState(showTimeItem);
  const [prices, setPrices] = useState([]);
  const [movieShowPrices, setMovieShowPrices] = useState(showPriceItem);
  useEffect(() => {
    getWeekDays();
  }, []);
  useEffect(() => {
    getMovieShowDetails(selectedDate);
  }, [selectedDate, regionId, movieId]);
  useEffect(() => {
    if (timings?.length && prices?.length) {
      priceFilterFunction(timeFilterFunction());
    } else {
      if (prices.length) {
        priceFilterFunction();
      } else {
        if (timings.length) {
          timeFilterFunction();
        } else {
          setFilterShows(filteredMovieShows);
        }
      }
    }
  }, [timings, prices, filteredMovieShows]);
  const getWeekDays = () => {
    let days = [];
    let daysRequired = 6;
    for (let i = 0; i <= daysRequired; i++) {
      const a = PagesIndex.moment();
      const b = PagesIndex.moment().add(i, "days");
      let objData = {
        day:
          //   b.diff(a, "days") === 0
          //     ? "Today"
          //     : b.diff(a, "days") === 1
          //     ? "Tomorrow"
          //     : b.format("dddd"),
          b.format("ddd"),
        date: b.format("DD"),
        month: b.format("MMM"),
        paramDate: b.format("YYYY-MM-DD"),
        selected: b.diff(a, "days") === 0 ? true : false,
      };
      days.push(objData);
    }
    setShowDays(days);
  };
  const dateFilter = (index, checked, startTime, endTime) => {
    setMovieShowTimings((e) => {
      const arr = [...e];
      const selected = arr.map((data, key) => {
        if (index === key) {
          return {
            ...data,
            checked,
          };
        } else {
          return {
            ...data,
          };
        }
      });
      return selected;
    });
    if (checked) {
      setTimings((prev) => [
        ...prev,
        {
          startTime,
          endTime,
        },
      ]);
    } else {
      setTimings((prev) => prev.filter((data) => data?.endTime !== endTime));
    }
  };
  const priceFilter = (index, checked, startPrice, endPrice) => {
    setMovieShowPrices((e) => {
      const arr = [...e];
      const selected = arr.map((data, key) => {
        if (index === key) {
          return {
            ...data,
            checked,
          };
        } else {
          return {
            ...data,
          };
        }
      });
      return selected;
    });
    if (checked) {
      setPrices((prev) => [
        ...prev,
        {
          startPrice,
          endPrice,
        },
      ]);
    } else {
      setPrices((prev) => prev.filter((data) => data?.endPrice !== endPrice));
    }
  };
  const timeFilterFunction = () => {
    let data = filteredMovieShows.map((e) => {
      return {
        ...e,
        timings: e.timings?.filter((el) => {
          const validTime = timings.some((ele) => {
            const startTime = PagesIndex.moment(el.startTime).format("HH:mm");
            return (
              PagesIndex.moment(startTime, "HH:mm").format("X") <=
                +PagesIndex.moment(ele.endTime, "HH:mm").format("X") &&
              PagesIndex.moment(startTime, "HH:mm").format("X") >=
                +PagesIndex.moment(ele.startTime, "HH:mm").format("X")
            );
          });
          if (validTime && timings.length) {
            return {
              ...el,
            };
          }
        }),
      };
    });
    setFilterShows(data);
    return data;
  };
  const priceFilterFunction = (movieData) => {
    let movie = movieData || filteredMovieShows;
    let data = movie.map((e) => {
      return {
        ...e,
        timings: e.timings?.filter((el) => {
          var validPrice = false;
          el.areas.map((ele) => {
            if (
              prices.some((elem) => {
                if (elem.endPrice === "Max") {
                  return ele.price >= elem.startPrice;
                }
                if (elem.endPrice !== "Max") {
                  return (
                    ele.price < elem.endPrice && ele.price >= elem.startPrice
                  );
                }
              })
            ) {
              validPrice = true;
            }
          });
          if (validPrice && prices?.length) {
            return {
              ...el,
            };
          }
        }),
      };
    });
    setFilterShows(data);
    return data;
  };
  const dropDownHandle = (e, className) => {
    e.stopPropagation();
    e.preventDefault();
    const timeFilter = document.getElementById(className);
    timeFilter.childNodes[1].classList.toggle("show-dropdown");
    timeFilter.childNodes[0].classList.toggle("show-dropdown");
  };

  return (
    <Index.Box className="cus-container">
      <Index.Box className="detail-tab-content">
        <Index.Box className="detail-tab-header">
          <Index.Box className="detail-tab-date">
            <PagesIndex.Swiper
              modules={[PagesIndex.Navigation]}
              slidesPerView={"auto"}
              spaceBetween={10}
              breakpoints={{
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 12,
                },
              }}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              {...props}
            >
              {showDays.map((item, key) => (
                <PagesIndex.SwiperSlide
                  key={key}
                  className={`date-item ${item.selected && "slide-active"}`}
                  onClick={() => {
                    setSelectedDate(item?.paramDate);
                    setShowDays((e) => {
                      let arr = [...e];
                      let unSelected = arr.map((e) => ({
                        ...e,
                        selected: false,
                      }));
                      unSelected[key].selected = true;
                      return unSelected;
                    });
                  }}
                >
                  <Index.Typography variant="p" component="p" className="day">
                    {item.day}
                  </Index.Typography>
                  <Index.Typography variant="p" component="p" className="date">
                    {item.date}
                  </Index.Typography>
                  <Index.Typography variant="p" component="p" className="month">
                    {item.month}
                  </Index.Typography>
                </PagesIndex.SwiperSlide>
              ))}
            </PagesIndex.Swiper>
            <Index.Box className="swiper-button-prev" />
            <Index.Box className="swiper-button-next" />
          </Index.Box>
          <Index.Box className="detail-tab-filter">
            <div className="filter-dropdown-main language-category-dropdown">
              <div className="filter-drop-btn">
                <p className="filter-btn-title">Hindi 2D</p>
                <img
                  src={PagesIndex.Svg.ArrowDownIcon}
                  className="filter-down-arrow"
                />
              </div>
              <ul className="filter-ul">
                <li className="filter-li active">
                  <p className="filter-data-text">Hindi 2D</p>
                </li>
                <li className="filter-li">
                  <p className="filter-data-text">Hindi 3D</p>
                </li>
                <li className="filter-li">
                  <p className="filter-data-text">English 3D</p>
                </li>
              </ul>
            </div>
            <div
              id="price-range-dropdown"
              className="filter-dropdown-main price-range-dropdown"
              onMouseLeave={(e) => {
                const timeFilter = document.getElementById(
                  "price-range-dropdown"
                );
                timeFilter?.childNodes[1].classList.remove("show-dropdown");
                timeFilter?.childNodes[0].classList.remove("show-dropdown");
              }}
            >
              <div
                className="filter-drop-btn"
                onClick={(e) => dropDownHandle(e, "price-range-dropdown")}
              >
                <p className="filter-btn-title">Price Range</p>
                <img
                  src={PagesIndex.Svg.ArrowDownIcon}
                  className="filter-down-arrow"
                />
              </div>
              <ul className="filter-ul">
                {movieShowPrices.map((item, key) => {
                  return (
                    <li className="filter-li" key={key}>
                      <div className="filter-check-flex">
                        <Index.FormControlLabel
                          label={
                            <p className="filter-data-text">
                              Rs. {item?.startPrice}-{item?.endPrice}
                            </p>
                          }
                          control={
                            <Index.Checkbox
                              className="filter-checkbox"
                              onClick={(e) => {
                                priceFilter(
                                  key,
                                  e.target.checked,
                                  item?.startPrice,
                                  item?.endPrice
                                );
                              }}
                              checked={item?.checked}
                            />
                          }
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              id="show-time-dropdown"
              className="filter-dropdown-main show-time-dropdown"
              onMouseLeave={() => {
                const timeFilter =
                  document.getElementById("show-time-dropdown");
                timeFilter?.childNodes[1].classList.remove("show-dropdown");
                timeFilter?.childNodes[0].classList.remove("show-dropdown");
              }}
            >
              <div
                className="filter-drop-btn"
                onClick={(e) => dropDownHandle(e, "show-time-dropdown")}
              >
                <p className="filter-btn-title">Show Time</p>
                <img
                  src={PagesIndex.Svg.ArrowDownIcon}
                  className="filter-down-arrow"
                />
              </div>
              <ul className="filter-ul">
                {movieShowTimings.map((item, key) => {
                  return (
                    <li className="filter-li" key={key}>
                      <Index.FormControlLabel
                        label={
                          <>
                            <p className="filter-data-text">
                              {item?.startTime}-{item?.endTime} {item?.period}
                            </p>

                            <div className="filter-check-flex">
                              <p className="filter-time-text">{item.dayTime}</p>
                              <img
                                src={item.icon}
                                className="show-time-icons"
                              />
                            </div>
                          </>
                        }
                        control={
                          <Index.Checkbox
                            className="filter-checkbox"
                            onClick={(e) => {
                              dateFilter(
                                key,
                                e.target.checked,
                                PagesIndex.moment(
                                  `${item?.startTime}${item?.period}`,
                                  "HH:mm A"
                                ).format("HH:mm"),
                                PagesIndex.moment(
                                  `${item?.endTime}${item?.period}`,
                                  "HH:mm A"
                                ).format("HH:mm")
                              );
                            }}
                            checked={item?.checked}
                          />
                        }
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </Index.Box>
        </Index.Box>
        <Index.Box className="detail-tab-body">
          <Index.Box className="detail-tab-body-top">
            <Index.Box className="detail-tab-search">
              <Index.SearchIcon />
              <Index.TextField
                fullWidth
                id="movieSearch"
                className="form-control"
                placeholder="Search here"
                onChange={(e) => {
                  setFilteredMovieShows(
                    movieShows.filter((data) =>
                      data?.cinemaDetails?.displayName
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  );
                }}
              />
            </Index.Box>
            <Index.Box className="detail-tab-availability">
              <Index.Typography
                variant="span"
                component="span"
                className="available"
              >
                Available
              </Index.Typography>
              <Index.Typography
                variant="span"
                component="span"
                className="fast-filling"
              >
                FILLING FAST
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="detail-tab-body-bottom">
            {filterShows?.filter((data) => data?.timings?.length)?.length ? (
              filterShows
                ?.filter((data) => data?.timings?.length)
                ?.map((item, key) => {
                  return (
                    <Index.Box
                      key={key}
                      className={
                        filteredMovieShows?.length < 3
                          ? "theatre-shows-box big"
                          : "theatre-shows-box"
                      }
                    >
                      <Index.Box className="theatre-shows-left">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="theatre-name"
                        >
                          {item.cinemaDetails?.displayName}
                        </Index.Typography>
                        <Index.Box className="theatre-info">
                          <Index.Box className="movie-cancellation">
                            {item?.cinemaDetails?.cinemaAmenities?.includes(
                              "Ticket Cancellation"
                            )
                              ? "Cancellation Available"
                              : "Cancellation Not Available"}
                          </Index.Box>
                          {item?.cinemaDetails?.cinemaAmenities?.length ? (
                            <Index.Box className="movie-info">
                              <Index.InfoOutlinedIcon className="info" />
                              INFO
                              <Index.Box className="movie-info-tooltip">
                                {item?.cinemaDetails?.cinemaAmenities?.includes(
                                  "Ticket Cancellation"
                                ) ? (
                                  <Index.Box className="movie-info-tooltip-inner">
                                    <img
                                      src={PagesIndex.Svg.TicketCancellation}
                                      width="24"
                                      height="24"
                                      alt="Ticket Cancellation"
                                    />
                                    Ticket Cancellation
                                  </Index.Box>
                                ) : (
                                  ""
                                )}
                                {item?.cinemaDetails?.cinemaAmenities?.includes(
                                  "Wheelechair Facility"
                                ) ? (
                                  <Index.Box className="movie-info-tooltip-inner">
                                    <img
                                      src={PagesIndex.Svg.WheelChair}
                                      width="24"
                                      height="24"
                                      alt="Wheel Chair Facility"
                                    />
                                    Wheel Chair Facility
                                  </Index.Box>
                                ) : (
                                  ""
                                )}
                                {item?.cinemaDetails?.cinemaAmenities?.includes(
                                  "Parking Facility"
                                ) ? (
                                  <Index.Box className="movie-info-tooltip-inner">
                                    <img
                                      src={PagesIndex.Svg.Parking}
                                      width="24"
                                      height="24"
                                      alt="Parking Facility"
                                    />
                                    Parking Facility
                                  </Index.Box>
                                ) : (
                                  ""
                                )}
                                {item?.cinemaDetails?.cinemaAmenities?.includes(
                                  "M Ticket"
                                ) ? (
                                  <Index.Box className="movie-info-tooltip-inner">
                                    <img
                                      src={PagesIndex.Svg.MTicket}
                                      width="24"
                                      height="24"
                                      alt="MTicket"
                                    />
                                    MTicket
                                  </Index.Box>
                                ) : (
                                  ""
                                )}
                                {item?.cinemaDetails?.cinemaAmenities?.includes(
                                  "F&B"
                                ) ? (
                                  <Index.Box className="movie-info-tooltip-inner">
                                    <img
                                      src={PagesIndex.Svg.FoodAndBeverages}
                                      width="24"
                                      height="24"
                                      alt="F&B"
                                    />
                                    F&B
                                  </Index.Box>
                                ) : (
                                  ""
                                )}
                              </Index.Box>
                            </Index.Box>
                          ) : (
                            ""
                          )}
                        </Index.Box>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="theatre-location"
                        >
                          <Index.FmdGoodIcon />
                          {item.cinemaDetails?.address}
                        </Index.Typography>
                      </Index.Box>
                      {item?.timings?.length ? (
                        <Index.Box className="theatre-shows-right">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="movie-lang"
                          >
                            {languages}-2D
                          </Index.Typography>
                          <Index.Box className="movie-timing-box">
                            {item?.timings?.map((res, key) => {
                              // const attributes = res?.aData
                              //   ?.split("|")
                              //   ?.join(" ")
                              //   ?.split(" SCREENATTRIBUTES=")
                              //   ?.join("")
                              //   ?.split(" SESSIONATTRIBUTES=")
                              //   ?.map((data) => data?.trim())
                              //   ?.join(", ");
                              const attributes = res?.aData
                                ?.split("|")
                                .filter((data) =>
                                  data?.includes("SCREENATTRIBUTES")
                                )
                                ?.join("")
                                ?.split("SCREENATTRIBUTES=")
                                .filter((data) => data)
                                ?.join(", ");
                              return (
                                <Index.Box
                                  component={"button"}
                                  onClick={() => {
                                    var startTime = PagesIndex.moment(
                                      res?.startTime
                                    ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
                                    var currentTime =
                                      PagesIndex.moment().format(
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      );
                                    let timeisGt = startTime > currentTime;
                                    if (timeisGt) {
                                      navigate(
                                        {
                                          pathname: "/seat-management",
                                          search: PagesIndex.createSearchParams(
                                            {
                                              mId: movieId,
                                              rId: regionId,
                                            }
                                          ).toString(),
                                        },
                                        {
                                          state: {
                                            cId: item.cinemaDetails?.cinemaId,
                                            c_Id: item.cinemaDetails?._id,
                                            show_Time: PagesIndex.moment(
                                              res.startTime
                                            ).format("hh:mm A"),
                                            showDate: selectedDate,
                                            priceDetails: res?.priceDetails,
                                          },
                                        }
                                      );
                                    }
                                  }}
                                  key={key}
                                  className={`movie-timing`}
                                  // className={`movie-timing almost-full sold-out`}
                                  // disabled={res.soldOut === true}
                                >
                                  {PagesIndex.moment(res.startTime).format(
                                    "hh:mm A"
                                  )}
                                  <Index.Typography
                                    variant="span"
                                    component="span"
                                    className="movie-timing-label"
                                  >
                                    {attributes}
                                  </Index.Typography>
                                  {res.areas && (
                                    <Index.Box
                                      key={key}
                                      className="movie-timing-tooltip"
                                    >
                                      {res.areas.map((resItem, key) => (
                                        <Index.Box key={key}>
                                          <Index.Typography
                                            variant="p"
                                            component="p"
                                            className="timing-tooltip-price"
                                          >
                                            ₹{resItem.price}
                                          </Index.Typography>
                                          <Index.Typography
                                            variant="p"
                                            component="p"
                                            className="timing-tooltip-class"
                                          >
                                            {resItem.label}
                                          </Index.Typography>
                                          <Index.Typography
                                            variant="p"
                                            component="p"
                                            className={`timing-tooltip-availability ${
                                              resItem?.statusColour === "Y"
                                                ? "filling-fast"
                                                : resItem?.statusColour === "R"
                                                ? "sold-out"
                                                : "available"
                                            }`}
                                          >
                                            {resItem?.statusColour === "Y"
                                              ? "Filling Fast"
                                              : resItem?.statusColour === "R"
                                              ? resItem?.statusColour === "R" &&
                                                resItem.aSeats
                                                ? "Almost Full"
                                                : "Sold Out"
                                              : "Available"}
                                          </Index.Typography>
                                        </Index.Box>
                                      ))}
                                    </Index.Box>
                                  )}
                                </Index.Box>
                              );
                            })}
                          </Index.Box>
                        </Index.Box>
                      ) : (
                        <Index.Box className="theatre-shows-right">
                          No shows found
                        </Index.Box>
                      )}
                    </Index.Box>
                  );
                })
            ) : (
              <Index.Box className="no-found-img-box">
                <img src={PagesIndex.Png.Theatre} alt="No Found" />
                No theatre found
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default DetailTabContent;
