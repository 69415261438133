import ScreenImg from "../assets/images/svg/screenImg.svg"
import ConnplexLogo from "../assets/images/svg/connplexLogo.svg"
import GoogleIcon from "../assets/images/svg/googleIcon.svg"
import FacebookIcon from "../assets/images/svg/facebookIcon.svg"
import AppleIcon from "../assets/images/svg/appleIcon.svg"
import PlayStore from "../assets/images/svg/playStore.svg"
import AppleStore from "../assets/images/svg/appleStore.svg"
import MorningIcon from "../assets/images/svg/morning.svg"
import AfternoonIcon from "../assets/images/svg/afternoon.svg"
import EveningIcon from "../assets/images/svg/evening.svg"
import NightIcon from "../assets/images/svg/night.svg"
import PageNotFound from "../assets/images/svg/pageNotFound.svg"
import ArrowDownIcon from "../assets/images/svg/arrowDownIcon.svg"
import TicketCancellation from "../assets/images/svg/ticket_cancellation.svg"
import MTicket from "../assets/images/svg/m_ticket.svg"
import FoodAndBeverages from "../assets/images/svg/food_and_beverages.svg"
import WheelChair from "../assets/images/svg/wheel_chair.svg"
import Parking from "../assets/images/svg/parking.svg"

const Svg = {
    ScreenImg,
    ConnplexLogo,
    GoogleIcon,
    FacebookIcon,
    AppleIcon,
    PlayStore,
    AppleStore,
    MorningIcon,
    AfternoonIcon,
    EveningIcon,
    NightIcon,
    PageNotFound,
    ArrowDownIcon,
    TicketCancellation,
    MTicket,
    FoodAndBeverages,
    WheelChair,
    Parking,
};

export default Svg;