import Footer from "./Footer";
import Header from "./Header";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";

const Layout = () => {
  const { isLoading } = PagesIndex.useSelector((state) => state.UserReducer);
  return (
    <>
      <Header />
      <Index.Box className="main-content">
        {isLoading && <PagesIndex.Loader primary />}
        <PagesIndex.Outlet />
      </Index.Box>
      <Footer />
    </>
  );
};

export default Layout;
