import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../container/pages/user/home/Home";
import About from "../container/pages/user/about/About";
import Contact from "../container/pages/user/contact/Contact";
import MovieDetail from "../container/pages/user/movieDetail/MovieDetail";
import SeatManagement from "../container/pages/user/seatManagement/SeatManagement";
import AddSnacks from "../container/pages/user/addSnacks/AddSnacks";
import Gallery from "../container/pages/user/gallery/Gallery";
import Account from "../container/pages/user/account/Account";
import Cinema from "../container/pages/user/cinema/Cinema";
import Calender from "../container/pages/user/calender/Calender";
import Ecommerce from "../container/pages/user/ecommerce/Ecommerce";
import Layout from "../components/user/defaultLayout/Layout";
import PrivacyPolicy from "../container/pages/user/privacyPolicy/PrivacyPolicy";
import TermsConition from "../container/pages/user/termsCondition/TermsCondition";
import Membership from "../container/pages/user/membership/Membership";
import TransactionFailed from "../container/pages/user/transactionFailed/TransactionFailed";
import GallaryImages from "../container/pages/user/gallery/gallaryImages/GallaryImages";
import PageNotFound from "../container/pages/user/pageNotFound/PageNotFound";
import ConfirmationScreen from "../container/pages/user/confirmationScreen/ConfirmationScreen";
import BookingInfo from "../container/pages/user/bookingInfo/BookingInfo";
import Faq from "../container/pages/user/faq/Faq";
import Franchise from "../container/pages/user/franchise/Franchise";
import CinemaInner from "../container/pages/user/cinema/cinemaInner/CinemaInner";
import LegalNotice from "../container/pages/user/legalNotice/LegalNotice";
import Advertise from "../container/pages/user/advertise/Advertise";
import Career from "../container/pages/user/career/Career";

function Routers() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/movie-details" element={<MovieDetail />} />
        <Route path="/seat-management" element={<SeatManagement />} />
        <Route path="/add-snacks" element={<AddSnacks />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:id" element={<GallaryImages />} />
        <Route path="/account" element={<Account />} />
        <Route path="/cinema" element={<Cinema />} />
        <Route path="/cinema-detail" element={<CinemaInner />} />
        <Route path="/calender" element={<Calender />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConition />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/franchise" element={<Franchise />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/confirmation-screen" element={<ConfirmationScreen />} />
        <Route path="/booking-info" element={<BookingInfo />} />
        <Route path="/my-booked-ticket" element={<ConfirmationScreen />} />
        <Route path="/legal-notice" element={<LegalNotice />} />
        <Route path="/advertise" element={<Advertise />} />
        <Route path="/career" element={<Career />} />
      </Route>
      <Route path="/transaction-failed" element={<TransactionFailed />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/app-privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/app-terms-conditions" element={<TermsConition />} />
      <Route path="/app-legal-notice" element={<LegalNotice />} />
      <Route path="/app-about" element={<About />} />
    </Routes>
  );
}

export default Routers;
