import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function SeatManagement() {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const { isLoggedIn } = PagesIndex.useSelector((state) => state.UserReducer);
  const movieId = new URLSearchParams(location.search).get("mId");
  const regionId = new URLSearchParams(location.search).get("rId");
  const [seatLayout, setSeatLayout] = useState([]);
  const [selectedSeatArea, setSelectedSeatArea] = useState("");
  const [selectedSeatAreaPrice, setSelectedSeatAreaPrice] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [showTimingsData, setShowTimingsData] = useState([]);
  const [selectedShowTiming, setSelectedShowTiming] = useState(
    location.state?.show_Time
  );
  const [selectedSessionId, setSelectedSessionId] = useState("");
  const [pGroupCode, setPGroupCode] = useState("");
  const [showId, setShowId] = useState("");
  const [movieData, setMovieData] = useState({});
  const [cinemaData, setCinemaData] = useState({});
  const [areaPriceDetailsList, setAreaPriceDetailsList] = useState([]);
  const [selectedAreaData, setSelectedAreaData] = useState({});
  const [openTerms, setOpenTerms] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [seatDetails, setSeatDetails] = useState([]);
  const [tax1, setTax1] = useState(0);
  const [tax2, setTax2] = useState(0);
  const [tax3, setTax3] = useState(0);
  const [tax4, setTax4] = useState(0);
  const [convenienceFees, setConvenienceFees] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const signInOpen = () => {
    setToggle(true);
  };
  const signInClose = () => setToggle(false);
  const handleCloseWarning = () => setOpenWarning(false);
  const handleTermsOpen = () => setOpenTerms(true);
  const handleTermsClose = () => setOpenTerms(false);
  useEffect(() => {
    if (location.state) {
      getShowTimingsByCinemaAndMovie();
    } else {
      navigate(
        {
          pathname: `/movie-details`,
          search: PagesIndex?.createSearchParams({
            mId: movieId,
            rId: regionId,
          }).toString(),
        },
        { replace: true }
      );
    }
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, []);
  useEffect(() => {
    if (location.state?.cId && location.state?.c_Id) {
      if (selectedSessionId) {
        setIsLoading(true);
        getSeatLayout();
      }
    } else {
      navigate({
        pathname: `/movie-details`,
        search: PagesIndex?.createSearchParams({
          mId: movieId,
          rId: regionId,
        }).toString(),
      });
    }
  }, [selectedSessionId]);
  const getShowTimingsByCinemaAndMovie = () => {
    dispatch(PagesIndex.showLoader());
    const urlEncoded = new URLSearchParams();
    urlEncoded.append(
      "id",
      `${location.state.c_Id}|${location.state?.showDate}|${movieId}`
    );
    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_SHOW_TIMINGS_BY_CINEMA_MOVIE,
      urlEncoded
    )
      .then((res) => {
        const shows = [];
        res.data.map((item) => {
          var startTime = PagesIndex.moment(item?.sessionRealShow).format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          );
          var currentTime = PagesIndex.moment().format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          );
          let timeisGt = startTime < currentTime;
          const objData = {
            sessionRealShow: item?.sessionRealShow,
            sessionId: item?.sessionId,
            screenName: item?.screenName,
            pGroupCode: item?.pGroupCode,
            sessionAdditionalData: item?.sessionAdditionalData,
            _id: item?._id,
          };
          if (!timeisGt) {
            shows.push(objData);
          }
        });
        setShowTimingsData(shows);
        setMovieData(res.movie);
        setCinemaData(res.cinema);
        const selectedShow = res.data.find(
          (data) =>
            PagesIndex.moment(data?.sessionRealShow).format("hh:mm A") ===
            location.state?.show_Time
        );
        setSelectedSessionId(selectedShow?.sessionId);
        setPGroupCode(selectedShow?.pGroupCode);
        setShowId(selectedShow?._id);
      })
      .catch(() => {});
    dispatch(PagesIndex.hideLoader());
  };
  const getSeatLayout = () => {
    dispatch(PagesIndex.showLoader());
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_SEAT_LAYOUT,
      `${location.state.cId}/${selectedSessionId}`
    ).then((res) => {
      if (res?.status === 200) {
        getAreaPriceList(res.data.data);
      } else {
      }
      setIsLoading(false);
      dispatch(PagesIndex.hideLoader());
    });
  };
  const getAreaPriceList = (seatLayout) => {
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_PRICE_DETAILS_LIST,
      `${pGroupCode}/${location.state?.cId}`
    ).then((res) => {
      if (res?.status === 200) {
        setAreaPriceDetailsList(res.data);
        setSeatLayout(modifyData(seatLayout, res.data));
      } else {
        PagesIndex.toast.error("Something went wrong");
      }
    });
  };
  const modifyData = (seatLayout, areaPriceData) => {
    let modifiedData = [];
    seatLayout.map((seatData, index) => {
      let area = areaPriceData?.find((item) => {
        return item?.areaCatCode === seatData.colData.strAreaCode;
      });
      if (!modifiedData[seatData.colData.strAreaCode])
        modifiedData[seatData.colData.strAreaCode] = {
          ...seatData.colData,
          area_price: area?.currentPrice,
          priceTax1: area?.priceTax1,
          priceTax2: area?.priceTax2,
          priceTax3: area?.priceTax3,
          priceTax4: area?.priceTax4,
          rowData: [],
        };

      if (index == 0) modifiedData[seatData.colData.strAreaCode].rowData = [];

      modifiedData[seatData.colData.strAreaCode].rowData[
        seatData.rowData.strRowPhyID
      ] = seatData.rowData;
    });

    //
    seatLayout.map((seatData, index) => {
      if (
        !modifiedData[seatData.colData.strAreaCode].rowData[
          seatData.rowData.strRowPhyID
        ].seatData
      )
        modifiedData[seatData.colData.strAreaCode].rowData[
          seatData.rowData.strRowPhyID
        ].seatData = [];

      modifiedData[seatData.colData.strAreaCode].rowData[
        seatData.rowData.strRowPhyID
      ].seatData[index] = seatData.seatData;
    });
    let actualData = [];
    for (var key in modifiedData) {
      let actualRowData = [];
      // check if the property/key is defined in the object itself, not in parent
      if (modifiedData.hasOwnProperty(key)) {
      }
      for (var key2 in modifiedData[key].rowData) {
        let actualSeatData = [];
        for (var key3 in modifiedData[key].rowData[key2].seatData) {
          actualSeatData.unshift(
            modifiedData[key].rowData[key2].seatData[key3]
          );
        }
        modifiedData[key].rowData[key2].seatData = actualSeatData;
        actualRowData.unshift(modifiedData[key].rowData[key2]);
      }
      modifiedData[key].rowData = actualRowData;
      actualData.unshift(modifiedData[key]);
    }
    setIsLoading(false);
    return actualData;
  };
  const handleSeatSelection = async (index1, index2, index3) => {
    const selectedArea = areaPriceDetailsList?.find(
      (item) => item?.areaCatCode === seatLayout[index1].strAreaCode
    );
    if (
      seatLayout[index1]?.rowData[index2]?.seatData[index3]?.strSeatStatus ===
      "0"
    ) {
      setSelectedAreaData(selectedArea);
      setSelectedSeatAreaPrice(seatLayout[index1].area_price);
      handleChangeArea(index1, index2, index3);
      if (selectedSeats.length < 10) {
        handleSeatSelect(index1, index2, index3);
        setSelectedSeatArea(seatLayout[index1].strAreaDesc);
      } else if (
        selectedSeats.length === 10 &&
        selectedSeatArea === seatLayout[index1].strAreaDesc
      ) {
        setOpenWarning(true);
      }
    } else if (
      seatLayout[index1]?.rowData[index2]?.seatData[index3]?.strSeatStatus ===
      "-1"
    ) {
      handleSeatDeSelect(index1, index2, index3);
    }
  };
  // Function to handle seat selection from seta layout
  const handleSeatSelect = (index1, index2, index3) => {
    setConvenienceFees((prev) => prev + cinemaData?.convenienceFees);
    if (
      seatLayout[index1].rowData[index2].seatData[index3].strSeatStatus === "0"
    ) {
      setSeatLayout((e) => {
        let arr = [...e];
        arr[index1].rowData[index2].seatData[index3].strSeatStatus = "-1";
        return arr;
      });
      setSelectedPrice((prev) => {
        let value = prev + seatLayout[index1].area_price;
        return value;
      });
      setTax1((prev) => {
        let value = prev + seatLayout[index1].priceTax1;
        return value;
      });
      setTax2((prev) => {
        let value = prev + seatLayout[index1].priceTax2;
        return value;
      });
      setTax3((prev) => {
        let value = prev + seatLayout[index1].priceTax3;
        return value;
      });
      setTax4((prev) => {
        let value = prev + seatLayout[index1].priceTax4;
        return value;
      });
      setSelectedSeats((prev) => [
        ...prev,
        `${seatLayout[index1].rowData[index2].strRowPhyID}${seatLayout[index1].rowData[index2].seatData[index3].strSeatNumber}`,
      ]);
      setSeatDetails((prev) => [
        ...prev,
        {
          areaCode: seatLayout[index1].strAreaCode,
          areaNumber: seatLayout[index1].strAreaNum,
          SeatRowId: seatLayout[index1].rowData[index2].intGridRowID,
          seatNumber:
            seatLayout[index1].rowData[index2].seatData[index3].intGridSeatNum,
        },
      ]);
    }
  };
  // Function to deselect the selected seats from seat layout
  const handleSeatDeSelect = (index1, index2, index3) => {
    setConvenienceFees((prev) => prev - cinemaData?.convenienceFees);
    if (
      seatLayout[index1].rowData[index2].seatData[index3].strSeatStatus === "-1"
    ) {
      setSeatLayout((e) => {
        let arr = [...e];
        arr[index1].rowData[index2].seatData[index3].strSeatStatus = "0";
        return arr;
      });
      setSelectedPrice((prev) => {
        let value = prev - seatLayout[index1].area_price;
        return value;
      });
      setTax1((prev) => {
        let value = prev - seatLayout[index1].priceTax1;
        return value;
      });
      setTax2((prev) => {
        let value = prev - seatLayout[index1].priceTax2;
        return value;
      });
      setTax3((prev) => {
        let value = prev - seatLayout[index1].priceTax3;
        return value;
      });
      setTax4((prev) => {
        let value = prev - seatLayout[index1].priceTax4;
        return value;
      });
      setSelectedSeats((prev) => {
        const arr = prev.filter(function (item) {
          return (
            item !==
            `${seatLayout[index1].rowData[index2].strRowPhyID}${seatLayout[index1].rowData[index2].seatData[index3].strSeatNumber}`
          );
        });
        return arr;
      });
      setSeatDetails((prev) => {
        const arr = prev.filter(function (item) {
          return (
            item.seatNumber !==
            seatLayout[index1].rowData[index2].seatData[index3].intGridSeatNum
          );
        });
        return arr;
      });
    }
  };
  // Function for handling area change in seat layout
  const handleChangeArea = (index1, index2, index3) => {
    if (seatLayout[index1].strAreaDesc !== selectedSeatArea) {
      setSelectedPrice(0);
      setTax1(0);
      setTax2(0);
      setTax3(0);
      setTax4(0);
      setSelectedSeats([]);
      setSeatDetails([]);
      setSeatLayout((e) => {
        let arr = [...e];
        arr.map((item, index01) => {
          item.rowData.map((rows, index02) => {
            rows.seatData.map((seats, index03) => {
              if (
                arr[index01].rowData[index02].seatData[index03]
                  .strSeatStatus === "-1"
              ) {
                arr[index01].rowData[index02].seatData[index03].strSeatStatus =
                  "0";
              }
            });
          });
        });
        return arr;
      });
      if (selectedSeats.length === 10) {
        handleSeatSelect(index1, index2, index3);
      }
    }
  };
  const handleInitBooking = async () => {
    dispatch(PagesIndex.showLoader());
    handleTermsClose();
    await PagesIndex.apiGetHandler(
      PagesIndex.Api.INIT_SEAT_BOOKING,
      location.state.cId
    ).then(async (res) => {
      if (res?.status === 200) {
        await addSeats(res.data.data);
      } else {
        PagesIndex.toast.error("Something went wrong");
        dispatch(PagesIndex.hideLoader());
      }
    });
  };
  const addSeats = async (transactionId) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append(
      "id",
      `${location.state.cId}|${transactionId}|${selectedSessionId}|${selectedAreaData?.tTypeCode}|${selectedSeats.length}`
    );
    await PagesIndex.apiPostHandler(PagesIndex.Api.ADD_SEATS, urlEncoded).then(
      async (res) => {
        if (res?.status === 200) {
          await setSeats(transactionId);
        } else {
          PagesIndex.toast.error("Something went wrong");
          dispatch(PagesIndex.hideLoader());
        }
      }
    );
  };
  const setSeats = async (transactionId) => {
    const seatString = `|${seatDetails?.length}${seatDetails
      ?.map(
        (detail) =>
          `|${detail.areaCode}|${detail.areaNumber}|${detail.SeatRowId}|${detail.seatNumber}`
      )
      .join("")}|`;
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("cinemaId", location.state.cId);
    urlEncoded.append("cinemaObjId", location.state.c_Id);
    urlEncoded.append("movieObjId", movieData?._id);
    urlEncoded.append("showObjId", showId);
    urlEncoded.append("strTransId", transactionId);
    urlEncoded.append("lngSessionId", selectedSessionId);
    urlEncoded.append("strSelectedSeats", seatString);
    await PagesIndex.apiPostHandler(PagesIndex.Api.SET_SEATS, urlEncoded)
      .then(async (res) => {
        if (res?.status === 200) handleTermsClose();
        dispatch(PagesIndex.hideLoader());
        if (res?.status === 200) {
          navigate(
            {
              pathname: "/add-snacks",
              search: PagesIndex?.createSearchParams({
                mId: movieId,
                rId: regionId,
              }).toString(),
            },
            {
              state: {
                selectedSessionId: selectedSessionId,
                cinemaData: cinemaData,
                selectedAreaData: selectedAreaData,
                selectedSeats: selectedSeats,
                movieData: movieData,
                pGroupCode: pGroupCode,
                selectedShowTiming: selectedShowTiming,
                showDate: location.state?.showDate,
                convenienceFees: convenienceFees,
                ticketPriceDetails: {
                  total: selectedPrice,
                  tax1: tax1,
                  tax2: tax2,
                  tax3: tax3,
                  tax4: tax4,
                },
                transactionId: transactionId,
              },
            }
          );
          window.history.replaceState({}, document.title);
        } else {
          PagesIndex.toast.error("Something went wrong");
        }
      })
      .catch(() => {
        dispatch(PagesIndex.hideLoader());
      });
  };

  return (
    <Index.Box className="main-seat-management">
      <Index.Box className="cus-container">
        <Index.Box className="seat-management-header">
          <Index.Typography
            variant="h1"
            component="h1"
            className="header-title"
          >
            {movieData?.name}
          </Index.Typography>
          <Index.Typography
            variant="p"
            component="p"
            className="header-content"
          >
            {PagesIndex.moment(location.state?.showDate, "YYYY-MM-DD").format(
              "dddd, DD MMM"
            )}{" "}
            ({movieData?.languages} 2D)
          </Index.Typography>
          <Index.Box className="header-timing-box cus-scrollbar">
            <Index.Box className="header-timing-box-inner">
              {showTimingsData.map((item, key) => {
                const attributes = item?.sessionAdditionalData
                  ?.split("|")
                  .filter((data) => data?.includes("SCREENATTRIBUTES"))
                  ?.join("")
                  ?.split("SCREENATTRIBUTES=")
                  .filter((data) => data)
                  ?.join(", ");
                return (
                  <PagesIndex.Button
                    key={key}
                    className={`movie-timing ${
                      PagesIndex.moment(item.sessionRealShow).format(
                        "hh:mm A"
                      ) === selectedShowTiming
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedShowTiming(
                        PagesIndex.moment(item.sessionRealShow).format(
                          "hh:mm A"
                        )
                      );
                      setSelectedSessionId(item?.sessionId);
                      setPGroupCode(item?.pGroupCode);
                      setShowId(item?._id);
                    }}
                  >
                    {PagesIndex.moment(item.sessionRealShow).format("hh:mm A")}
                    <Index.Typography
                      variant="span"
                      component="span"
                      className="movie-timing-label"
                    >
                      {attributes}
                    </Index.Typography>
                  </PagesIndex.Button>
                );
              })}
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="seat-management-body">
          <Index.Box className="seat-box-wrapper">
            <Index.Box className="seat-box-inner cus-scrollbar">
              <Index.Box className="seat-box-inner-wrapper">
                <Index.Box className="screen-box">
                  <img
                    src={PagesIndex.Svg.ScreenImg}
                    width=""
                    height=""
                    alt="screen"
                  />
                  <Index.Typography
                    component="span"
                    variant="span"
                    className="screen-box-text"
                  >
                    SCREEN THIS WAY
                  </Index.Typography>
                </Index.Box>
                {!isLoading ? (
                  seatLayout.length ? (
                    <Index.Table>
                      <Index.TableBody>
                        {seatLayout?.map((item, key1) => {
                          return (
                            <>
                              <Index.TableRow>
                                <Index.TableCell
                                  component="th"
                                  scope="row"
                                  colSpan={15}
                                >
                                  {item.strAreaDesc} - ₹{item?.area_price}
                                </Index.TableCell>
                              </Index.TableRow>
                              {item.rowData.map((res, key2) => {
                                return (
                                  <Index.TableRow key={key2}>
                                    <Index.TableCell align="center">
                                      {res.strRowPhyID}
                                    </Index.TableCell>
                                    {res.seatData.map((resItem, key3) => {
                                      return (
                                        <>
                                          {resItem.Key ? (
                                            resItem.strSeatStatus === "-1" ? (
                                              <Index.TableCell
                                                align="center"
                                                onClick={async () => {
                                                  await handleSeatSelection(
                                                    key1,
                                                    key2,
                                                    key3
                                                  );
                                                }}
                                              >
                                                <Index.Box className="seat-box">
                                                  <PagesIndex.SeatIcon selected />
                                                  <Index.Box className="seat-box-tooltip">
                                                    <img src={PagesIndex.Png.NoImage} alt="Ticket Cancellation" />
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.TableCell>
                                            ) : resItem.strSeatStatus ===
                                              "1" ? (
                                              <Index.TableCell
                                                align="center"
                                                onClick={async () => {
                                                  await handleSeatSelection(
                                                    key1,
                                                    key2,
                                                    key3
                                                  );
                                                }}
                                              >
                                                <Index.Box className="seat-box">
                                                  <PagesIndex.SeatIcon reserved />
                                                  <Index.Box className="seat-box-tooltip">
                                                    <img src={PagesIndex.Png.NoImage} alt="Ticket Cancellation" />
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.TableCell>
                                            ) : (
                                              <Index.TableCell
                                                align="center"
                                                onClick={async () => {
                                                  await handleSeatSelection(
                                                    key1,
                                                    key2,
                                                    key3
                                                  );
                                                }}
                                              >
                                                <Index.Box className="seat-box">
                                                  <PagesIndex.SeatIcon />
                                                  <Index.Box className="seat-box-tooltip">
                                                    <img src={PagesIndex.Png.NoImage} alt="Ticket Cancellation" />
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.TableCell>
                                            )
                                          ) : (
                                            <Index.TableCell align="center"></Index.TableCell>
                                          )}
                                        </>
                                      );
                                    })}
                                  </Index.TableRow>
                                );
                              })}
                            </>
                          );
                        })}
                      </Index.TableBody>
                    </Index.Table>
                  ) : (
                    <Index.Box className="no-found-svg-box">
                      <Index.EventSeatIcon />
                      No Seats available
                    </Index.Box>
                  )
                ) : (
                  <Index.Box className="no-found-svg-box">
                    <Index.EventSeatIcon />
                    Loading...
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>
            <Index.Box className="seat-suggestion-box">
              <Index.Box className="seat-available">
                <Index.Typography
                  variant="span"
                  component="span"
                  className="seat-suggestion-color"
                ></Index.Typography>
                <Index.Typography
                  variant="span"
                  component="span"
                  className="seat-suggestion-text"
                >
                  Available
                </Index.Typography>
              </Index.Box>
              <Index.Box className="seat-reserved">
                <Index.Typography
                  variant="span"
                  component="span"
                  className="seat-suggestion-color"
                ></Index.Typography>
                <Index.Typography
                  variant="span"
                  component="span"
                  className="seat-suggestion-text"
                >
                  Reserved
                </Index.Typography>
              </Index.Box>
              <Index.Box className="seat-selected">
                <Index.Typography
                  variant="span"
                  component="span"
                  className="seat-suggestion-color"
                ></Index.Typography>
                <Index.Typography
                  variant="span"
                  component="span"
                  className="seat-suggestion-text"
                >
                  Selected
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="summary-box-wrapper">
            <Index.Typography
              variant="p"
              component="p"
              className="summary-main-title"
            >
              Your Booking
            </Index.Typography>
            <Index.Box className="summary-box-inner cus-scrollbar">
              <Index.Box className="booking-summary">
                <Index.Box className="selected-movie summary-box-row">
                  <img
                    src={
                      movieData?.poster
                        ? PagesIndex.IMAGES_API_ENDPOINT + movieData?.poster
                        : PagesIndex.Png.NoImage
                    }
                    width="1280"
                    height="80"
                    alt="movie detail"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = PagesIndex.Png.NoImage;
                    }}
                  />
                  <Index.Typography>
                    <Index.Typography className="summary-title">
                      {movieData?.name}
                    </Index.Typography>
                    <Index.Typography className="summary-subtitle">
                      ({" "}
                      {movieData?.category
                        ? movieData?.category?.replace(", ", " | ")
                        : "-"}{" "}
                      |{" "}
                      {movieData?.censorRating ? movieData?.censorRating : "-"}{" "}
                      )
                    </Index.Typography>
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="selected-seat summary-box-row">
                  <PagesIndex.SeatIcon selected />
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="summary-title"
                  >
                    {selectedSeats?.length} Seats{" "}
                    {selectedSeats?.length
                      ? `(${selectedSeats?.toString()?.replaceAll(",", ", ")})`
                      : ""}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="selected-cinema summary-box-row">
                  <Index.FmdGoodIcon />
                  <Index.Box>
                    <Index.Typography
                      variant="span"
                      component="span"
                      className="summary-title"
                    >
                      {cinemaData?.displayName}
                    </Index.Typography>
                    <Index.Typography
                      variant="span"
                      component="span"
                      className="summary-subtitle"
                    >
                      {cinemaData?.address}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="selected-time summary-box-row">
                  <Index.WatchLaterIcon />
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="summary-title"
                  >
                    {selectedShowTiming}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="payment-summary">
                <Index.Box className="payment-summary-row">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-label"
                  >
                    <Index.Typography variant="p" component="p">
                      Ticket Price :
                    </Index.Typography>
                    <Index.Typography variant="p" component="p">
                      ({selectedSeats.length} x ₹{selectedSeatAreaPrice})
                    </Index.Typography>
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-price"
                  >
                    ₹{parseFloat(selectedPrice).toFixed(2)}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="payment-summary-row">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-label"
                  >
                    CGST :
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-price"
                  >
                    ₹{parseFloat(tax1).toFixed(2)}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="payment-summary-row">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-label"
                  >
                    SGST :
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-price"
                  >
                    ₹{parseFloat(tax2).toFixed(2)}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="payment-summary-row">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-label"
                  >
                    <Index.Typography variant="p" component="p">
                      Convenience fee :
                    </Index.Typography>
                    <Index.Typography variant="p" component="p">
                      ({selectedSeats.length} x ₹{cinemaData?.convenienceFees})
                    </Index.Typography>
                  </Index.Typography>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="payment-summary-price"
                  >
                    ₹{parseFloat(convenienceFees).toFixed(2)}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="total-payment-row">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="total-payment"
                >
                  Total :
                </Index.Typography>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="total-payment"
                >
                  ₹{parseFloat(selectedPrice + convenienceFees).toFixed(2)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="summary-box-button">
                <PagesIndex.Button
                  primary
                  className="book-now-btn"
                  onClick={handleTermsOpen}
                  disabled={!selectedSeats.length}
                >
                  Book Now
                </PagesIndex.Button>
                {/* <Index.Link
                to="/add-snacks"
                className="add-snacks-btn btn btn-secondary"
              >
                Add Snacks
              </Index.Link> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openWarning}
        onClose={handleCloseWarning}
        className="max-seat-warning-modal common-modal"
      >
        <Index.Box className="max-seat-warning-modal-inner common-modal-inner">
          <Index.Box className="modal-inner cus-scrollbar">
            <Index.Box className="max-seat-warning">
              <Index.ErrorIcon />
              Warning
            </Index.Box>
            <Index.Typography className="max-seat-warning-text">
              You cannot select more than 10 seats.
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <Index.Modal
        open={openTerms}
        onClose={handleTermsClose}
        aria-labelledby="terms-modal-title"
        aria-describedby="terms-modal-description"
        className="terms-modal common-modal"
      >
        <Index.Box className="terms-modal-inner common-modal-inner">
          <Index.Box className="terms-modal-title common-modal-title">
            Terms & Conditions
          </Index.Box>
          <Index.Box className="modal-inner cus-scrollbar">
            <Index.Box className="terms-modal-content">
              <Index.Typography variant="p" component="p">
                1. Seat layout page for Connplex cinemas is for representational
                purpose only and actual seat layout might vary.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                2. Ticket is compulsory for children of 3 years & above.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                3. Patrons below the age of 18 years cannot be admitted for
                movies certified A.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                4. For 3D movies, ticket price includes charges towards usage of
                3D glasses.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                5. Outside Food & Beverage are not allowed In the cinema
                premises.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                6. Items like laptops, cameras, knives, lighters, matchboxes,
                cigarettes, firearms, and all types of inflammable objects are
                strictly prohibited.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                7. Items like carry-bags, eatables, helmets, handbags are not
                allowed inside the theatres and are strictly prohibited. Kindly
                deposit at the baggage counter of the mall/cinema.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                8. Smoking is strictly not permitted inside the cinema premises.
                Cigarettes/lighters/matchsticks/Gutkha/Pan masala etc. will not
                be allowed.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                9. Ticket prices are subject to change without any prior
                notification.
              </Index.Typography>
              <Index.Typography variant="p" component="p">
                10. For celebrating Birthday parties and special occasions,
                kindly contact the cinema manager.
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="terms-modal-btn">
            <PagesIndex.Button
              primary
              onClick={() => {
                if (isLoggedIn) {
                  handleInitBooking();
                } else {
                  signInOpen();
                }
              }}
            >
              Accept
            </PagesIndex.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <Index.Modal
        open={toggle}
        onClose={signInClose}
        aria-labelledby="signin-modal-title"
        aria-describedby="signin-modal-description"
        className="signin-modal"
      >
        <>
          <PagesIndex.Login signInClose={signInClose} />
        </>
      </Index.Modal>
    </Index.Box>
  );
}

export default SeatManagement;
