import type from "./type";

const initialState = {
  userDetails: {},
  userToken: "",
  isLoggedIn: false,
  region: "",
  upcomingMoviesList: [],
  isLoading: false,
  otpTimer: { minute: 0, seconds: 30 },
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.getUserDetails:
      return {
        ...state,
        userDetails: action.payload,
        isLoggedIn: true,
      };
    case type.getUserToken:
      return {
        ...state,
        userToken: action.payload,
      };
    case type.userLogOut:
      return {
        ...state,
        isLoggedIn: false,
      };
    case type.upcomingMoviesList:
      return {
        ...state,
        upcomingMoviesList: action.payload,
      };
    case type.getOtpTimer:
      return {
        ...state,
        otpTimer: action.payload,
      };
    case type.showLoader:
      return {
        ...state,
        isLoading: true,
      };
    case type.hideLoader:
      return {
        ...state,
        isLoading: false,
      };
    case type.changeRegion:
      return {
        ...state,
        region: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
