import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function Advertise() {

  return (
    <Index.Box className="main-advertise">
      <PagesIndex.BannerImage
        bannerImage={PagesIndex.Jpg.contactBanner}
        bannerImageWidth="900"
        bannerImageHeight="570"
        bannerTitle="Advertise With Us"
      />
      <Index.Box className="main-advertise-wrapper">
        <Index.Box className="cus-container">
          <Index.Grid container spacing={{ md: 5, xxs: 4 }} alignItems="center">
            <Index.Grid item lg={6} xxs={12} className="advertise-left">
              <Index.Box className="advertise-img">
                <img src={PagesIndex.Jpg.membership} alt="advertise" />
              </Index.Box>
            </Index.Grid>
            <Index.Grid item lg={6} xxs={12} className="advertise-right">
              <Index.Box className="advertise-form">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="advertise-form-title"
                >
                  Advertise With Us
                </Index.Typography>
                <Index.Grid container spacing={2} className="form-group">
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Brand name
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="brandNameAdvertise"
                      className="form-control"
                      placeholder="Brand name"
                      name="brandName"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Designation
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="designationAdvertise"
                      className="form-control"
                      placeholder="Designation"
                      name="designation"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={12} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Website
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="websiteAdvertise"
                      className="form-control"
                      placeholder="Website"
                      name="website"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      First name
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="firstNameAdvertise"
                      className="form-control"
                      placeholder="First name"
                      name="firstName"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Last name
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="lastNameAdvertise"
                      className="form-control"
                      placeholder="First name"
                      name="firstName"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Email
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="emailAdvertise"
                      className="form-control"
                      placeholder="Email"
                      type="email"
                      name="email"
                      inputProps={{ maxLength: 320 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={6} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Phone number
                    </Index.FormHelperText>
                    <Index.TextField
                      fullWidth
                      id="phoneAdvertise"
                      className="form-control"
                      placeholder="Phone number"
                      name="phoneNumber"
                      inputProps={{ maxLength: 10 }}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={12} xxs={12}>
                    <Index.FormHelperText className="form-label">
                      Message
                    </Index.FormHelperText>
                    <Index.TextareaAutosize
                      minRows={5}
                      placeholder="Message"
                      name="message"
                      maxLength={250}
                    />
                  </Index.Grid>
                  <Index.Grid item sm={12} xxs={12} className="form-action">
                    <PagesIndex.Button type="submit" primary>
                      Submit
                    </PagesIndex.Button>
                  </Index.Grid>
                </Index.Grid>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );

}

export default Advertise;
