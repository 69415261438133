import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function AccountTab() {
  const initialValuesAccount = {
    email: "",
    phoneNumber: "",
  };
  const initialValues = {
    profile: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "",
    maritalStatus: "",
    address: "",
    city: "",
  };
  const initialValuesOtp = {
    otp: "",
  };
  const dispatch = PagesIndex.useDispatch();
  const formikRef = useRef();
  const formikRefAccount = useRef();
  const formikRefOtp = useRef();
  const { userDetails, userToken, otpTimer } = PagesIndex.useSelector(
    (state) => state.UserReducer
  );
  const [imageUrl, setImageUrl] = useState("");
  const [edit, setEdit] = useState(false);
  const [editType, setEditType] = useState("email");
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [changedCredential, setChangedCredential] = useState("");

  useEffect(() => {
    getUser();
  }, []);
  useEffect(() => {
    let myInterval;
    if (openOTPModal) {
      myInterval = setTimeout(() => {
        if (otpTimer.seconds > 0) {
          dispatch(
            PagesIndex.getOtpTimer({
              minute: otpTimer.minute,
              seconds: otpTimer.seconds - 1,
            })
          );
        }
        if (otpTimer.seconds == 0) {
          if (otpTimer.minute == 0) {
            clearTimeout(myInterval);
            dispatch(
              PagesIndex.getOtpTimer({
                seconds: 0,
                minute: 0,
              })
            );
          } else {
            dispatch(
              PagesIndex.getOtpTimer({
                minute: otpTimer.minute - 1,
                seconds: 59,
              })
            );
          }
        }
      }, 1000);
    }
    return () => {
      clearTimeout(myInterval);
    };
  });
  function getUser() {
    dispatch(PagesIndex.showLoader());
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_USER, "", userToken).then(
      (res) => {
        if (res?.status === 200) {
          formikRef.current.setFieldValue("firstName", res?.data?.firstName);
          formikRef.current.setFieldValue("lastName", res?.data?.lastName);
          formikRef.current.setFieldValue("email", res?.data?.email);
          formikRef.current.setFieldValue(
            "phoneNumber",
            res?.data?.mobileNumber
          );
          formikRefAccount.current.setFieldValue("email", res?.data?.email);
          formikRefAccount.current.setFieldValue(
            "phoneNumber",
            res?.data?.mobileNumber
          );
          if (res?.data?.birthDate) {
            formikRef.current.setFieldValue(
              "birthDate",
              PagesIndex.moment(res?.data?.birthDate)
            );
          }
          if (res?.data?.gender) {
            formikRef.current.setFieldValue("gender", res?.data?.gender);
          } else {
            formikRef.current.setFieldValue("gender", "");
          }
          formikRef.current.setFieldValue(
            "maritalStatus",
            res?.data?.maritalStatus ? "Married" : "Unmarried"
          );
          if (res?.data?.gender) {
            formikRef.current.setFieldValue("address", res?.data?.address);
          } else {
            formikRef.current.setFieldValue("address", "");
          }
          if (res?.data?.gender) {
            formikRef.current.setFieldValue("city", res?.data?.city);
          } else {
            formikRef.current.setFieldValue("city", "");
          }
          if (res?.data?.profile) {
            setImageUrl(
              `${PagesIndex.IMAGES_API_ENDPOINT}${res?.data?.profile}`
            );
          }
          dispatch(PagesIndex.getUserData(res?.data));
        } else {
          PagesIndex.toast(res?.message);
        }
        dispatch(PagesIndex.hideLoader());
      }
    );
  }
  function handleSubmit(values) {
    dispatch(PagesIndex.showLoader());
    const formdata = new FormData();
    formdata.append("profile", values?.profile);
    formdata.append("firstName", values?.firstName);
    formdata.append("lastName", values?.lastName);
    formdata.append(
      "birthDate",
      PagesIndex.moment(values?.birthDate).format("YYYY/MM/DD")
    );
    formdata.append("gender", values?.gender);
    formdata.append(
      "maritalStatus",
      values?.maritalStatus === "Married" ? true : false
    );
    formdata.append("address", values?.address);
    formdata.append("city", values?.city);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.UPDATE_USER,
      formdata,
      userToken
    ).then((res) => {
      if (res?.status === 200) {
        getUser();
        PagesIndex.toast.success(res?.message);
      } else {
        PagesIndex.toast.error(res?.message);
      }
    });
    dispatch(PagesIndex.hideLoader());
  }
  function handleSubmitAccountDetails(values) {
    const formdata = new URLSearchParams();
    if (editType === "email") {
      formdata.append("email", values?.email);
    }
    if (editType === "phone") {
      formdata.append("mobileNumber", values?.phoneNumber);
    }
    if (editType === "email") {
      setChangedCredential(values?.email);
      PagesIndex.apiPostHandler(
        PagesIndex.Api.VERIFY_EMAIL,
        formdata,
        userToken
      ).then((res) => {
        if (res?.status === 200) {
          editModalClose();
          otpModalOpen();
          dispatch(
            PagesIndex.getOtpTimer({
              minute: 0,
              seconds: 30,
            })
          );
          PagesIndex.toast.success(res?.message);
        } else {
          PagesIndex.toast(res?.message);
        }
      });
    }
    if (editType === "phone") {
      setChangedCredential(values?.phoneNumber);
      PagesIndex.apiPostHandler(
        PagesIndex.Api.VERIFY_MOBILENUMBER,
        formdata,
        userToken
      ).then((res) => {
        if (res?.status === 200) {
          editModalClose();
          otpModalOpen();
          dispatch(
            PagesIndex.getOtpTimer({
              seconds: 30,
              minute: 0,
            })
          );
          PagesIndex.toast.success(res.message);
        } else {
          PagesIndex.toast(res?.message);
        }
      });
    }
  }
  const handleSubmitOtp = (values) => {
    var payload;
    if (editType === "email") {
      payload = {
        id: userDetails?._id,
        otp: values?.otp,
        flag: 1,
        email: changedCredential,
      };
    }
    if (editType === "phone") {
      payload = {
        id: userDetails?._id,
        otp: values?.otp,
        flag: 1,
        mobileNumber: changedCredential,
      };
    }
    PagesIndex.apiPostHandler(PagesIndex.Api.VERIFY_OTP, payload).then(
      (res) => {
        setChangedCredential("");
        otpModalClose();
        if (res?.status === 200) {
          formikRef.current.resetForm();
          formikRefAccount.current.resetForm();
          getUser();
        } else {
          PagesIndex.toast(res?.message);
        }
      }
    );
  };
  const resendOtp = () => {
    let payload;
    if (editType === "email") {
      payload = {
        id: userDetails?._id,
        email: changedCredential,
      };
    }
    if (editType === "phone") {
      payload = {
        id: userDetails?._id,
        mobileNumber: changedCredential,
      };
    }
    PagesIndex.apiPostHandler(PagesIndex.Api.RESEND_OTP, payload).then(
      (res) => {
        if (res?.status === 201) {
          dispatch(
            PagesIndex.getOtpTimer({
              seconds: 30,
              minute: 0,
            })
          );
          PagesIndex.toast.success(res?.message);
        } else {
          PagesIndex.toast.error(res?.message);
        }
      }
    );
  };
  const otpModalOpen = () => {
    setOpenOTPModal(true);
  };
  const otpModalClose = () => {
    setOpenOTPModal(false);
    formikRefOtp.current.resetForm();
  };
  const editModalOpen = (type) => {
    setEdit(true);
    setEditType(type);
  };
  const editModalClose = () => {
    setEdit(false);
    formikRefAccount.current.setFieldValue(
      "email",
      formikRef.current.values.email
    );
    formikRefAccount.current.setFieldValue(
      "phoneNumber",
      formikRef.current.values.phoneNumber
    );
  };

  return (
    <>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={PagesIndex.userDetailsSchema}
        innerRef={formikRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Index.Stack
            className="form-control"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Index.Box className="account-tab-main">
              <Index.Box className="profile-header-box">
                <Index.Box className="profile-img-box">
                  <Index.Box className="profile-img">
                    <img
                      src={imageUrl ? imageUrl : PagesIndex.Png.Avatar}
                      width="80"
                      height="80"
                      alt="profile"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = PagesIndex.Png.Avatar;
                      }}
                    />
                    <input
                      name="profile"
                      type="file"
                      accept="image/*"
                      id="profile"
                      className="profile-input"
                      onChange={(event) => {
                        setFieldValue("profile", event.currentTarget.files[0]);
                        setFieldTouched("profile");
                        setImageUrl(
                          URL.createObjectURL(event.currentTarget.files[0])
                        );
                      }}
                    />
                    <label htmlFor="profile" className="profile-label">
                      <Index.CreateIcon />
                    </label>
                  </Index.Box>
                </Index.Box>
                <Index.FormHelperText error>
                  {errors.profile && touched.profile ? errors?.profile : null}
                </Index.FormHelperText>
              </Index.Box>
              <Index.Grid
                container
                spacing={{ sm: 2, xxs: 1 }}
                className="form-group"
              >
                <Index.Grid item sm={12} xxs={12} className="account-tab-title">
                  Account Details
                </Index.Grid>
                <Index.Grid item sm={6} xxs={12}>
                  <Index.FormHelperText className="form-label">
                    Email address
                    {values?.email ? (
                      <Index.Typography
                        variant="span"
                        component="span"
                        className="edit-label"
                        onClick={() => editModalOpen("email")}
                      >
                        <Index.CreateIcon />
                        Edit
                      </Index.Typography>
                    ) : (
                      ""
                    )}
                  </Index.FormHelperText>
                  {values?.email ? (
                    <Index.TextField
                      name="email"
                      fullWidth
                      id="emailAcc"
                      className="form-control"
                      placeholder="Add email address"
                      type="email"
                      disabled
                      inputProps={{ maxLength: 320 }}
                      value={values?.email}
                      error={errors.email && touched.email ? true : false}
                      helperText={
                        errors.email && touched.email ? errors.email : null
                      }
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <Index.InputAdornment
                            position="end"
                            className="verify-label"
                          >
                            Verified
                          </Index.InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Index.Box
                      className="add-email-phone"
                      onClick={() => {
                        editModalOpen("email");
                        formikRefAccount.current.setErrors({ email: "" });
                      }}
                    >
                      + Add email address
                    </Index.Box>
                  )}
                </Index.Grid>
                <Index.Grid item sm={6} xxs={12}>
                  <Index.FormHelperText className="form-label">
                    Phone number
                    {values?.phoneNumber ? (
                      <Index.Typography
                        variant="span"
                        component="span"
                        className="edit-label"
                        onClick={() => editModalOpen("phone")}
                      >
                        <Index.CreateIcon />
                        Edit
                      </Index.Typography>
                    ) : (
                      ""
                    )}
                  </Index.FormHelperText>
                  {values?.phoneNumber ? (
                    <Index.TextField
                      name="phoneNumber"
                      fullWidth
                      id="phoneAcc"
                      className="form-control"
                      placeholder="Add phone number"
                      type="number"
                      disabled
                      inputProps={{ maxLength: 10 }}
                      value={values?.phoneNumber}
                      error={
                        errors.phoneNumber && touched.phoneNumber ? true : false
                      }
                      helperText={
                        errors.phoneNumber && touched.phoneNumber
                          ? errors.phoneNumber
                          : null
                      }
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <Index.InputAdornment
                            position="end"
                            className="verify-label"
                          >
                            Verified
                          </Index.InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Index.Box
                      className="add-email-phone"
                      onClick={() => editModalOpen("phone")}
                    >
                      + Add Phone Number
                    </Index.Box>
                  )}
                </Index.Grid>
                <Index.Divider className="divider" />
                <Index.Grid item sm={12} xxs={12} className="account-tab-title">
                  Personal Details
                </Index.Grid>
                <Index.Grid item sm={6} xxs={12}>
                  <Index.FormHelperText className="form-label">
                    First name
                  </Index.FormHelperText>
                  <Index.TextField
                    name="firstName"
                    fullWidth
                    id="firstNameAcc"
                    className="form-control"
                    placeholder="First name"
                    inputProps={{ maxLength: 50 }}
                    value={values?.firstName}
                    error={errors.firstName && touched.firstName ? true : false}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : null
                    }
                    onChange={handleChange}
                  />
                </Index.Grid>
                <Index.Grid item sm={6} xxs={12}>
                  <Index.FormHelperText className="form-label">
                    Last name
                  </Index.FormHelperText>
                  <Index.TextField
                    name="lastName"
                    fullWidth
                    id="lastNameAcc"
                    className="form-control"
                    placeholder="Last name"
                    inputProps={{ maxLength: 50 }}
                    value={values?.lastName}
                    error={errors.lastName && touched.lastName ? true : false}
                    helperText={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : null
                    }
                    onChange={handleChange}
                  />
                </Index.Grid>
                <Index.Grid item sm={4} xxs={12}>
                  <Index.FormHelperText className="form-label">
                    Birthdate
                  </Index.FormHelperText>
                  <PagesIndex.Datetime
                    name="birthDate"
                    timeFormat={false}
                    className="birth-input"
                    value={values?.birthDate}
                    isValidDate={(current) => {
                      return current.isBefore(PagesIndex.moment());
                    }}
                    inputProps={{ placeholder: "DD/MM/YYYY" }}
                    dateFormat="DD/MM/YYYY"
                    onChange={(e) => {
                      setFieldValue("birthDate", e);
                    }}
                  />
                  <PagesIndex.FormHelperText error>
                    {errors.birthDate && touched.birthDate
                      ? errors.birthDate
                      : null}
                  </PagesIndex.FormHelperText>
                </Index.Grid>
                <Index.Grid item sm={4} xxs={12}>
                  <Index.FormHelperText className="form-label">
                    Gender
                  </Index.FormHelperText>
                  <Index.Select
                    id={"gender"}
                    name="gender"
                    className="gender-select "
                    displayEmpty
                    fullWidth
                    renderValue={
                      values?.gender ? undefined : () => "Select gender"
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    value={values?.gender}
                    error={errors.gender && touched.gender ? true : false}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldTouched("gender");
                    }}
                  >
                    <Index.MenuItem value={"Male"} className="menuitem">
                      Male
                    </Index.MenuItem>
                    <Index.MenuItem value={"Female"} className="menuitem">
                      Female
                    </Index.MenuItem>
                    <Index.MenuItem value={"Other"} className="menuitem">
                      Other
                    </Index.MenuItem>
                  </Index.Select>
                  <Index.FormHelperText error>
                    {errors.gender && touched.gender ? errors.gender : null}
                  </Index.FormHelperText>
                </Index.Grid>
                <Index.Grid item sm={4} xxs={12}>
                  <Index.FormHelperText className="form-label">
                    Marital status
                  </Index.FormHelperText>
                  <Index.Select
                    id={"maritalStatus"}
                    name="maritalStatus"
                    className="city-select "
                    displayEmpty
                    fullWidth
                    renderValue={
                      values?.maritalStatus
                        ? undefined
                        : () => "Select marital status"
                    }
                    inputProps={{ "aria-label": "Without label" }}
                    value={values?.maritalStatus}
                    error={
                      errors.maritalStatus && touched.maritalStatus
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      handleChange(e);
                      setFieldTouched("maritalStatus");
                    }}
                  >
                    <Index.MenuItem value={"Unmarried"} className="menuitem">
                      Unmarried
                    </Index.MenuItem>
                    <Index.MenuItem value={"Married"} className="menuitem">
                      Married
                    </Index.MenuItem>
                  </Index.Select>
                  <Index.FormHelperText error>
                    {errors.maritalStatus && touched.maritalStatus
                      ? errors.maritalStatus
                      : null}
                  </Index.FormHelperText>
                </Index.Grid>
                <Index.Grid item sm={6} xxs={12}>
                  <Index.FormHelperText className="form-label">
                    Address
                  </Index.FormHelperText>
                  <Index.TextField
                    name="address"
                    fullWidth
                    id="address1Acc"
                    className="form-control"
                    placeholder="Address"
                    inputProps={{ maxLength: 350 }}
                    value={values?.address}
                    error={errors.address && touched.address ? true : false}
                    helperText={
                      errors.address && touched.address ? errors.address : null
                    }
                    onChange={handleChange}
                  />
                </Index.Grid>
                <Index.Grid item sm={6} xxs={12}>
                  <Index.FormHelperText className="form-label">
                    City
                  </Index.FormHelperText>
                  <Index.TextField
                    name="city"
                    fullWidth
                    id="address2Acc"
                    className="form-control"
                    placeholder="City"
                    inputProps={{ maxLength: 50 }}
                    value={values?.city}
                    error={errors.city && touched.city ? true : false}
                    helperText={
                      errors.city && touched.city ? errors.city : null
                    }
                    onChange={handleChange}
                  />
                </Index.Grid>
                <Index.Grid item sm={12} xxs={12} className="form-submit">
                  <PagesIndex.Button
                    primary
                    className="save-button form-btn"
                    type="submit"
                  >
                    Update Profile
                  </PagesIndex.Button>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Stack>
        )}
      </PagesIndex.Formik>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleSubmitAccountDetails}
        initialValues={initialValuesAccount}
        validationSchema={
          editType === "email"
            ? PagesIndex.accountDetailsEmailSchema
            : PagesIndex.accountDetailsPhoneSchema
        }
        innerRef={formikRefAccount}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Index.Modal
            open={edit}
            onClose={editModalClose}
            className="edit-number-modal"
          >
            <Index.Box className="edit-number-modal-inner">
              <Index.Box className="modal-inner cus-scrollbar">
                <Index.Stack
                  className="form-control"
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Typography className="edit-number-title">
                    Edit{" "}
                    {editType === "email" ? "email address" : "phone number"}
                  </Index.Typography>
                  <Index.Typography className="edit-number-content">
                    This {editType === "email" ? "email" : "phone number"} will
                    be verified by an OTP
                  </Index.Typography>
                  <Index.Box className="edit-number-form">
                    <Index.TextField
                      fullWidth
                      name={editType === "email" ? "email" : `phoneNumber`}
                      className="form-control"
                      placeholder={`Enter your ${
                        editType === "email" ? "email" : "phone number"
                      }`}
                      inputProps={{
                        maxLength: editType === "phone" ? 10 : 100,
                      }}
                      value={
                        editType === "email"
                          ? values?.email
                          : values?.phoneNumber
                      }
                      onChange={handleChange}
                      error={
                        editType === "email" && errors.email && touched.email
                          ? true
                          : editType === "phone" &&
                            errors.phoneNumber &&
                            touched.phoneNumber
                          ? true
                          : false
                      }
                      helperText={
                        editType === "email" && errors.email && touched.email
                          ? errors.email
                          : editType === "phone" &&
                            errors.phoneNumber &&
                            touched.phoneNumber
                          ? errors.phoneNumber
                          : null
                      }
                    />
                    <PagesIndex.Button
                      type="submit"
                      primary
                      className="edit-number-btn"
                    >
                      Verify
                    </PagesIndex.Button>
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        )}
      </PagesIndex.Formik>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleSubmitOtp}
        initialValues={initialValuesOtp}
        validationSchema={PagesIndex.otpSchema}
        innerRef={formikRefOtp}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Index.Modal
            open={openOTPModal}
            onClose={otpModalClose}
            className="edit-number-modal"
          >
            <Index.Box className="edit-number-modal-inner">
              <Index.Box className="modal-inner cus-scrollbar">
                <Index.Stack
                  className="form-control"
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Typography className="edit-number-title">
                    Verify your{" "}
                    {editType === "email" ? "Email address" : "Phone Number"}
                  </Index.Typography>
                  <Index.Typography className="edit-number-content">
                    Enter OTP sent to{" "}
                    {
                      formikRefAccount.current?.values[
                        editType === "email" ? "email" : "phoneNumber"
                      ]
                    }
                  </Index.Typography>
                  <Index.Box className="otp-form-wrapper">
                    <Index.Box className="otp-form-inner-wrapper">
                      <PagesIndex.OTPInput
                        shouldAutoFocus
                        name="otp"
                        numInputs={4}
                        inputStyle="form-control"
                        containerStyle="otp-form"
                        inputType="number"
                        value={values.otp}
                        onChange={(value) => {
                          setFieldValue("otp", value);
                          setFieldTouched("otp");
                        }}
                        error={errors.otp && touched.otp ? true : false}
                        renderInput={(props) => <input {...props} />}
                      />
                      <Index.FormHelperText error>
                        {errors.otp && touched.otp ? errors.otp : null}
                      </Index.FormHelperText>
                      <Index.Box className="resend-otp-box">
                        <Index.Typography variant="span" component="span">
                          Didn't recieved OTP?
                        </Index.Typography>
                        {otpTimer.minute === 0 && otpTimer.seconds === 0 ? (
                          <button
                            className="resend-otp-btn"
                            onClick={() => resendOtp()}
                            type="button"
                          >
                            Resend
                          </button>
                        ) : (
                          <Index.Typography variant="span" component="span">
                            {otpTimer.minute < 10
                              ? `0${otpTimer.minute}`
                              : otpTimer.minute}
                            :
                            {otpTimer.seconds < 10
                              ? `0${otpTimer.seconds}`
                              : otpTimer.seconds}
                          </Index.Typography>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <PagesIndex.Button
                      primary
                      className="otp-btn"
                      type="submit"
                    >
                      Verify OTP
                    </PagesIndex.Button>
                    <Index.Box className="back-btn-box">
                      <Index.Box
                        onClick={() => {
                          editModalOpen(editType);
                          setOpenOTPModal(false);
                        }}
                        className="back-btn"
                      >
                        <Index.ArrowRightAltIcon />
                        Back
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        )}
      </PagesIndex.Formik>
    </>
  );
}

export default AccountTab;
