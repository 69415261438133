import React from 'react'
import Index from '../../../Index';
import PagesIndex from "../../../PagesIndex";

function Membership() {

    const membershipItem = [
        {
            title: "Silver",
            className: "silver-membership",
            price: "Rs. 500/-",
            children: [
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: false,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: false,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: false,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: false,
                },
            ],
        },
        {
            title: "Gold (Popular)",
            className: "gold-membership",
            price: "Rs. 1000/-",
            children: [
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: false,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: false,
                },
            ],
        },
        {
            title: "Platinum",
            className: "platinum-membership",
            price: "Rs. 1500/-",
            children: [
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam, consectetur",
                    active: true,
                },
                {
                    content: "Lorem ipsum dolor sit amet ametam",
                    active: true,
                },
            ],
        },
    ]

    return (

        <Index.Box className="main-membership">
            <Index.Box className="cus-container">
                <Index.Box className="membership-header">
                    <Index.Typography
                        variant="h1"
                        component="h1"
                        className="membership-header-title"
                    >
                        Membership
                    </Index.Typography>
                    <Index.Typography
                        variant="p"
                        component="p"
                        className="membership-header-subtitle"
                    >
                        Join membership by choose your perfect option below
                    </Index.Typography>
                </Index.Box>
                <Index.Box className="membership-body">
                    <Index.Box className="membership-item-box">
                        {membershipItem.map((item, key) => (
                            <Index.Box key={key} className={`membership-item ${item.className}`}>
                                <Index.Typography className='membership-title'>
                                    {item.title}
                                </Index.Typography>
                                <Index.Box className='membership-price'>
                                    <Index.Typography className='membership-price-inner'>
                                        {item.price}
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className='membership-content-box'>
                                    {item.children.map((res, key) => (
                                        <Index.Typography key={key} className={`membership-content ${res.active ? "active" : "disable"}`}>
                                            <Index.CheckIcon />
                                            {res.content}
                                        </Index.Typography>
                                    ))}
                                </Index.Box>
                                <Index.Box className='membership-btn-box'>
                                    <PagesIndex.Button secondary className='membership-btn'>
                                        Choose Plan
                                    </PagesIndex.Button>
                                </Index.Box>
                            </Index.Box>
                        ))}
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </Index.Box>

    )

}

export default Membership