import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function AboutTabContent({ details, movieId }) {
  const { upcomingMoviesList } = PagesIndex.useSelector(
    (state) => state.UserReducer
  );
  return (
    <Index.Box className="cus-container">
      <Index.Box className="about-tab-content">
        <Index.Box className="about-tab-header">
          <Index.Typography
            variant="p"
            component="p"
            className="tab-header-title"
          >
            About the movie
          </Index.Typography>
          <Index.Typography
            variant="p"
            component="p"
            className="tab-header-content"
          >
            {details?.description ? details?.description : "No data found."}
          </Index.Typography>
        </Index.Box>
        <Index.Box className="about-tab-body">
          <Index.Box className="about-tab-cast-crew">
            <Index.Typography
              variant="p"
              component="p"
              className="cast-crew-title"
            >
              Cast
            </Index.Typography>
            {details?.starCast?.length ? (
              <Index.Box className="cast-crew-wrapper">
                {details?.starCast?.map((item, key) => (
                  <Index.Box key={key} className="cast-crew-item">
                    <Index.Box className="cast-crew-img">
                      <img
                        src={
                          item?.starCastId?.profile
                            ? PagesIndex.IMAGES_API_ENDPOINT +
                            item?.starCastId?.profile
                            : PagesIndex.Png.Avatar
                        }
                        width="98"
                        height="98"
                        alt="cast crew"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = PagesIndex.Png.Avatar;
                        }}
                      />
                    </Index.Box>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="real-name"
                    >
                      {item?.starCastId?.name}
                    </Index.Typography>
                    {/* <Index.Typography
                    variant="p"
                    component="p"
                    className="movie-name"
                  >
                    as Tara Singh
                  </Index.Typography> */}
                  </Index.Box>
                ))}
              </Index.Box>
            ) : (
              "No data found"
            )}
          </Index.Box>
          <Index.Box className="about-tab-cast-crew">
            <Index.Typography
              variant="p"
              component="p"
              className="cast-crew-title"
            >
              Crew
            </Index.Typography>
            {details?.crew?.length ? (
              <Index.Box className="cast-crew-wrapper">
                {details?.crew?.map((item, key) => (
                  <Index.Box key={key} className="cast-crew-item">
                    <Index.Box className="cast-crew-img">
                      <img
                        src={
                          item?.starCastId?.profile
                            ? PagesIndex.IMAGES_API_ENDPOINT +
                            item?.starCastId?.profile
                            : PagesIndex.Png.Avatar
                        }
                        width="98"
                        height="98"
                        alt="cast crew"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = PagesIndex.Png.Avatar;
                        }}
                      />
                    </Index.Box>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="real-name"
                    >
                      {item?.starCastId?.name}
                    </Index.Typography>
                    {/* <Index.Typography
                    variant="p"
                    component="p"
                    className="movie-name"
                  >
                    as Tara Singh
                  </Index.Typography> */}
                  </Index.Box>
                ))}
              </Index.Box>
            ) : (
              "No data found"
            )}
          </Index.Box>
          {/* upcoming */}
          <Index.Box className="about-movie-upcoming movie-slider">
            <Index.Box className="showing-txt movie-slider-heading">
              <Index.Typography variant="h5">Upcoming</Index.Typography>
            </Index.Box>
            <Index.Box className="main-upcoming-slider movie-slider-inner">
              {upcomingMoviesList?.length ? (
                <PagesIndex.Swiper
                  modules={[PagesIndex.Navigation]}
                  navigation={true}
                  spaceBetween={10}
                  slidesPerView={2.5}
                  breakpoints={{
                    550: {
                      slidesPerView: 2.8,
                      spaceBetween: 10,
                    },
                    780: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                    1349: {
                      slidesPerView: 5,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {upcomingMoviesList
                    ?.filter((data) => data?._id !== movieId)
                    ?.map((item, key) => (
                      <PagesIndex.SwiperSlide key={key}>
                        <PagesIndex.MovieCard
                          Like={key % 2 === 0 ? true : false}
                          Rating={key % 2 === 1 ? true : false}
                          item={item}
                        />
                      </PagesIndex.SwiperSlide>
                    ))}
                </PagesIndex.Swiper>
              ) : (
                <Index.Box className="not-found">No Movies Found</Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default AboutTabContent;
